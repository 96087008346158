import '../styles/Button.css';
import { useEffect, useRef, useState } from 'react';

function Button(props)
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ButtonWrapper, setButtonWrapper] = useState('ButtonWrapper');
    const [ButtonIcon, setButtonIcon] = useState('ButtonIcon');
    const [ButtonText, setButtonText] = useState('ButtonText');

    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
            setButtonWrapper('ButtonWrapper_Ipad');
            setButtonText('ButtonText_Ipad')

        }

        if(windowSize.current[0] < 900)
            {
                setButtonWrapper('ButtonWrapper_Ipad_upright');
                setButtonText('ButtonText_Ipad_upright')
    
            }

        if(windowSize.current[0] < 600)
        {
            setButtonWrapper('ButtonWrapper_Mobile');
            setButtonText('ButtonText_Mobile')
        }

    }, windowSize.current[0]);

    return(
    <div className={ButtonWrapper} style={{marginLeft:props.MarginLeft+"%"}}>
        <div className={ButtonText}>{props.Text}</div>
    </div>)
}

export default Button;