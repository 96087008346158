import Kursblock from '../components/Kursblock';
import Navigation from '../components/Navigation';
import '../styles/Modul3.css';
import '../styles/Modulubersicht.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

function Modul3()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ModulHeader, setModulHeader] = useState('ModulHeader');
    const [ModulHeaderElement, setModulHeaderElement] = useState('ModulHeaderElement');
    const [ModulHeaderElementColored, setModulHeaderElementColored] = useState('ModulHeaderElement_Colored');
    const [ModulBody, setModulBody] = useState('ModulBody');
    const [FlowChartArea, setFlowChartArea] = useState('FlowChartArea');
    const [ModulBlockOnline, setModulBlockOnline] = useState('ModulBlockOnline');
    const [ModulBlockOffline, setModulBlockOffline] = useState('ModulBlockOffline');
    const [ModulBlockTextArea, setModulBlockTextArea] = useState('ModulBlockTextArea');
    const [ModulBlockLink, setModulBlockLink] = useState('ModulBlockLink');
    const [ModulBlockLinkLink, setModulBlockLinkLink] = useState('ModulBlockLinkLink');
    const [ButtonText1, setButtonText1] = useState('zum Material');
    const [ButtonText2, setButtonText2] = useState('zum Workshop');
    const [ModulBlockTitel, setModulBlockTitel] = useState('ModulBlockTitel');
    const [ModulBlockText, setModulBlockText] = useState('ModulBlockText');

    
   
    const Api = "https://www.schreibenmitki.de:8443/";

    
    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1600)
        {
            setModulBlockText('ModulBlockText_Medium');
        }

        if(windowSize.current[0] < 1200)
        {
            setModulHeader('ModulHeader_Ipad');
            setModulHeaderElement('ModulHeaderElement_Ipad');
            setModulHeaderElementColored('ModulHeaderElement_Colored_Ipad')
        }

        if(windowSize.current[0] < 900)
            {
                
            }

        if(windowSize.current[0] < 600)
        {
            setModulHeader('ModulHeader_Mobile');
            setModulHeaderElement('ModulHeaderElement_Mobile');
            setModulHeaderElementColored('ModulHeaderElement_Colored_Mobile');
            setModulBody('ModulBody_Mobile');
            setFlowChartArea('FlowChartArea_Mobile');
            setModulBlockOnline('ModulBlockOnline_Mobile');
            setModulBlockOffline('ModulBlockOffline_Mobile');
            setModulBlockTextArea('ModulBlockTextArea_Mobile');
            setModulBlockLink('ModulBlockLink_Mobile');
            setModulBlockLinkLink('ModulBlockLinkLink_Mobile');
            setButtonText1('>');
            setButtonText2('>');
            setModulBlockTitel('ModulBlockTitel_Mobile');
            setModulBlockText('ModulBlockText_Mobile');
        }

    }, windowSize.current[0]);


    return(
        <div className='Modul3Wrapper'>
            <Navigation Page={"Landingpage"}></Navigation>
        <div className={ModulHeader}>
            <div className={ModulHeaderElement}>Schreiben nachbereiten:</div><div className='Row2'><div className={ModulHeaderElementColored}>Feedback</div> <div className={ModulHeaderElement}>mit KI </div></div>
        </div>
        <div className={ModulBody}>
            <div className={FlowChartArea}>
                <div className={ModulBlockOnline}>
                    <div className={ModulBlockTextArea}>
                        <div className={ModulBlockTitel}>Vorbereitung</div>
                        <div className={ModulBlockText}>
                            <ul>
                                <li>Einen Account bei OpenAi anlegen.</li>
                            </ul>
                        </div>
                    </div>
                    <div className={ModulBlockLink}>
                        <div className={ModulBlockLinkLink}>{ButtonText1}</div>
                    </div>
                </div>
                <div className={ModulBlockOffline}>
                    <div className={ModulBlockTextArea}>
                        <div className={ModulBlockTitel}>Workshop Tag 1</div>
                        <div className={ModulBlockText}>
                            <ul>
                                <li>Merkmale guter Texte kennenlernen</li>
                                <li style={{marginTop:3+"%"}}>Merkmale lernförderlichen Feedbacks kennenlernen</li>
                                <li style={{marginTop:3+"%"}}>Grundfunktionen von KI für Feedbacks</li>
                            </ul>
                        </div>
                    </div>
                    <div className={ModulBlockLink}>
                        <div className={ModulBlockLinkLink}><Link to="/M3Transit" style={{color:"black", textDecoration:"none"}}>{ButtonText2}</Link></div>
                    </div>
                </div>
                <div className={ModulBlockOnline}>
                    <div className={ModulBlockTextArea}>
                        <div className={ModulBlockTitel}>Hausaufgabe</div>
                        <div className={ModulBlockText}>
                            <ul>
                                <li>Anwendung auf ein selbstgewähltes Beispiel</li>
                                <li style={{marginTop:3+"%"}}>Einen Accout bei <i>fiete.ai</i> anlegen</li>
                            </ul>
                        </div>
                    </div>
                    <div className={ModulBlockLink}>
                        <div className={ModulBlockLinkLink}><Link to="/M3Transit" style={{color:"black", textDecoration:"none"}}>{ButtonText1}</Link></div>
                    </div>
                </div>
                <div className={ModulBlockOffline}>
                    <div className={ModulBlockTextArea}>
                        <div className={ModulBlockTitel}>Workshop Tag 2</div>
                        <div className={ModulBlockText}>
                            <ul>
                                <li>KI-basierte Feedbacktools vergleichen</li>
                                <li style={{marginTop:3+"%"}}>KI als didaktische Ressource?</li>
                            </ul>
                        </div>
                    </div>
                    <div className={ModulBlockLink}>
                        <div className={ModulBlockLinkLink}><Link to="/M3Transit" style={{color:"black", textDecoration:"none"}}>{ButtonText2}</Link></div>
                    </div>
                </div>
                <div className={ModulBlockOnline}>
                    <div className={ModulBlockTextArea}><div className={ModulBlockTitel}>Nachbereitung</div>
                        <div className={ModulBlockText}>
                            <ul>
                                <li>Anwendung auf ein anforderungsreiches Beispiel</li>
                            </ul>
                        </div>
                    </div>
                    <div className={ModulBlockLink}>
                        <div className={ModulBlockLinkLink}>{ButtonText1}</div>
                    </div>
                </div>
            </div>
        </div>
       

       
        <Footer></Footer>
        </div>
    )
}
export default Modul3;