import Footer from '../components/Footer';
import '../styles/Register.css';
import Axios from 'axios';
import bcrypt from 'bcryptjs';
import { Link } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { useEffect, useRef, useState } from 'react';

function Register()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const salt = "$2a$10$FyRan35Gah8Thq8%tjUM5x";
    const [checker1, setChecker1] = useState("");
    const [checker2, setChecker2] = useState("");
    const [checker3, setChecker3] = useState("");
    const [checker4, setChecker4] = useState("");
    const Api = "https://www.schreibenmitki.de:8443/";

    const [vornameInput, setVornameInput] = useState("RegisterInput1");
    const [nachnameInput, setNachnameInput] = useState("RegisterInput1");
    const [uidInput, setUidInput] = useState("RegisterInput2");
    const [mailInput, setMailInput] = useState("RegisterInput2");
    const [pwdInput1, setPwdInput1] = useState("RegisterInput2");
    const [pwdInput2, setPwdInput2] = useState("RegisterInput2");
    const [zufallsButtonView, setZufallsButtonView] = useState("RegisterHelpBut");
    const [uberprufBut, setUberPrufBut] = useState('RegisterButton');
    const [finalRegBut, setFinalRegBut] = useState('RegisterButton_gone');
    const [ButSucces, setButSuccess] = useState('RegisterButton_gone');

    const [pwd1Fix, setPwd1Fix] = useState("RegisterInput2_Fix_gone");
    const [pwd2Fix, setPwd2Fix] = useState("RegisterInput2_Fix_gone");


    const [Vorname, setVorname] = useState("");
    const [Nachname, setNachname] = useState("");
    const [mailAdresse, setMailAdresse] = useState("");
    const [uId, setUid] = useState("");
    const [pwd, setPwd] = useState("");
    const [pwd2, setPwd2] = useState("");
    const [pwdPre, setPwdPre] = useState("");

    const [RegisterHead, setRegisterHead] = useState('RegisterHead');
    const [RegisterTitel, setRegisterTitel] = useState('RegisterTitel');
    const [RegisterRow, setRegisterRow] = useState('RegisterRow');
    const [RegisterRow2, setRegisterRow2] = useState('RegisterRow2');
    const [RegisterLabel, setRegisterLabel] = useState('RegisterLabel');
    const [RegisterContainer, setRegisterContainer] = useState('RegisterContainer');
    const [HelpText, setHelpText] = useState('Zufallspasswort');

    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
            
        }

        if(windowSize.current[0] < 900)
        {
               
        }

        if(windowSize.current[0] < 600)
        {
            setRegisterTitel('RegisterTitel_Mobile');
            setRegisterHead('RegisterHead_Mobile');
            setRegisterRow('RegisterRow_Mobile');
            setRegisterRow2('RegisterRow2_Mobile');
            setRegisterLabel('RegisterLabel_Mobile');
            setRegisterContainer('RegisterContainer_Mobile');
            setVornameInput("RegisterInput1_Mobile");
            setNachnameInput("RegisterInput1_Mobile");
            setUidInput("RegisterInput1_Mobile");
            setPwdInput2("RegisterInput1_Mobile");
            setUberPrufBut('RegisterButton_Mobile');
            setZufallsButtonView('RegisterHelpBut_Mobile');
            setHelpText('Zufalls-passwort');
        }

    }, windowSize.current[0]);




    const uberprufen = () =>{
        var namensCheck = false;
        var uIdCheck = false;
        var mailCheck = true;
        var passwordCheck = false;


        if(Vorname.length > 2 && Nachname.length > 2)
        {
            namensCheck = true;
        }

        if(uId.length > 2)
        {
            Axios.post(Api+'checkUidAvailable', {useruid: uId,}).then((responseUid) => {
                if(responseUid.data !== 0)
                    {
                        uIdCheck = false;
                        setChecker1("X");
                    }
                    else{
                        uIdCheck = true;
                        setChecker1("√");
                    }
            }) 
        }

        if(mailAdresse.length > 5)
        {
            if(mailAdresse.includes("@"))
            {
                if(mailAdresse.includes("."))
                {
                    mailCheck = true;
                    setChecker2("√")
                }

            }
            else{
                mailCheck = false;
                setChecker2("X");
            }
        }

        if(pwd.length > 7)
        {
            if(pwd == pwd2)
            {
                if(windowSize.current[0] > 600)
                    {
                        setChecker3("√");
                        setChecker4("√");
                    }

                
                passwordCheck = true;
            }
            else{
                passwordCheck=false;
            }
            
        }

        if(passwordCheck==true && namensCheck == true && uIdCheck == true && mailCheck == true)
        {
            setUberPrufBut('RegisterButton_gone')
            console.log("All checks clear!");
        }

        setTimeout(()=> {
            if(passwordCheck==true && namensCheck == true && uIdCheck == true && mailCheck == true)
            {
            setUberPrufBut('RegisterButton_gone')
            setPwdInput1('RegisterInput2_gone');
            setPwdInput2('RegisterInput2_gone');
            setVornameInput('RegisterInput2_gone');
            setZufallsButtonView('RegisterHelpBut_gone');
            setNachnameInput('RegisterInput2_gone');
            setUidInput('RegisterInput2_gone');
            setMailInput('RegisterInput2_gone');
            setPwd2Fix("RegisterInput2_Fix");
            setFinalRegBut('RegisterButton');
            if(windowSize.current[0] < 600)
                {
                    setFinalRegBut('RegisterButton_Mobile');
                }           

            }
        },2000)
    }



    const zufallsPasswortGenerieren = () =>{

        setZufallsButtonView('RegisterHelpBut_gone');
        setPwdInput1('RegisterInput2_gone');
        setPwdInput2('RegisterInput2_gone');
        setPwd1Fix("RegisterInput2_Fix");

        var RandomPwd = makeRandom(10);
        setPwd(RandomPwd);
        setPwd2(RandomPwd);
    }

    function makeRandom(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }

    const userRegistrieren = () =>{
        setFinalRegBut('RegisterButton_gone');
        setButSuccess('RegisterButton');
        Axios.post(Api+'schmkiRegister', {
            Realname: Vorname+" "+Nachname, 
            Nutzername: uId,
            Passwort:  bcrypt.hashSync(pwd, salt)
        }).then((responseReg)=> {
            if(responseReg)
            {
                
            }
        })
    }

    

    return(
        <div className='RegisterWrapper'>
            <Navigation Page={"Landingpage"}/>
               <div className='RegisterBody'>
                    <div className={RegisterHead}>
                        <div className={RegisterTitel}>Als Teilnehmer:in für "Schreiben mit KI im Lehramt anmelden":</div>
                    </div>
                    <div className='RegisterMain'>
                    <div className={RegisterContainer}>
                            <div className={RegisterRow}>
                                <div className={RegisterLabel}>Name:</div>
                                <input className={vornameInput} type="text" placeholder='z.B. Max' onChange={(e) => {setVorname(e.target.value)}}></input>
                                <div className={pwd2Fix}>{Vorname}</div>
                            </div>
                            <div className={RegisterRow}>
                                <div className={RegisterLabel}>Nachname:</div>
                                <input className={nachnameInput} type="text" placeholder='z.B. Mustermann' onChange={(e) => {setNachname(e.target.value)}}></input>
                                <div className={pwd2Fix}>{Nachname}</div>
                            </div>
                            <div className={RegisterRow}>
                            <div className={RegisterLabel}>Benutzername:</div>
                            <input className={uidInput} type="text" placeholder='z.B. Megaman' onChange={(e) => {setUid(e.target.value)}}></input>
                            <div className={pwd2Fix}>{uId}</div>
                            <div className='RegisterChecker'>{checker1}</div>
                            </div>
                            <div className={RegisterRow}>
                            <div className={RegisterLabel}>Passwort:</div>
                            <input className={pwdInput1} type="password" onChange={(e) => {setPwd(e.target.value)}}></input>
                            <div className={pwd1Fix}>{pwd}</div>
                            <div className='RegisterChecker'>{checker3}</div>
                            <div className={zufallsButtonView} onClick={zufallsPasswortGenerieren}>{HelpText}</div>
                            </div>
                            <div className={RegisterRow}>
                            <div className={RegisterLabel}>Passwort erneut:</div>
                            <input className={pwdInput2} type="password" onChange={(e) => {setPwd2(e.target.value)}}></input>
                            <div className={pwd1Fix}>{pwd2}</div>
                            <div className='RegisterChecker'>{checker4}</div>
                            </div>
                            <div className={RegisterRow2}>
                                <div className={uberprufBut} onClick={uberprufen}>Daten überprüfen</div>
                                <div className={finalRegBut} onClick={userRegistrieren}>Registrieren</div>
                                <Link to="/M3Q" state={{UserCode:uId}}><div className={ButSucces} >Erfolg!</div></Link>
                            </div>
                        </div>
                    </div>
               </div>
        </div>
    )
}

export default Register;

