

import '../styles/Homeworkshower.css'
function Homeworkshower(props)
{
return(
    <div className='HomeWorkShowerWrapper'>
         {props.Text}
    </div>
)
}

export default Homeworkshower;