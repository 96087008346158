import Navigation from '../components/Navigation';
import { useEffect, useRef, useState } from 'react';
import '../styles/Projektseite.css';
import Footer from '../components/Footer';

function Projektseite()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ProjektHeader, setProjektHeader] = useState('ProjektHeader');
    const [PH_Image1, setPH_Image1] = useState('PH_Image1');
    const [SuperTitel, setSuperTitel] = useState('SuperTitel');
    const [PH_TextArea_Titel, setPH_TextArea_Titel] = useState('PH_TextArea_Titel');
    const [PH_Subtitel, setPH_Subtitel] = useState('PH_Subtitel');
    const [Projekt_MainArea, setProjekt_MainArea] = useState('Projekt_MainArea');
    const [ProjektText, setProjektText] = useState('ProjektText');
    const [ProjektText2, setProjektText2] = useState('ProjektText2');

    useEffect(()=>{
        if(windowSize.current[0] > 1600)
        {
           setPH_Image1('PH_Image1');
           setSuperTitel('SuperTitel_4K');
           setPH_TextArea_Titel('PH_TextArea_Titel_4K');
           setPH_Subtitel('PH_Subtitel_4K');
           setProjekt_MainArea('Projekt_MainArea_4K');
           setProjektText('ProjektText_4K');
           setProjektText2('ProjektText2_4K');
        }


        if(windowSize.current[0] < 1200)
        {
           setProjektHeader('ProjektHeader_Ipad');
           setPH_Image1('PH_Image1_Ipad');
           setSuperTitel('SuperTitel_Ipad');
           setPH_TextArea_Titel('PH_TextArea_Titel_Ipad');
           setPH_Subtitel('PH_Subtitel_Ipad');
           setProjekt_MainArea('Projekt_MainArea_Ipad');
        }

        if(windowSize.current[0] < 600)
        {
            setProjektHeader('ProjektHeader_Mobile');
            setPH_Image1('PH_Image1_Mobile');
            setSuperTitel('SuperTitel_Mobile');
            setPH_TextArea_Titel('PH_TextArea_Titel_Mobile');
            setPH_Subtitel('PH_Subtitel_Mobile');
            setProjekt_MainArea('Projekt_MainArea_Mobile');
            setProjektText('ProjektText_Mobile');
            setProjektText2('ProjektText2_Mobile');
        }

    }, windowSize.current[0]);


    return(
       <div className='ProjektseitenWrapper'>
        <Navigation/>
        <div className={ProjektHeader}>
            <div className='PH_Textarea'>
                <div className={SuperTitel}>Projekt </div>
                <div className={PH_TextArea_Titel}>Lehramtsstudierende nutzen KI als Ressource, um das Schreiben vorzubereiten, zu begleiten und nachzubereiten</div>
                <div className={PH_Subtitel}>Die Entwicklung von extracurricularen Lehr-Lern-Modulen für Lehramtsstudierende aller Fächer.</div>
            </div>
            <div className='PH_Imagearea'>
                <div className={PH_Image1}></div>
            </div>
        </div>
        <div className={Projekt_MainArea}>
            <div className={ProjektText}>KI-Anwendungen und insbesondere <i>ChatGPT</i> <b style={{fontWeight:300, color:"darkgray"}}>(OpenAI 2022)</b> wird das Potenzial zugeschrieben, das Schreiben in der Schule und Hochschule disruptiv zu verändern <b style={{fontWeight:300, color:"darkgray"}}>(Alier et al. 2024; Schindler 2024)</b>. Einige gehen soweit, zu vermuten, dass es das Schreiben zukünftig vollständig aus der Hand der Lernenden nimmt <b style={{fontWeight:300, color:"darkgray"}}>(Anson & Straume 2022)</b>. Offizielle Stellen, wie z.B. die Ständige Wissenschaftliche Kommission der Kultusministerkonferenz (SWK) aber auch die UNESCO, fordern seither auf, <b>KI als Ressource</b> für das Unterrichten zu verstehen. Vor allem fordern diese Stelle auf, Fortbildungsangebote für Lehrkräfte und werdende Lehrkräfte anzubieten.</div>
            <div className={ProjektText2}>Im Fokus des Projektes Schreiben mit KI im Lehramt der Friedrich-Schiller-Universität Jena steht die Fort- und Weiterbildung von Lehramtsstudieren, denn diese stehen vor einer gleich doppelten Herausforderung: Einerseits sind sie <b>Schreibenlernende</b>, beispielsweise mit Blick auf das wissenschaftliche Schreiben, das sich im Einfluss von KI gegenwärtig rasant wandelt. Andererseits sind sie <b>Schreibenlehrende</b> mit Blick auf ihre zukünftigen Schüler:innen, die sie im Schreiberwerb begleiten sollen. Auch in diesem Bereich zeichnen sich erste durch KI ausgelöste Wandelprozesse ab. Um also die Lehramtsstudierenden in ihrer Doppelrolle zu entlasten, werden <b>Lehr-Lern-Module</b> entwickelt, die Lehramtsstudierende aller Fächer extracurricular durchlaufen können. Die Module können aufeinander aufbauend oder auch isoliert belegt werden.</div>
            <div className={ProjektText2}>Ergebnisse nationaler und internationaler Studierendenbefragungen  <b style={{fontWeight:300, color:"darkgray"}}>(z.B. Barrett & Pack 2023; Helm & Hesse 2024; Hoffmann & Schmidt 2023; von Garrel et al. 2023; Malmström et al. 2023)</b> verdeutlichen, dass die Ausgangsbedingungen von Lehramtsstudierenden heterogen sind, sowohl hinsichtlich der Vorerfahrungen mit KI-Anwendungen als auch in der Nutzung von KI für das Schreiben. Vor allem zeigt sich, dass KI-Anwendungen vorrangig in der Phase der <i>Textplanung</i> und <i>Textvorbereitung</i> genutzt werden. In weiteren möglichen Schreibphase (Formulieren, Überarbeiten) findet der Einsatz von KI nur selten statt. Als Reaktion hierauf sind die Lehr-Lern-Module des Projektes entlang des Schreibprozesses konzipiert. Betrachtet wird damit das <b>Anleiten</b>, <b>Begleiten</b> und <b>Nachbereiten</b> des Schreibens mit Hilfe von KI. </div>
            <div className={ProjektText2}>Jedes der insgesamt <b>drei Module</b> besteht aus zwei aufeinander aufbauenden Präsenzveranstaltungen im Umfang von je vier Stunden (die in Zusammenarbeit mit dem Schreibzentrum durchgeführt werden). Gerahmt werden diese von einem umfassenden Online-Angebot, mit dem vor allem den heterogenen Ausgangsbedingungen der Studierenden begegnet werden soll. So werden eine Reihen von Video-Tutorial und interaktiven Online-Aufgaben entwickelt, mit denen an den eigenen Vorerfahrungen angeknüpft werden kann.</div>
            <div className={ProjektText2}>Entwickelt und umgesetzt werden die Lehr-Lern-Module unter der Leitung von Dr. des. Gerrit Helm in Zusammenarbeit mit Dr. des. Florian Hesse und in Kooperation mit Dr. Peter Braun und den Schreibtutor:innen des Schreibzentrums der FSU Jena. </div>
        </div>
        <Footer/>             
       </div>
    )
}
export default Projektseite;