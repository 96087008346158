import '../styles/M3Q.css';
import '../styles/M3CK1.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie'; 
import Loader from '../../components/Loader';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

function M3Q()
{
    const location = useLocation();
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [UserCode, setUserCode] = useState(location.state?.UserCode);
    const Api = "https://www.schreibenmitki.de:8443/";

    const [M3QTitel, setM3QTitel] = useState('M3Q_Titel');
    const [M3QBox, setM3QBox] = useState('M3QBox');
    const [M3QBoxLabel, setM3QBoxLabel] = useState('M3QBoxLabel');
    const [M3QQsingle, setM3QQsingle] = useState('M3QQsingle');
    const [M3QQLabel, set3QQLabel] = useState('M3QQLabel');
    const [M3Qinput1, setM3Qinput1] = useState('M3Qinput1');
    const [M3QScaleBox, setM3QScaleBox] = useState('M3QScaleBox');
    const [M3QScaleBoxSuperTitel, setM3QScaleBoxSuperTitel] = useState('M3QScaleBoxSuperTitel');
    const [M3QScaleBoxScaleArea, setM3QScaleBoxScaleArea] = useState('M3QScaleBoxScaleArea');
    const [M3QScaleBoxScaleLabel, setM3QScaleBoxScaleLabel] = useState('M3QScaleBoxScaleLabel');
    const [MQ3Scale, setMQ3Scale] = useState('MQ3Scale');
    const [M3QScaleStep, setM3QScaleStep] = useState('M3QScaleStep');
    const [M3QScaleCheckBox, setM3QScaleCheckBox] = useState('M3QScaleCheckBox');
    const [M3QScaleCheckBoxLabel, setM3QScaleCheckBoxLabel] = useState('M3QScaleCheckBoxLabel');
    const [M3QButtonArea, setM3QButtonArea] = useState('M3QButtonArea');
    const [M3QButton1, setM3QButton1] = useState('M3QButton');
    const [M3QButton2, setM3QButton2] = useState('M3QButton_gone');
    const [LoaderStatus, setLoaderStatus] = useState('loading');
    const [M3CK1SLoader, setM3CK1SLoader] = useState('M3CK1SLoader_gone');

    const [FA_1, setFA_1] = useState('kein Fach');
    const [FA_2, setFA_2] = useState('kein Fach');
    const [SEM, setSEM] = useState(0);
    const [VOR_SCHREIB, setVOR_SCHREIB] = useState(99);
    const [VOR_KI, setVOR_KI] = useState(99);
    const [SCHS1, setSCHS1] = useState(99);
    const [SCHS2, setSCHS2] = useState(99);
    const [SCHS3, setSCHS3] = useState(99);
    const [AILit1, setAILit1] = useState(99);
    const [AILit2, setAILit2] = useState(99);
    const [AILit3, setAILit3] = useState(99);
    const [BELIEF1, setBELIEF1] = useState(99);
    const [BELIEF2, setBELIEF2] = useState(99);
    const [BELIEF3, setBELIEF3] = useState(99);
    

   const [VOR_SCHREIB1_1_checked, setVOR_SCHREIB1_1_checked] = useState(false);
   const [VOR_SCHREIB1_2_checked, setVOR_SCHREIB1_2_checked] = useState(false);
   const [VOR_SCHREIB1_3_checked, setVOR_SCHREIB1_3_checked] = useState(false);
   const [VOR_SCHREIB1_4_checked, setVOR_SCHREIB1_4_checked] = useState(false);
   const [VOR_KI1_1_checked, setVOR_KI1_1_checked] = useState(false);
   const [VOR_KI1_2_checked, setVOR_KI1_2_checked] = useState(false);
   const [VOR_KI1_3_checked, setVOR_KI1_3_checked] = useState(false);
   const [VOR_KI1_4_checked, setVOR_KI1_4_checked] = useState(false);

   const [SCHS1_1_checked, setSCHS1_1_checked] = useState(false);
   const [SCHS1_2_checked, setSCHS1_2_checked] = useState(false);
   const [SCHS1_3_checked, setSCHS1_3_checked] = useState(false);
   const [SCHS1_4_checked, setSCHS1_4_checked] = useState(false);
   const [SCHS1_5_checked, setSCHS1_5_checked] = useState(false);
   const [SCHS2_1_checked, setSCHS2_1_checked] = useState(false);
   const [SCHS2_2_checked, setSCHS2_2_checked] = useState(false);
   const [SCHS2_3_checked, setSCHS2_3_checked] = useState(false);
   const [SCHS2_4_checked, setSCHS2_4_checked] = useState(false);
   const [SCHS2_5_checked, setSCHS2_5_checked] = useState(false);
   const [SCHS3_1_checked, setSCHS3_1_checked] = useState(false);
   const [SCHS3_2_checked, setSCHS3_2_checked] = useState(false);
   const [SCHS3_3_checked, setSCHS3_3_checked] = useState(false);
   const [SCHS3_4_checked, setSCHS3_4_checked] = useState(false);
   const [SCHS3_5_checked, setSCHS3_5_checked] = useState(false);
   const [AILIT1_1_checked, setAILIT1_1_checked] = useState(false);
   const [AILIT1_2_checked, setAILIT1_2_checked] = useState(false);
   const [AILIT1_3_checked, setAILIT1_3_checked] = useState(false);
   const [AILIT1_4_checked, setAILIT1_4_checked] = useState(false);
   const [AILIT1_5_checked, setAILIT1_5_checked] = useState(false);
   const [AILIT2_1_checked, setAILIT2_1_checked] = useState(false);
   const [AILIT2_2_checked, setAILIT2_2_checked] = useState(false);
   const [AILIT2_3_checked, setAILIT2_3_checked] = useState(false);
   const [AILIT2_4_checked, setAILIT2_4_checked] = useState(false);
   const [AILIT2_5_checked, setAILIT2_5_checked] = useState(false);
   const [AILIT3_1_checked, setAILIT3_1_checked] = useState(false);
   const [AILIT3_2_checked, setAILIT3_2_checked] = useState(false);
   const [AILIT3_3_checked, setAILIT3_3_checked] = useState(false);
   const [AILIT3_4_checked, setAILIT3_4_checked] = useState(false);
   const [AILIT3_5_checked, setAILIT3_5_checked] = useState(false);
   const [BELIEF1_1_checked, setBELIEF1_1_checked] = useState(false);
   const [BELIEF1_2_checked, setBELIEF1_2_checked] = useState(false);
   const [BELIEF1_3_checked, setBELIEF1_3_checked] = useState(false);
   const [BELIEF1_4_checked, setBELIEF1_4_checked] = useState(false);
   const [BELIEF1_5_checked, setBELIEF1_5_checked] = useState(false);
   const [BELIEF2_1_checked, setBELIEF2_1_checked] = useState(false);
   const [BELIEF2_2_checked, setBELIEF2_2_checked] = useState(false);
   const [BELIEF2_3_checked, setBELIEF2_3_checked] = useState(false);
   const [BELIEF2_4_checked, setBELIEF2_4_checked] = useState(false);
   const [BELIEF2_5_checked, setBELIEF2_5_checked] = useState(false);
   const [BELIEF3_1_checked, setBELIEF3_1_checked] = useState(false);
   const [BELIEF3_2_checked, setBELIEF3_2_checked] = useState(false);
   const [BELIEF3_3_checked, setBELIEF3_3_checked] = useState(false);
   const [BELIEF3_4_checked, setBELIEF3_4_checked] = useState(false);
   const [BELIEF3_5_checked, setBELIEF3_5_checked] = useState(false);


    const sendResults = () =>{
        
            
                setM3QButton1('M3QButton_gone');
                setM3CK1SLoader('M3CK1SLoader');
                Axios.post(Api+"saveMeta", {UserCode: UserCode, FA_1: FA_1, FA_2: FA_2, SEM: SEM, VOR_SCHREIB: VOR_SCHREIB, VOR_KI: VOR_KI, SCHS1: SCHS1, SCHS2: SCHS2, SCHS3: SCHS3, AILit1: AILit1, AILit2: AILit2, AILit3: AILit3, BELIEF1: BELIEF1, BELIEF2: BELIEF2, BELIEF3: BELIEF3}).catch(err => err);

                setTimeout(()=>{
                    Axios.post(Api+'confirmMeta', {UserCode: UserCode}).then((result) =>{
                        if(result.data.confirmation == "No data!")
                            {
                                setLoaderStatus('error');
                            }
                            if(result.data.confirmation == "Confirm!")
                            {
                                setM3CK1SLoader('M3CK1SLoader_gone');
                                setM3QButton2('M3QButton');                                
                            } 

                    }).catch(err => err)
                },5000)
            
        
        
    }


    useEffect(()=>{
        
        if(windowSize.current[0] > 1600)
        {
           
        }

        if(windowSize.current[0] < 1300)
        {
            
        }

        if(windowSize.current[0] < 600)
        {
            setM3QTitel('M3Q_Titel_Mobile');
            setM3QBox('M3QBox_Mobile');
            setM3QBoxLabel('M3QBoxLabel_Mobile');
            setM3QQsingle('M3QQsingle_Mobile');
            set3QQLabel('M3QQLabel_Mobile');
            setM3Qinput1('M3Qinput1_Mobile');
            setM3QScaleBox('M3QScaleBox_Mobile');
            setM3QScaleBoxSuperTitel('M3QScaleBoxSuperTitel_Mobile');
            setMQ3Scale('MQ3Scale_Mobile');
            setM3QScaleStep('M3QScaleStep_Mobile');
            setM3QButtonArea('M3QButtonArea_Mobile');
            setM3QScaleCheckBoxLabel('M3QScaleCheckBoxLabel_Mobile');
        }

    }, windowSize.current[0]);



    const handle_VOR_SCHREIB = (wert) =>{setVOR_SCHREIB(wert);}
    const VOR_SCHREIB0 = () =>{ handle_VOR_SCHREIB(0); setVOR_SCHREIB1_1_checked(true); setVOR_SCHREIB1_2_checked(false); setVOR_SCHREIB1_3_checked(false);setVOR_SCHREIB1_4_checked(false);};
    const VOR_SCHREIB1 = () =>{ handle_VOR_SCHREIB(1); setVOR_SCHREIB1_1_checked(false); setVOR_SCHREIB1_2_checked(true); setVOR_SCHREIB1_3_checked(false);setVOR_SCHREIB1_4_checked(false);};
    const VOR_SCHREIB2 = () =>{ handle_VOR_SCHREIB(2); setVOR_SCHREIB1_1_checked(false); setVOR_SCHREIB1_2_checked(false); setVOR_SCHREIB1_3_checked(true);setVOR_SCHREIB1_4_checked(false);};
    const VOR_SCHREIB3 = () =>{ handle_VOR_SCHREIB(3); setVOR_SCHREIB1_1_checked(false); setVOR_SCHREIB1_2_checked(false); setVOR_SCHREIB1_3_checked(false);setVOR_SCHREIB1_4_checked(true);};

    const handle_VOR_KI = (wert) =>{setVOR_KI(wert);}
    const VOR_KI0 = () =>{ handle_VOR_KI(0); setVOR_KI1_1_checked(true); setVOR_KI1_2_checked(false); setVOR_KI1_3_checked(false);setVOR_KI1_4_checked(false);};
    const VOR_KI1 = () =>{ handle_VOR_KI(1); setVOR_KI1_1_checked(false); setVOR_KI1_2_checked(true); setVOR_KI1_3_checked(false);setVOR_KI1_4_checked(false);};
    const VOR_KI2 = () =>{ handle_VOR_KI(2); setVOR_KI1_1_checked(false); setVOR_KI1_2_checked(false); setVOR_KI1_3_checked(true);setVOR_KI1_4_checked(false);};
    const VOR_KI3 = () =>{ handle_VOR_KI(3); setVOR_KI1_1_checked(false); setVOR_KI1_2_checked(false); setVOR_KI1_3_checked(false);setVOR_KI1_4_checked(true);};

    const handle_SCHS1 = (wert) =>{setSCHS1(wert)}
    const handle_SCHS2 = (wert) =>{setSCHS2(wert)}
    const handle_SCHS3 = (wert) =>{setSCHS3(wert)}
    const click_SCHS1_1 = () => {handle_SCHS1(0); setSCHS1_1_checked(true);setSCHS1_2_checked(false);setSCHS1_3_checked(false);setSCHS1_4_checked(false);setSCHS1_5_checked(false);}
    const click_SCHS1_2 = () => {handle_SCHS1(1); setSCHS1_1_checked(false);setSCHS1_2_checked(true);setSCHS1_3_checked(false);setSCHS1_4_checked(false);setSCHS1_5_checked(false);}
    const click_SCHS1_3 = () => {handle_SCHS1(2); setSCHS1_1_checked(false);setSCHS1_2_checked(false);setSCHS1_3_checked(true);setSCHS1_4_checked(false);setSCHS1_5_checked(false);}
    const click_SCHS1_4 = () => {handle_SCHS1(3); setSCHS1_1_checked(false);setSCHS1_2_checked(false);setSCHS1_3_checked(false);setSCHS1_4_checked(true);setSCHS1_5_checked(false);}
    const click_SCHS1_5 = () => {handle_SCHS1(4); setSCHS1_1_checked(false);setSCHS1_2_checked(false);setSCHS1_3_checked(false);setSCHS1_4_checked(false);setSCHS1_5_checked(true);}
    const click_SCHS2_1 = () => {handle_SCHS2(0); setSCHS2_1_checked(true);setSCHS2_2_checked(false);setSCHS2_3_checked(false);setSCHS2_4_checked(false);setSCHS2_5_checked(false);}
    const click_SCHS2_2 = () => {handle_SCHS2(1); setSCHS2_1_checked(false);setSCHS2_2_checked(true);setSCHS2_3_checked(false);setSCHS2_4_checked(false);setSCHS2_5_checked(false);}
    const click_SCHS2_3 = () => {handle_SCHS2(2); setSCHS2_1_checked(false);setSCHS2_2_checked(false);setSCHS2_3_checked(true);setSCHS2_4_checked(false);setSCHS2_5_checked(false);}
    const click_SCHS2_4 = () => {handle_SCHS2(3); setSCHS2_1_checked(false);setSCHS2_2_checked(false);setSCHS2_3_checked(false);setSCHS2_4_checked(true);setSCHS2_5_checked(false);}
    const click_SCHS2_5 = () => {handle_SCHS2(4); setSCHS2_1_checked(false);setSCHS2_2_checked(false);setSCHS2_3_checked(false);setSCHS2_4_checked(false);setSCHS2_5_checked(true);}
    const click_SCHS3_1 = () => {handle_SCHS3(0); setSCHS3_1_checked(true);setSCHS3_2_checked(false);setSCHS3_3_checked(false);setSCHS3_4_checked(false);setSCHS3_5_checked(false);}
    const click_SCHS3_2 = () => {handle_SCHS3(1); setSCHS3_1_checked(false);setSCHS3_2_checked(true);setSCHS3_3_checked(false);setSCHS3_4_checked(false);setSCHS3_5_checked(false);}
    const click_SCHS3_3 = () => {handle_SCHS3(2); setSCHS3_1_checked(false);setSCHS3_2_checked(false);setSCHS3_3_checked(true);setSCHS3_4_checked(false);setSCHS3_5_checked(false);}
    const click_SCHS3_4 = () => {handle_SCHS3(3); setSCHS3_1_checked(false);setSCHS3_2_checked(false);setSCHS3_3_checked(false);setSCHS3_4_checked(true);setSCHS3_5_checked(false);}
    const click_SCHS3_5 = () => {handle_SCHS3(4); setSCHS3_1_checked(false);setSCHS3_2_checked(false);setSCHS3_3_checked(false);setSCHS3_4_checked(false);setSCHS3_5_checked(true);}
    const handleAILit1 = (wert) => {setAILit1(wert)}
    const handleAILit2 = (wert) => {setAILit2(wert)}
    const handleAILit3 = (wert) => {setAILit3(wert)}
    const clickAILit1_1 = () =>{handleAILit1(0); setAILIT1_1_checked(true);setAILIT1_2_checked(false);setAILIT1_3_checked(false);setAILIT1_4_checked(false);setAILIT1_5_checked(false);}
    const clickAILit1_2 = () =>{handleAILit1(1); setAILIT1_1_checked(false);setAILIT1_2_checked(true);setAILIT1_3_checked(false);setAILIT1_4_checked(false);setAILIT1_5_checked(false);}
    const clickAILit1_3 = () =>{handleAILit1(2); setAILIT1_1_checked(false);setAILIT1_2_checked(false);setAILIT1_3_checked(true);setAILIT1_4_checked(false);setAILIT1_5_checked(false);}
    const clickAILit1_4 = () =>{handleAILit1(3); setAILIT1_1_checked(false);setAILIT1_2_checked(false);setAILIT1_3_checked(false);setAILIT1_4_checked(true);setAILIT1_5_checked(false);}
    const clickAILit1_5 = () =>{handleAILit1(4); setAILIT1_1_checked(false);setAILIT1_2_checked(false);setAILIT1_3_checked(false);setAILIT1_4_checked(false);setAILIT1_5_checked(true);}
    const clickAILit2_1 = () =>{handleAILit2(0); setAILIT2_1_checked(true);setAILIT2_2_checked(false);setAILIT2_3_checked(false);setAILIT2_4_checked(false);setAILIT2_5_checked(false);}
    const clickAILit2_2 = () =>{handleAILit2(1); setAILIT2_1_checked(false);setAILIT2_2_checked(true);setAILIT2_3_checked(false);setAILIT2_4_checked(false);setAILIT2_5_checked(false);}
    const clickAILit2_3 = () =>{handleAILit2(2); setAILIT2_1_checked(false);setAILIT2_2_checked(false);setAILIT2_3_checked(true);setAILIT2_4_checked(false);setAILIT2_5_checked(false);}
    const clickAILit2_4 = () =>{handleAILit2(3); setAILIT2_1_checked(false);setAILIT2_2_checked(false);setAILIT2_3_checked(false);setAILIT2_4_checked(true);setAILIT2_5_checked(false);}
    const clickAILit2_5 = () =>{handleAILit2(4); setAILIT2_1_checked(false);setAILIT2_2_checked(false);setAILIT2_3_checked(false);setAILIT2_4_checked(false);setAILIT2_5_checked(true);}
    const clickAILit3_1 = () =>{handleAILit3(0); setAILIT3_1_checked(true);setAILIT3_2_checked(false);setAILIT3_3_checked(false);setAILIT3_4_checked(false);setAILIT3_5_checked(false);}
    const clickAILit3_2 = () =>{handleAILit3(1); setAILIT3_1_checked(false);setAILIT3_2_checked(true);setAILIT3_3_checked(false);setAILIT3_4_checked(false);setAILIT3_5_checked(false);}
    const clickAILit3_3 = () =>{handleAILit3(2); setAILIT3_1_checked(false);setAILIT3_2_checked(false);setAILIT3_3_checked(true);setAILIT3_4_checked(false);setAILIT3_5_checked(false);}
    const clickAILit3_4 = () =>{handleAILit3(3); setAILIT3_1_checked(false);setAILIT3_2_checked(false);setAILIT3_3_checked(false);setAILIT3_4_checked(true);setAILIT3_5_checked(false);}
    const clickAILit3_5 = () =>{handleAILit3(4); setAILIT3_1_checked(false);setAILIT3_2_checked(false);setAILIT3_3_checked(false);setAILIT3_4_checked(false);setAILIT3_5_checked(true);}
    const handleBELIEF1 = (wert) => {setBELIEF1(wert)}
    const handleBELIEF2 = (wert) => {setBELIEF2(wert)}
    const handleBELIEF3 = (wert) => {setBELIEF3(wert)}
    const clickBELIEF1_1 = () =>{handleBELIEF1(0); setBELIEF1_1_checked(true);setBELIEF1_2_checked(false);setBELIEF1_3_checked(false);setBELIEF1_4_checked(false);setBELIEF1_5_checked(false);}
    const clickBELIEF1_2 = () =>{handleBELIEF1(1); setBELIEF1_1_checked(false);setBELIEF1_2_checked(true);setBELIEF1_3_checked(false);setBELIEF1_4_checked(false);setBELIEF1_5_checked(false);}
    const clickBELIEF1_3 = () =>{handleBELIEF1(2); setBELIEF1_1_checked(false);setBELIEF1_2_checked(false);setBELIEF1_3_checked(true);setBELIEF1_4_checked(false);setBELIEF1_5_checked(false);}
    const clickBELIEF1_4 = () =>{handleBELIEF1(3); setBELIEF1_1_checked(false);setBELIEF1_2_checked(false);setBELIEF1_3_checked(false);setBELIEF1_4_checked(true);setBELIEF1_5_checked(false);}
    const clickBELIEF1_5 = () =>{handleBELIEF1(4); setBELIEF1_1_checked(false);setBELIEF1_2_checked(false);setBELIEF1_3_checked(false);setBELIEF1_4_checked(false);setBELIEF1_5_checked(true);}
    const clickBELIEF2_1 = () =>{handleBELIEF2(0); setBELIEF2_1_checked(true);setBELIEF2_2_checked(false);setBELIEF2_3_checked(false);setBELIEF2_4_checked(false);setBELIEF2_5_checked(false);}
    const clickBELIEF2_2 = () =>{handleBELIEF2(1); setBELIEF2_1_checked(false);setBELIEF2_2_checked(true);setBELIEF2_3_checked(false);setBELIEF2_4_checked(false);setBELIEF2_5_checked(false);}
    const clickBELIEF2_3 = () =>{handleBELIEF2(2); setBELIEF2_1_checked(false);setBELIEF2_2_checked(false);setBELIEF2_3_checked(true);setBELIEF2_4_checked(false);setBELIEF2_5_checked(false);}
    const clickBELIEF2_4 = () =>{handleBELIEF2(3); setBELIEF2_1_checked(false);setBELIEF2_2_checked(false);setBELIEF2_3_checked(false);setBELIEF2_4_checked(true);setBELIEF2_5_checked(false);}
    const clickBELIEF2_5 = () =>{handleBELIEF2(4); setBELIEF2_1_checked(false);setBELIEF2_2_checked(false);setBELIEF2_3_checked(false);setBELIEF2_4_checked(false);setBELIEF2_5_checked(true);}
    const clickBELIEF3_1 = () =>{handleBELIEF3(0); setBELIEF3_1_checked(true);setBELIEF3_2_checked(false);setBELIEF3_3_checked(false);setBELIEF3_4_checked(false);setBELIEF3_5_checked(false);}
    const clickBELIEF3_2 = () =>{handleBELIEF3(1); setBELIEF3_1_checked(false);setBELIEF3_2_checked(true);setBELIEF3_3_checked(false);setBELIEF3_4_checked(false);setBELIEF3_5_checked(false);}
    const clickBELIEF3_3 = () =>{handleBELIEF3(2); setBELIEF3_1_checked(false);setBELIEF3_2_checked(false);setBELIEF3_3_checked(true);setBELIEF3_4_checked(false);setBELIEF3_5_checked(false);}
    const clickBELIEF3_4 = () =>{handleBELIEF3(3); setBELIEF3_1_checked(false);setBELIEF3_2_checked(false);setBELIEF3_3_checked(false);setBELIEF3_4_checked(true);setBELIEF3_5_checked(false);}
    const clickBELIEF3_5 = () =>{handleBELIEF3(4); setBELIEF3_1_checked(false);setBELIEF3_2_checked(false);setBELIEF3_3_checked(false);setBELIEF3_4_checked(false);setBELIEF3_5_checked(true);}


    




    

    return(
    <div className='M3QWrapper'>
        <div className='M3Questionnaire'>
            <div className={M3QTitel}>Ein paar Fragen: </div>
            <div className={M3QBox}>
                <div className={M3QBoxLabel}>Was sind Ihre Studienfächer?</div>
                <div className={M3QQsingle}>
                    <div className={M3QQLabel}>Fach 1</div><input className={M3Qinput1} onChange={(e) => {setFA_1(e.target.value)}}></input><div className={M3QQLabel}>Fach 2</div><input className={M3Qinput1} onClick={(e) => {setFA_2(e.target.value)}}></input>
                </div>
            </div>
            <div className={M3QBox}>
                <div className={M3QBoxLabel}>In welchem Studiensemester befinden Sie sich? (Bei abweichenden Zahlen in den Fächern, wählen Sie die höhere Semesteranzahl).</div>
                <div className={M3QQsingle}>
                    <div className={M3QQLabel}>Anzahl:</div><input className={M3Qinput1} placeholder='z.B. 3' onChange={(e) => setSEM(e.target.value)}></input>
                </div>
            </div>

            <div className={M3QBox}>
                <div className={M3QBoxLabel}>Haben Sie sich bereits (z.B. in Seminaren, Fortbildungen, Workshops) bereits mit dem Thema 'Schreiben' auseinandergesetzt?.</div>
                <div className={MQ3Scale}>
                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_SCHREIB0} checked={VOR_SCHREIB1_1_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>noch nie</div>
                    </div>
                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_SCHREIB1} checked={VOR_SCHREIB1_2_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>selten</div>
                    </div>

                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_SCHREIB2} checked={VOR_SCHREIB1_3_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>häufig</div>
                    </div>

                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_SCHREIB3} checked={VOR_SCHREIB1_4_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>sehr häufig</div>
                    </div>

                </div>
            </div>

            <div className={M3QBox}>
                <div className={M3QBoxLabel}>Haben Sie sich bereits (z.B. in Seminaren, Fortbildungen, Workshops) bereits mit dem Thema 'KI' auseinandergesetzt?.</div>
                <div className={MQ3Scale}>
                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_KI0} checked={VOR_KI1_1_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>noch nie</div>
                    </div>
                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_KI1} checked={VOR_KI1_2_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>selten</div>
                    </div>

                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_KI2} checked={VOR_KI1_3_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>häufig</div>
                    </div>

                    <div className={M3QScaleStep}>
                        <input type='Checkbox' onClick={VOR_KI3} checked={VOR_KI1_4_checked}></input>
                        <div className={M3QScaleCheckBoxLabel}>sehr häufig</div>
                    </div>

                </div>
            </div>

            <div className={M3QScaleBox}>
                <div className={M3QScaleBoxSuperTitel}>Wie sicher fühlen Sie sich im Hinblick auf die folgenden Tätigkeiten:</div>
                    <div className={M3QScaleBoxScaleArea}>
                        <div className={M3QScaleBoxScaleLabel}>Das Schreiben gezielt fördern (z.B. Schreibstrategien).</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS1_1} checked={SCHS1_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>sehr unsicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS1_2} checked={SCHS1_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>eher unsicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS1_3} checked={SCHS1_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>neutral</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS1_4} checked={SCHS1_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>eher sicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS1_5} checked={SCHS1_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>sehr sicher</div>
                            </div>
                        </div>       

                        <div className={M3QScaleBoxScaleLabel}>Schreibkompetenz diagnostizieren (z.B. Fehlerschwerpunkte in Schüler:innentexten identifizieren, Schreibentwicklung einschätzen).</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS2_1} checked={SCHS2_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>sehr unsicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS2_2} checked={SCHS2_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>eher unsicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS2_3} checked={SCHS2_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>neutral</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS2_4} checked={SCHS2_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>eher sicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS2_5} checked={SCHS2_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>sehr sicher</div>
                            </div>
                        </div>         
                        <div className={M3QScaleBoxScaleLabel}>Kriteriengeleitete Rückmeldungen zu Schüler:innentexten geben.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS3_1} checked={SCHS3_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>sehr unsicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS3_2} checked={SCHS3_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>eher unsicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS3_3} checked={SCHS3_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>neutral</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS3_4} checked={SCHS3_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>eher sicher</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={click_SCHS3_5} checked={SCHS3_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>sehr sicher</div>
                            </div>
                        </div>                     

                    </div>
            </div>

            <div className={M3QScaleBox}>
                <div className={M3QScaleBoxSuperTitel}>Bitte geben Sie an, inwieweit die folgenden Aussagen auf Sie selbst zutreffen:</div>
                    <div className={M3QScaleBoxScaleArea}>
                        <div className={M3QScaleBoxScaleLabel}>Ich kann erklären, wie Künstliche Intelligenz, maschinelles Lernen und künstliche neuronale Netzwerke funktionieren.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit1_1} checked={AILIT1_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit1_2} checked={AILIT1_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft eher nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit1_3} checked={AILIT1_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft teilweise zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit1_4} checked={AILIT1_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft eher zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit1_5} checked={AILIT1_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft voll zu</div>
                            </div>
                        </div>

                        <div className={M3QScaleBoxScaleLabel}>Ich kann Schwächen der KI benennen und reflektieren, welche Gefahren daraus hervorgehen können.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit2_1} checked={AILIT2_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit2_2} checked={AILIT2_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft eher nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit2_3} checked={AILIT2_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft teilweise zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit2_4} checked={AILIT2_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft eher zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit2_5} checked={AILIT2_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft voll zu</div>
                            </div>
                        </div>

                        <div className={M3QScaleBoxScaleLabel}>Ich kann zielsicher mehrere Anwendungen aus meinem alltäglichen Leben nennen, die auf Künstlicher Intelligenz basieren.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit3_1} checked={AILIT3_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit3_2} checked={AILIT3_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft eher nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit3_3} checked={AILIT3_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft teilweise zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit3_4} checked={AILIT3_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft eher zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickAILit3_5} checked={AILIT3_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>trifft voll zu</div>
                            </div>
                        </div>

                        

                    </div>
            </div>

            <div className={M3QScaleBox}>
                <div className={M3QScaleBoxSuperTitel}>Inwieweit stimmen Sie den Aussagen zu?</div>
                    <div className={M3QScaleBoxScaleArea}>
                        <div className={M3QScaleBoxScaleLabel}>KI-Anwendungen werden das unterrichtliche Schreiben in meinem Fach verändern.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF1_1} checked={BELIEF1_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF1_2} checked={BELIEF1_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme eher nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF1_3} checked={BELIEF1_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>neutral</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF1_4} checked={BELIEF1_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme eher zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF1_5} checked={BELIEF1_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme voll zu</div>
                            </div>
                        </div>

                        <div className={M3QScaleBoxScaleLabel}>KI-Anwendungen werden die Art und Weise, wie Schreiben in meinem Fach unterrichtet wird, verändern.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF2_1} checked={BELIEF2_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF2_2} checked={BELIEF2_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme eher nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF2_3} checked={BELIEF2_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>neutral</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF2_4} checked={BELIEF2_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme eher zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF2_5} checked={BELIEF2_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme voll zu</div>
                            </div>
                        </div>

                        <div className={M3QScaleBoxScaleLabel}>KI-Anwendungen werden die Art der Schreibaufgaben in meinem Fach verändern.</div>
                        <div className={MQ3Scale}>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF3_1} checked={BELIEF3_1_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF3_2} checked={BELIEF3_2_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme eher nicht zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF3_3} checked={BELIEF3_3_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>neutral</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF3_4} checked={BELIEF3_4_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme eher zu</div>
                            </div>
                            <div className={M3QScaleStep}>
                                <input type='Checkbox' onClick={clickBELIEF3_5} checked={BELIEF3_5_checked}></input>
                                <div className={M3QScaleCheckBoxLabel}>stimme voll zu</div>
                            </div>
                        </div>
                      
                    </div>
                    <div className={M3QButtonArea}>
                        <div className={M3QButton1} onClick={sendResults}>abschließen</div><div className={M3CK1SLoader}><Loader Status={LoaderStatus}></Loader></div>
                        <div className={M3QButton2}><Link to="/">weiter</Link></div>
                    </div>

            

            </div>
           
        </div>
    </div>
    )

}
export default M3Q;