import '../styles/M3PK1.css';
import '../styles/M3CK1.css';
import '../styles/M3TK1.css';
import '../styles/M3HW.css';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import SchulertextViewer from '../../components/SchulertextViewer.js';
import Cookies from 'js-cookie'; 


function M3HW(){
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [UserCode, setUserCode] = useState(Cookies.get("SchreibenmitKI_UserCode"));
    const [Datum, setDatum] = useState(Cookies.get("SchreibenmitKI_Datum"));
    const [Kurs, setKurs] = useState(Cookies.get("SchreibenmitKI_WorkshopCode"));
    const Api = "https://www.schreibenmitki.de:8443/";
    const [M3CK1S_Top, setM3CK1S_Top] = useState('M3CK1S_Top');
    const [M3CK1SNavigationArea, setM3CK1SNavigationArea] = useState('M3CK1SNavigationArea');
    const [M3CK1SNavElement1, setM3CK1SNavElement1] = useState('M3CK1SNavElement');
    const [M3CK1S_Middle, setM3CK1S_Middle] = useState('M3CK1S_Middle');
    const [M3CK1SNavImage1, setM3CK1SNavImage1] = useState('NavImage_Gears_active');
    const [M3CK1SNavImage2, setM3CK1SNavImage2] = useState('NavImage_Lernende');
    const [M3CK1SNavImage3, setM3CK1SNavImage3] = useState('NavImage_Connor');
    const [M3CK1SNavImage4, setM3CK1SNavImage4] = useState('NavImage_Danielle');
    const [M3CK1SNavImage5, setM3CK1SNavImage5] = useState('NavImage_Luca');
    const [M3CK1SNavImage6, setM3CK1SNavImage6] = useState('NavImage_Miles');
    const [M3CK1S_ContentArea1, setM3CK1S_ContentArea1] = useState('M3CK1S_Content_Area');
    const [M3CK1S_ContentArea2, setM3CK1S_ContentArea2] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea3, setM3CK1S_ContentArea3] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea4, setM3CK1S_ContentArea4] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea5, setM3CK1S_ContentArea5] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea6, setM3CK1S_ContentArea6] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1SNextButton, setM3CK1SNextButton] = useState('M3CK1S_NextButton_first');
    const [currentPage, setCurrentPage] = useState(0);
    const [Lernende2, setLernende2] = useState('Lernende2');
    const [Lernende2Titel, setLernende2Titel] = useState('Lernende2Titel');
    const [Lernende2Area, setLernende2Area] = useState('Lernende2Area');
    const [LernendeRow, setLernendeRow] = useState('LernendeRow');
    const [triggerLoad, setTriggerLoad] = useState(false);
    const [Lernenden2Box, setLernenden2Box] = useState('Lernenden2Box');
    const [Lernenden2PictureArea, setLernenden2PictureArea] = useState('Lernenden2PictureArea');
    const [Lernenden2InfoArea, setLernenden2InfoArea] = useState('Lernenden2InfoArea');
    const [Lernenden2ImageBox, setLernenden2ImageBox] = useState('Lernenden2ImageBox');
    const [Lernenden2InfoRow, setLernenden2InfoRow] = useState('Lernenden2InfoRow');
    const [AufgabenErgebnisArea, setAufgabenErgebnisArea] = useState('AufgabenErgebnisArea');
    const [AufgabenErgebnisBox, setAufgabenErgebnisBox] = useState('AufgabenErgebnisBox');
    const [Lernenden2Schulbuchseite, setLernenden2Schulbuchseite] = useState('Lernenden2Schulbuchseite');
    const [Lernenden2SchulbuchseiteTitel,setLernenden2SchulbuchseiteTitel] = useState('Lernenden2SchulbuchseiteTitel');
    const [EnglischBox, setEnglischBox] = useState('EnglischBox');
    const [EnglischBoxColumn, setEnglischBoxColumn] = useState('EnglischBoxColumn');
    const [AufgabeTextRow, setAufgabeTextRow] = useState('AufgabeTextRow');
    const [AufgabeTextRow2, setAufgabeTextRow2] = useState('AufgabeTextRow2');
    const [AufgabeBildReihe, setAufgabeBildReihe] = useState('AufgabeBildReihe');
    const [AufgabeBildReihe2, setAufgabeBildReihe2] = useState('AufgabeBildReihe2');
    const [HistoColumns, setHistoColumns] = useState('HistoColumns');
    const [HistoColumnTextblock, setHistoColumnTextblock] = useState('HistoColumnTextblock');
    const [HistoColumnBildblock, setHistoColumnBildblock] = useState('HistoColumnBildblock');
    const [HausaufgabenField, setHausaufgabenField] = useState('HausaufgabenField');
    const [HausaufgabenText, setHausaufgabenText] = useState('HausaufgabenText');
    
   
    useEffect(()=>{

        if(windowSize.current[0] > 2000)
        {
            setHausaufgabenText('HausaufgabenText_4K');
        }

        if(windowSize.current[0] < 1300)
        {
            
        }

        if(windowSize.current[0] < 600)
        {
            setM3CK1SNavigationArea('M3CK1SNavigationArea_Mobile');
            setM3CK1SNavElement1('M3CK1SNavElement_Mobile');
            setM3CK1SNextButton('M3CK1S_NextButton_Mobile_first');
            setLernende2('Lernende2_Mobile');
            setLernende2Titel('Lernende2Titel_Mobile');
            setLernende2Area('Lernende2Area_Mobile');
            setLernendeRow('LernendeRow_Mobile');
            setLernenden2Box('Lernenden2Box_Mobile');
            setLernenden2ImageBox('Lernenden2ImageBox_Mobile');
            setLernenden2InfoRow('Lernenden2InfoRow_Mobile');
            setAufgabenErgebnisArea('AufgabenErgebnisArea_Mobile');
            setAufgabenErgebnisBox('AufgabenErgebnisBox_Mobile');
            setLernenden2Schulbuchseite('Lernenden2Schulbuchseite_Mobile');
            setLernenden2SchulbuchseiteTitel('Lernenden2SchulbuchseiteTitel_Mobile');
            setEnglischBox('EnglischBox_Mobile');
            setEnglischBoxColumn('EnglischBoxColumn_Mobile');
            setAufgabeTextRow('AufgabeTextRow_Mobile');
            setAufgabeTextRow2('AufgabeTextRow2_Mobile');
            setAufgabeBildReihe('AufgabeBildReihe_Mobile');
            setAufgabeBildReihe2('AufgabeBildReihe_Mobile');
            setHistoColumns('HistoColumns_Mobile');
            setHistoColumnTextblock('HistoColumnTextblock_Mobile');
            setHistoColumnBildblock('HistoColumnBildblock_Mobile');
            setHausaufgabenField('HausaufgabenField_Mobile');
            setHausaufgabenText('HausaufgabenText_Mobile');
            setFullScreen();
        }

    }, windowSize.current[0]);


    const ConnorsText = "Aufgabe 3 | | Ich denke, dass wegen Religion schon viel passiert ist | was nicht gut war und das ohne Religion vielleicht | nicht passiert wäre. Aber es passiert eh viel schlechtes | auf der Welt und wenn Religion den Menschen hilft sich | gut und sicher zu fühlen dann ist Religion gut, | denke ich. | Meine Oma zum Beispiel die geht gern in die Kirche, | weil sie dort an Opa denken kann und da nicht allein | ist mit dem an jemanden denken."
    const MilesText = "Aufgabe 3 | |Ich finde, das es gut wäre wenn es immer Erdbeeren in | Deutschland gibt, aber wenn bei uns nur im Juni Zeit |für Erdbeeren ist und wir deswegen Erdbeeren von woanders | bekommen wo es nicht viel Wasser gibt und die Erdbeeren|  weit gebracht werden dann soll es Erdbeeren nur | im Sommer geben."
    const LucasText = "Aufgabe 3 | |In Athen konnte man viel direkter wählen. Da habe ich das Gefühl | das die meine Stimme viel mehr Wert wäre.  Und ich denke, das | die Demokratie aus Athen ein Vorbild für  uns sein kann. | Natürlich ist das heute viel schwerer, weil wir vielleicht viel mehr | Menschen sind und dass wäre immer sehr aufwendig wegen allem mit allen | zusammen zu kommen. Aber ich denke auch dann würden mehr kommen. | Man wüsste auch garnicht, ob man immer alle für alles braucht  zum | abstimmen, aber das kann man ja dann überlegen. Alles was besonders | wichtig ist, sollten alle bestimmen können. | Ich denke, dass dann auch viel mehr Menschen mitmachen würden. | Außerdem soll es ja nur ein Vorbild sein. Aus dem Grund kann man Sachen, | die früher vielleicht nicht so gut gelaufn sind ja verbessern. Und ich denke, | dass wir die Demokratie aus Athen als Vorbild nehmen müssen."
    const DanielleText = "I think that we today can`t live without Computers or mobile phones. | Many things we do work with the internet and we can`t get into the | internet without a mobile phone or a computer. For example if I want | to contact my friends I write to them on my mobile phone. Or I will | call them with it. But I couldn`t do it without the mobilephone. Or if I | want something that I can`t get near my town I must order it over the | internet. For example from Amazon. So i need a computer. Another thing | is that also our teachers use Computers to show us things in the lessons. | These are a few reasons why we need computers and mobile phones today. | And that is why I think  we need them for our daily live  and can`t really | live without them.";

    const nextPage = () =>{
        setM3CK1SNextButton('M3CK1S_NextButton');
        if(windowSize.current[0] < 600)
            {
                setM3CK1SNextButton('M3CK1S_NextButton_Mobile');
            }

        if(currentPage==5)
            {
                    setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                    setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                    setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
                    setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
                    setM3CK1S_ContentArea5('M3CK1S_Content_Area_leftOff'); 
                    setM3CK1S_ContentArea6('M3CK1S_Content_Area');
                    setCurrentPage(4);
                    setM3CK1SNavImage1('NavImage_Gears');
                    setM3CK1SNavImage2('NavImage_Lernende');
                    setM3CK1SNavImage3('NavImage_Connor');
                    setM3CK1SNavImage4('NavImage_Danielle');
                    setM3CK1SNavImage5('NavImage_Luca');
                    setM3CK1SNavImage6('NavImage_Miles_active');
                    setTriggerLoad(true);
            }
    

        if(currentPage==4)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
                setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
                setM3CK1S_ContentArea5('M3CK1S_Content_Area'); 
                setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
                setCurrentPage(5);
                setM3CK1SNavImage1('NavImage_Gears');
                setM3CK1SNavImage2('NavImage_Lernende');
                setM3CK1SNavImage3('NavImage_Connor');
                setM3CK1SNavImage4('NavImage_Danielle');
                setM3CK1SNavImage5('NavImage_Luca_active');
                setTriggerLoad(true);
            }

        if(currentPage==3)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
                setM3CK1S_ContentArea4('M3CK1S_Content_Area'); 
                setCurrentPage(4);
                setM3CK1SNavImage1('NavImage_Gears');
                setM3CK1SNavImage2('NavImage_Lernende');
                setM3CK1SNavImage3('NavImage_Connor');
                setM3CK1SNavImage4('NavImage_Danielle_active');
                setM3CK1SNavImage5('NavImage_Luca');
                setTriggerLoad(true);
            }

        if(currentPage==2)
        {
            setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
            setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
            setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
            setCurrentPage(3);
            setM3CK1SNavImage1('NavImage_Gears');
            setM3CK1SNavImage2('NavImage_Lernende');
            setM3CK1SNavImage3('NavImage_Connor_active');
            setM3CK1SNavImage4('NavImage_Danielle');
            setM3CK1SNavImage5('NavImage_Luca');
            setTriggerLoad(true);
        }

        if(currentPage==1)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area');  
                setCurrentPage(2);
                setM3CK1SNavImage1('NavImage_Gears');
                setM3CK1SNavImage2('NavImage_Lernende_active');
                setM3CK1SNavImage3('NavImage_Connor');
                setM3CK1SNavImage4('NavImage_Danielle');
                setM3CK1SNavImage5('NavImage_Luca');
                setTriggerLoad(true);
            }
        
        if(currentPage==0)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area');  
                setCurrentPage(2);
                setM3CK1SNavImage1('NavImage_Gears');
                setM3CK1SNavImage2('NavImage_Lernende_active');
                setM3CK1SNavImage3('NavImage_Connor');
                setM3CK1SNavImage4('NavImage_Danielle');
                setM3CK1SNavImage5('NavImage_Luca');
                setTriggerLoad(true);
            }
       
    }

       

    const selectPage1 = () =>{
        setM3CK1SNavImage1('NavImage_Gears_active');
        setM3CK1SNavImage2('NavImage_Lernende');
        setM3CK1SNavImage3('NavImage_Connor');
        setM3CK1SNavImage4('NavImage_Danielle');
        setM3CK1SNavImage5('NavImage_Luca');
        setM3CK1SNavImage6('NavImage_Miles');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_rightOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(1);
    }

    const selectPage2 = () =>{
        setM3CK1SNavImage1('NavImage_Gears');
        setM3CK1SNavImage2('NavImage_Lernende_active');
        setM3CK1SNavImage3('NavImage_Connor');
        setM3CK1SNavImage4('NavImage_Danielle');
        setM3CK1SNavImage5('NavImage_Luca');
        setM3CK1SNavImage6('NavImage_Miles');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(2);
    }

    const selectPage3 = () =>{
        setM3CK1SNavImage1('NavImage_Gears');
        setM3CK1SNavImage2('NavImage_Lernende');
        setM3CK1SNavImage3('NavImage_Connor_active');
        setM3CK1SNavImage4('NavImage_Danielle');
        setM3CK1SNavImage5('NavImage_Luca');
        setM3CK1SNavImage6('NavImage_Miles');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(3);
    }

    const selectPage4 = () =>{
        setM3CK1SNavImage1('NavImage_Gears');
        setM3CK1SNavImage2('NavImage_Lernende');
        setM3CK1SNavImage3('NavImage_Connor');
        setM3CK1SNavImage4('NavImage_Danielle_active');
        setM3CK1SNavImage5('NavImage_Luca');
        setM3CK1SNavImage6('NavImage_Miles');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff');
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff'); 
        setTriggerLoad(true);
        setCurrentPage(4);
    }

    const selectPage5 = () =>{
        setM3CK1SNavImage1('NavImage_Gears');
        setM3CK1SNavImage2('NavImage_Lernende');
        setM3CK1SNavImage3('NavImage_Connor');
        setM3CK1SNavImage4('NavImage_Danielle');
        setM3CK1SNavImage5('NavImage_Luca_active');
        setM3CK1SNavImage6('NavImage_Miles');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(5);
    }

    const selectPage6 = () =>{
        setM3CK1SNavImage1('NavImage_Gears');
        setM3CK1SNavImage2('NavImage_Lernende');
        setM3CK1SNavImage3('NavImage_Connor');
        setM3CK1SNavImage4('NavImage_Danielle');
        setM3CK1SNavImage5('NavImage_Luca');
        setM3CK1SNavImage6('NavImage_Miles_active');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area');
        setTriggerLoad(true);
        setCurrentPage(6);
    }

    

    const setFullScreen = () =>{
        const element = document.documentElement;

        if (element.requestFullscreen) {
          element.requestFullscreen().catch(err => err);
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen().catch(err => err);
        }
      }



    return(
        <div className='M3CK1S_Wrapper'>
            <div className={M3CK1S_Top}>
                <div className={M3CK1SNavigationArea}>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage1} onClick={selectPage1}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage2} onClick={selectPage2}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage3} onClick={selectPage3}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage4} onClick={selectPage4}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                    <div className={M3CK1SNavImage5} onClick={selectPage5}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                    <div className={M3CK1SNavImage6} onClick={selectPage6}></div>
                    </div>
                </div>
            </div>
            <div className={M3CK1S_Middle}>
                <div className={M3CK1S_ContentArea1}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                            <div className={HausaufgabenField}>
                            <div className={Lernende2Titel}>Anwendung im Fach:</div>
                                <div className={HausaufgabenText}>
                                    <p>Auf den Folgeseiten finden Sie Lernendentexte aus den Fächern <b>Religion</b>, <b>Englisch</b>, <b>Geschichte</b> und <b>Geographie</b>. </p>
                                    <p>Wählen Sie EINEN der Lernendentexte aus. Betrachten Sie den Lernendentext und die Schreibaufgabe sorgfältig. </p>
                                    <p>Verschaffen Sie sich zunächst einen Eindruck hinsichtlich der <b>fachlichen Korrektheit</b> des Schreibproduktes.</p>
                                    <p> Interpretieren Sie anschließend, inwieweit es den Lernenden gelingt, die Schreibaufgabe zu lösen und die <i>zerdehnte Kommunikationssituation </i> zu überwinden. Nehmen Sie dazu in den Blick, welche Ausprägungen von </p>
                                    <ul>
                                        <li>Ausdruckskompetenz</li>
                                        <li>Kontextualisierungskompetenz</li>
                                        <li>Antizipationskompetenz</li>
                                        <li>Textgestaltungskompetenz</li></ul>
                                    <p>sich bereits im Lernendentext zeigt.</p>
                                    <p>Verfassen Sie anschließend schriftlich ein <b>lernförderliches Feedback</b>, in welchem sie eine zielführende Fokussierung und einen zielführenden Umfang wählen und <i>Feed up</i>, <i>Feedback</i> und <i>Feed forward</i> formulieren.</p>
                                    <p>Sie können auf eine <b>KI-Anwendung</b> (z.B. ChatGPT) zurückgreifen, müssen es aber nicht. </p>
                                    <p><b>Bitte geben Sie Ihre Ergebnisse </b><Link to="/M3HW_A" style={{textDecoration:"none", color:"blue"}}>hier</Link><b> ab.</b></p>
                                    
                                </div>
                            </div>
                               
                            </div>                
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea2}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={Lernende2}>
                                    <div className={Lernende2Titel}>Vorstellung der Lernenden:</div>
                                    <div className={Lernende2Area}>
                                        <div className={LernendeRow}>
                                            <div className={Lernenden2Box}>
                                                <div className={Lernenden2PictureArea}><div className={Lernenden2ImageBox}><div className='Lerner_Connor'></div></div></div>
                                                <div className={Lernenden2InfoArea}>
                                                    <div className={Lernenden2InfoRow}><b>Connor</b></div>
                                                    <div className={Lernenden2InfoRow}>Alter: 12 Jahre</div>
                                                    <div className={Lernenden2InfoRow}>Familie: 3 Geschwister</div>
                                                    <div className={Lernenden2InfoRow}>Hobbies: Basketball und Kendo</div>
                                                </div>
                                            </div>
                                            <div className={Lernenden2Box}>
                                                <div className={Lernenden2PictureArea}><div className={Lernenden2ImageBox}><div className='Lerner_Danielle'></div></div></div>
                                                <div className={Lernenden2InfoArea}>
                                                    <div className={Lernenden2InfoRow}><b>Danielle</b></div>
                                                    <div className={Lernenden2InfoRow}>Alter: 11 Jahre</div>
                                                    <div className={Lernenden2InfoRow}>Familie: eine kleine Schwester</div>
                                                    <div className={Lernenden2InfoRow}>Hobbies: Tischtennis</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={LernendeRow}>
                                            <div className={Lernenden2Box}>
                                                <div className={Lernenden2PictureArea}><div className={Lernenden2ImageBox}><div className='Lerner_Luca'></div></div></div>
                                                <div className={Lernenden2InfoArea}>
                                                    <div className={Lernenden2InfoRow}><b>Luca</b></div>
                                                    <div className={Lernenden2InfoRow}>Alter: 12 Jahre</div>
                                                    <div className={Lernenden2InfoRow}>Familie: keine Geschwister</div>
                                                    <div className={Lernenden2InfoRow}>Hobbies: Zeichnen</div>
                                                </div>
                                            </div>
                                            <div className={Lernenden2Box}>
                                                <div className={Lernenden2PictureArea}><div className={Lernenden2ImageBox}><div className='Lerner_Miles'></div></div></div>
                                                <div className={Lernenden2InfoArea}>
                                                    <div className={Lernenden2InfoRow}><b>Miles</b></div>
                                                    <div className={Lernenden2InfoRow}>Alter: 11 Jahre</div>
                                                    <div className={Lernenden2InfoRow}>Familie: ein großer Bruder</div>
                                                    <div className={Lernenden2InfoRow}>Hobbies: Gaming</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea3}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={Lernende2}>
                                    <div className={Lernende2Titel}>Connor: Warum es Religionen gibt (Religion)</div>
                                    <div className={AufgabenErgebnisArea}>
                                    <div className={AufgabenErgebnisBox}><div className={Lernenden2Schulbuchseite}>
                                        <div className={Lernenden2SchulbuchseiteTitel}><b style={{color: "rgb(93, 181, 118)"}}>Warum es Religionen gibt</b></div>
                                        <div className={AufgabeBildReihe}><div className='Aufgabenbild_Religion'></div></div>
                                        <div className={AufgabeTextRow}>Pia und ihre Freundin Kerstin sitzen auf einer Bank. Kerstin hat sich einen Kettenanhänger gekauft, auf dem ein Fisch zu sehen ist. Kerstins Eltern gehören keiner Religion an. Als sie ihre Tochter mit dem Anhänger sehen, sind sie irritiert. Kerstins Vater erklärt: „Das ist ein christliches Symbol. Wer dieses Symbol trägt, bekennt sich zum Christentum.“ Seine Meinung ist: „Religionen bringen nur Unheil. Immer wieder werden Kriege im Namen der Religion geführt. Gerade das Christentum hat viel Schlimmes in der Geschichte angerichtet. Es wäre besser, wenn es keine Religion geben würde.“ Kerstins Mutter glaubt, dass Religionen und der Glaube an Gott von den eigentlichen Problemen der Welt ablenken. Sie sagt, man solle sich lieber darum kümmern, dass es keinen Hunger und keine Ungerechtigkeit mehr auf der Welt gibt.

Kerstin fragt ihre Freundin, was sie über Religion denkt. Pia antwortet: „Meine Eltern haben eine andere Einstellung. Mein Vater ist katholisch. Er besucht zwar selten den Gottesdienst, aber er meint, dass die Religion Menschen Halt geben kann – gerade, wenn man nicht mehr weiter weiß. Vater hat früh seine Eltern verloren. Der Glaube an Gott hat ihn getröstet und ihm Hoffnung gegeben. Meine Mutter ist evangelisch. Sie ist im Kirchengemeinderat und sagt, man kann nur etwas verändern, wenn man mitmacht. Lange hat sie den Kindergottesdienst und Freizeiten für Kinder organisiert. Für sie ist Religion eine Gemeinschaft, in der man miteinander einen Glauben lebt. Außerdem glaubt sie, dass dieses Miteinander auch weltweit funktioniert. Sie setzt sich für die Partnerschaft mit einer Kirchengemeinde in Afrika ein. Mann könne am besten für andere etwas tun, wenn man weiß, wie die Leute leben, meint meine Mutter.</div>
                                        <div className={AufgabeTextRow}>
                                            <ol style={{color:"rgb(21, 137, 137)"}}>
                                                <li><b>Fasse die Argumente für oder gegen eine Religion zusammen. Stelle sie gegenüber und ergänze weitere.</b> </li>
                                                <li><b>Bewerte die Argumente für oder gegen eine Religion. Welche findest du einleuchtend, welche nicht?</b> </li>
                                                <li><b>Beurteile aus deiner Sicht, wofür eine Religion gut sein kann.</b> </li>
                                            </ol>
                                        </div>
                                        </div></div>
                                        <div className={AufgabenErgebnisBox}><SchulertextViewer Schulername={"Connor"} Lernertext={ConnorsText}></SchulertextViewer></div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea4}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={Lernende2}>
                                            <div className={Lernende2Titel}>Danielle: Discussing statements (Englisch)</div>
                                            <div className={AufgabenErgebnisArea}>
                                                <div className={AufgabenErgebnisBox}><div className={Lernenden2Schulbuchseite}>
                                                    <div className={Lernenden2SchulbuchseiteTitel}><b style={{color: "rgb(122, 153, 164)"}}>W7 Discussing statements</b></div>
                                                    <div className={EnglischBox}>
                                                        <div className={EnglischBoxColumn}>Bei diesem Aufgabenformat musst du deine Meinung zum vorgegebenen Thema schriftlich äußern</div>
                                                        <div className={EnglischBoxColumn}><b>TIPP:</b> Begründe deine Position stets. Nutze dazu auch Beispiele aus deiner Erfahrung.</div>
                                                        <div className={EnglischBoxColumn}><b>HINWEIS:</b> Erkundige dich bei deiner Lehrerin/deinem Lehrer, wie viele Wörter du schreiben sollst.</div>
                                                    </div>
                                                    <div className={AufgabeTextRow}>
                                                        <b>Choose ONE of the following statements, tick it and write down your opinion. <br/> Give reasons for your opinion using examples where possible.</b>
                                                    </div>
                                                    <div className={AufgabeTextRow}>
                                                        <ol>
                                                            <li>Parents shouldn´t worry about social networking sites because these sites are an important part of teenage communication.   [ ]</li>
                                                            <li style={{marginTop:2+"%"}}>In our modern world you can´t survive without a computer or a mobile phone. [<b>x</b>]</li>
                                                            <li style={{marginTop:2+"%"}}>Life for teenagers today is much easier than it was thirty years ago. [ ]</li>
                                                            <li style={{marginTop:2+"%"}}>Every teenager should do some volunteer work after finishing school. [ ]</li>
                                                            <li style={{marginTop:2+"%"}}>It is best for young people to take time out after school and travel to see the world. [ ]</li>
                                                            <li style={{marginTop:2+"%"}}>It is best to have children early in life because then you are fit and active enough for them. [ ]</li>
                                                        </ol>
                                                    </div>
                                                    
                                                </div></div>
                                                <div className={AufgabenErgebnisBox}><SchulertextViewer Schulername={"Danielle"} Lernertext={DanielleText}></SchulertextViewer></div>
                                            </div>
                                        </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea5}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={Lernende2}>
                                            <div className={Lernende2Titel}>Luca: Demokratie damals und heute (Geschichte)</div>
                                            <div className={AufgabenErgebnisArea}>
                                            <div className={AufgabenErgebnisBox}>
                                                <div className={Lernenden2Schulbuchseite}>
                                                <div className={Lernenden2SchulbuchseiteTitel}><b style={{color: "rgb(25, 84, 167)"}}>Demokratie damals und heute</b></div>
                                                <div className={HistoColumns}>
                                                    <div className={HistoColumnTextblock}>Was ist eigentlich Demokratie? Die deutsche Wiedergabe desgriechischen Wortes als „Volksherrschaft“ ist nicht sehr aussagekräftig. Das Volk kann Herrschaft auf verschiedene Weise ausüben. In den kleinen überschaubaren Stadtstaaten des antiken Griechenlands kam das Volk, das waren damals die freien Männer, auf dem Marktplatz zusammen und stimmte über Gesetze ab. In den heutigen Großstaaten ist diese Form direkter Demokratie nicht mehr praktikabel [zu verwirklichen]. Das Volk kann in der modernen Massendemokratie die Herrschaft nur mittelbar und indirekt ausüben, indem es sie auf Vertreter (Repräsentanten) überträgt.</div>
                                                    <div className={HistoColumnBildblock}><div className='Geschichte_Bild1'></div></div>
                                                </div>
                                                <div className={AufgabeBildReihe2}><div className='Geschichte_Bild2' style={{marginTop:2+"%"}}></div></div>
                                                <div className={AufgabeTextRow}>
                                                        <ol>
                                                            <li>Nenne die Unterschiede, die im Text aufgezählt wurden.</li>
                                                            <li>Übertrage die Tabelle M9 in dein Heft und ergänze die Zeile für antike Demokratie in Athen.</li>
                                                            <li>Bei der Beschäftigung mit der Demokratie im alten Athen stößt man auf zwei Meinungen: "Die Demokratie in Athen ist auch für uns vorbildlich" - "Die Demokratie in Athen kann für uns kein Vorbild sein". Erläutere deine Position und begründe sie.</li>
                                                        </ol>
                                                    </div>
                                                
                                            </div></div>
                                                <div className={AufgabenErgebnisBox}><SchulertextViewer Schulername={"Luca"} Lernertext={LucasText}></SchulertextViewer></div>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea6}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={Lernende2}>
                                        <div className={Lernende2Titel}>Miles: Erdbeeren zu jeder Jahreszeit (Geographie)</div>
                                        <div className={AufgabenErgebnisArea}>
                                        <div className={AufgabenErgebnisBox}><div className={Lernenden2Schulbuchseite}>
                                                <div className={Lernenden2SchulbuchseiteTitel}><b style={{color: "rgb(167, 25, 25)"}}>Erdbeeren zu jeder Jahreszeit</b></div>
                                                <div className={HistoColumns}>
                                                    <div className={HistoColumnTextblock}>Obst und Gemüse kommen ja meist vom Bauern aus der näheren Umgebung – so denken viele Verbraucher. Aber Erdbeeren sogar im Winter – wie passt das zusammen? Bei uns in Deutschland ist doch erst im Juni Erdbeerzeit.
Im Südwesten Spaniens, zwischen Sevilla und Huelva, reihen sich auf mehr als 53km2 Erdbeerplantage an Erdbeerplantage. Die Erdbeerzeit beginnt hier bereits im Februar. Das liegt am warmen Klima und besonderen Anbaubedingungen. Hochbeete auf den Feldern sollen die Wachstums- und Reifebedingungen für die Erdbeeren noch weiter verbessern. Rund 30000 Saisonarbeiter, vor allem aus Marokko, werden als Erntehelfer benötigt. So gelingt es den Spaniern als ersten Europäern im Jahr, die Märkte mit frischen Erdbeeren zu beliefern. Dann werden auch die höchsten Preise erzielt.</div>
                                                    <div className={HistoColumnBildblock}><div className='Erdbeere_Bild1'></div></div>
                                                </div>
                                                <div className={HistoColumns}>
                                                <div className={HistoColumnTextblock}><p><b>Die neue Devise heißt „Bio“</b></p>Lange Zeit galten die Anbaugebiete in Spanien, die Europa ganzjährig mit Obst und Gemüse aus ihren Gewächshäusern beliefern, als negatives Beispiel für den Pestizideinsatz. Inzwischen stellen immer mehr Obst- und Gemüseproduzenten auf den biologischen Anbau um, weil diese Nachfrage bei den europäischen Kunden stark zugenommen hat. Wer also im Winter Bio-Erdbeeren aus Spanien kauft, kann davon ausgehen, dass sie keine Chemikalien enthalten. Eingesetzt werden organische Düngemittel und die biologische Schädlingsbekämpfung. Damit sind die Vorschriften, die von der EU festgelegt worden sind, eingehalten und das Bio-Siegel kann vergeben werden. Dies ist aber keine Garantie für Umweltfreundlichkeit.</div>
                                                <div className={HistoColumnBildblock}><div className='Erdbeere_Bild2'></div></div>
                                                </div>
                                                <div className={HistoColumns}>
                                                <div className={HistoColumnTextblock}><p><b>Hoher Wasserbedarf</b></p>Erdbeeren benötigen viel Wasser. Für die Erzeugung von 1kg Erdbeeren sind 209 Liter Wasser erforderlich. In Deutschland angebaute Erdbeeren benötigen mit 321 Liter pro kg zwar deutlich mehr Wasser, allerdings wird der Wasserbedarf hier überwiegend aus Niederschlägen gedeckt. Um Wasser zu sparen, berechnet ein Computer den Wasserbedarf und steuert die Verteilung des Wassers. Da die Anbauregion jedoch zu den trockenen Gebieten Spaniens gehört, stammt ein Teil des benötigten Wassers auch aus einem Stausee und aus illegal angelegten Brunnen. Dadurch kommt es zu einem Absinken des Grundwasserspiegels. Die Flüsse führen inzwischen nur noch halb so viel Wasser wie vor 30 Jahren – die Gegend vertrocknet.</div>
                                                <div className={HistoColumnBildblock}><div className='Erdbeere_Bild3'></div></div>
                                                </div>
                                                <div className={HistoColumns}>
                                                <div className={HistoColumnTextblock}><p><b>Umweltbelastung durch den Transport</b></p>Damit die Früchte dem Verbraucher möglichst frisch auf den Märkten angeboten werden können, erfolgt der Transport entweder mit gekühlten und spezialgefederten Lastwagen in 50 Stunden über eine Strecke von rund 3000 Kilometern oder aber mit Flugzeugen. Bei der Umweltbelastung durch den CO2-Ausstoß schneidet das Flugzeug am schlechtesten ab. Allgemein kann man sagen, dass die Umweltbelastung mit der Entfernung, die ein Produkt reisen muss, um zum Kunden zu gelangen, immer schlechter wird. Allerdings können deutsche Erdbeeren in der Vorsaison auch Verursacher eines höheren CO2-Ausstoßes sein, da die deutschen Erdbeeren vor Saisonbeginn noch beheizt und mit Folie abgedeckt werden müssen.</div>
                                                <div className={HistoColumnBildblock}><div className='Erdbeere_Bild4'></div></div>
                                                </div>
                                                <div className={AufgabeTextRow}>
                                                        <ol>
                                                            <li>Erkläre, warum Erdbeeren in Spanien früh geerntet werden können.</li>
                                                            <li>Begründe, warum spanische Erdbeeren mit dem Bio-Siegel der EU ausgezeichnet werden können.</li>
                                                            <li><b>Diskutiert darüber, ob Erdbeeren in Deutschland zu jeder Zeit verfügbar sein müssen.</b></li>
                                                            <li>Benenne die Monate, in denen es die kleinste und die größte Auswahl an: 
                                                                <ul>
                                                                    <li>Obst aus Deutschland gibt.</li>
                                                                    <li>Gemüse aus Deutschland gibt.</li>
                                                                </ul>
                                                                Begründe.
                                                            </li>
                                                        </ol>
                                                    </div>
                                                
                                            </div></div>
                                            <div className={AufgabenErgebnisBox}><SchulertextViewer Schulername={"Mile"} Lernertext={MilesText}></SchulertextViewer></div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1SNextButton} onClick={nextPage}>{">"}</div>
            </div>

        </div>
    )
}

export default M3HW;;