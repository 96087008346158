import '../styles/M3Printer.css';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useState, useRef} from "react";
import Ergebnisse from './Ergebnisse';
import SchulertextViewer from './SchulertextViewer';
import Homeworkshower from './Homeworkshower';

function M3Printer2(props){

    const [Schuler, setSchuler] = useState(props.Schuler);
    const [triggerLoad2, setTriggerLoad2] = useState(false);
    const componentRef = useRef();
    const [derLernerText, setDerLernerText] = useState('');
    const [TriggerPrint, setTriggerPrint] = useState(false);
    const [printed, setPrinted] = useState(false);
    
    
    
   

    useEffect(()=>{
        if(printed == false)
            {   
                if(props.TriggerPrint == true)
                {   setPrinted(true);
                    printingProcess();
                }
            }
        
        
    },[props.TriggerPrint])

    const printingProcess = () =>{
        setTriggerLoad2(true);
        setTimeout(() => {
            handlePrint()
        },4000)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return(
        <div className='M3PrinterWrapper'>
            <div className='M3PrinterSuperTitel' onClick={printingProcess}>Druckansicht</div>
            <div className='M3Druckbereich' ref={componentRef}>
                <div className='M3Druck_Titel'>Ergebnis der kleinen 'Hausaufgabe</div>
                <div className='M3Druck_Einleitung'>Im Rahmen des Worshops <b style={{color:"#e1743c"}}>Feedback</b> <b>mit KI im Lehramt</b> bestand die Hausaufgabe darin, ein Feedback zu dem Lernendentext von {Schuler} zu verfassen. 
                Zunächst wurde der Lernendentext dafür bewusst analysiert. Es wurde interpretiert, inwieweit sich bereits ausprägungen der Schreibkompetenz im Lernendentext zeigen. Verfasst haben Sie hierzu folgendes Feedback:
                
                </div>
                <div className='Printer_LernerTextArea'>
                    <Homeworkshower Text={props.Feedbacktext}></Homeworkshower>
                </div>
                <div className='M3Druck_Einleitung'>Zufällige Teilnehmende aus dem Workshop haben Ihr Feedback gelesen und dann ein <i>Feedback zum Feedback</i> verfasst. Dieses finden Sie hier.</div>
                <div className='Printer_ErgebnisArea'>
                    <Homeworkshower Text={props.Kommentar}></Homeworkshower>
                </div>
                <div className='M3Druck_Einleitung'>Ich freue mich über Ihre Beteiligung am Workshop</div>
                <div className='M3Druck_Einleitung'>Gerrit Helm</div>
                <div className='M3Druck_Einleitung' b style={{color:"#e1743c"}}>schreibenmitki.de</div>
            </div>

        </div>
    )

}
export default M3Printer2;