import '../styles/M3HW_A.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie'; 
import Loader from '../../components/Loader';

function M3HW_A()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [UserCode, setUserCode] = useState(Cookies.get("SchreibenmitKI_UserCode"));
    const [Datum, setDatum] = useState('');
    const [Kurs, setKurs] = useState(Cookies.get("SchreibenmitKI_WorkshopCode"));
    const Api = "https://www.schreibenmitki.de:8443/";
        
    const [M3HW_A_Titel, setM3HW_A_Titel] = useState('M3HW_A_Titel');
    const [H3HW_A_Box, setH3HW_A_Box] = useState('H3HW_A_Box');
    const [H3HW_A_Box_Label, setH3HW_A_Box_Label] = useState('H3HW_A_Box_Label');
    const [H3HW_A_Box_Field, setH3HW_A_Box_Field] = useState('H3HW_A_Box_Field');
    const [H3HW_A_Box_Field2, setH3HW_A_Box_Field2] = useState('H3HW_A_Box_Field2');
    const [M3HW_A_But, setM3HW_A_But] = useState('M3HW_A_But_gone');
    const [M3HW_A_Student_Selector, setM3HW_A_Student_Selector] = useState('M3HW_A_Student_Selector');
    const [M3HW_A_Connor, setM3HW_A_Connor] = useState('M3HW_A_Student');
    const [M3HW_A_Danielle, setM3HW_A_Danielle] = useState('M3HW_A_Student');
    const [M3HW_A_Luca, setM3HW_A_Luca] = useState('M3HW_A_Student');
    const [M3HW_A_Miles, setM3HW_A_Miles] = useState('M3HW_A_Student');
    const [M3HW_A_Own, setM3HW_A_Own] = useState('M3HW_A_Student');
    const [studentSelected, setStudentSelected] = useState(false);
    const [Lernender, setLernender] = useState(0);
    const [FeedbackText, setFeedbackText] = useState('Hallo');
    const [ChatGPTChat, setChatGPTChat] = useState('');
    const [LoaderStatus, setLoaderStatus] = useState('loading');
    const [M3HW_LoaderArea, setM3HW_LoaderArea] = useState('M3HW_LoaderArea_gone');
    const [M3HW_Messenger, setM3HW_Messenger] = useState('M3HW_Messenger_gone');
    const [M3HW_MessengerText, setM3HW_MessengerText] = useState('');

    useEffect(()=>{
        let today = new Date();
        let date=today.getDate() + "."+ parseInt(today.getMonth()+1) +"."+today.getFullYear();
        setDatum(date);



        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1300)
        {
            
        }

        if(windowSize.current[0] < 600)
        {
            setM3HW_A_Titel('M3HW_A_Titel_Mobile');
            setH3HW_A_Box('H3HW_A_Box_Mobile');
            setH3HW_A_Box_Label('H3HW_A_Box_Label_Mobile');
            setH3HW_A_Box_Field('H3HW_A_Box_Field_Mobile');
            setH3HW_A_Box_Field2('H3HW_A_Box_Field2_Mobile');
            setM3HW_A_Student_Selector('M3HW_A_Student_Selector_Mobile');
            setM3HW_A_Connor('M3HW_A_Student_Mobile');
            setM3HW_A_Danielle('M3HW_A_Student_Mobile');
            setM3HW_A_Luca('M3HW_A_Student_Mobile');
            setM3HW_A_Miles('M3HW_A_Student_Mobile');
            setM3HW_A_Own('M3HW_A_Student_Mobile');
        }

    }, windowSize.current[0]);

    const selectOwnText = () =>{
        setStudentSelected(true);
        setLernender(5);
        setM3HW_A_Own('M3HW_A_Student_active');
        setM3HW_A_Connor('M3HW_A_Student');
        setM3HW_A_Danielle('M3HW_A_Student');
        setM3HW_A_Luca('M3HW_A_Student');
        setM3HW_A_Miles('M3HW_A_Student');

        if(windowSize.current[0] < 600)
        {
            setM3HW_A_Own('M3HW_A_Student_Mobileactive');
            setM3HW_A_Connor('M3HW_A_Student_Mobile');
            setM3HW_A_Danielle('M3HW_A_Student_Mobile');
            setM3HW_A_Luca('M3HW_A_Student_Mobile');
            setM3HW_A_Miles('M3HW_A_Student_Mobile');
        }

    }

    const selectConnor = () =>{
        setStudentSelected(true);
        setLernender(1);
        setM3HW_A_Own('M3HW_A_Student');
        setM3HW_A_Connor('M3HW_A_Student_active');
        setM3HW_A_Danielle('M3HW_A_Student');
        setM3HW_A_Luca('M3HW_A_Student');
        setM3HW_A_Miles('M3HW_A_Student');

        if(windowSize.current[0] < 600)
        {
            setM3HW_A_Own('M3HW_A_Student_Mobile');
            setM3HW_A_Connor('M3HW_A_Student_Mobile_active');
            setM3HW_A_Danielle('M3HW_A_Student_Mobile');
            setM3HW_A_Luca('M3HW_A_Student_Mobile');
            setM3HW_A_Miles('M3HW_A_Student_Mobile');
        }

    }

    const selectDanielle= () =>{
        setStudentSelected(true);
        setLernender(2);
        setM3HW_A_Own('M3HW_A_Student');
        setM3HW_A_Connor('M3HW_A_Student');
        setM3HW_A_Danielle('M3HW_A_Student_active');
        setM3HW_A_Luca('M3HW_A_Student');
        setM3HW_A_Miles('M3HW_A_Student');

        if(windowSize.current[0] < 600)
        {
            setM3HW_A_Own('M3HW_A_Student_Mobile');
            setM3HW_A_Connor('M3HW_A_Student_Mobile');
            setM3HW_A_Danielle('M3HW_A_Student_Mobile_active');
            setM3HW_A_Luca('M3HW_A_Student_Mobile');
            setM3HW_A_Miles('M3HW_A_Student_Mobile');
        }
    }

    const selectLuca= () =>{
        setStudentSelected(true);
        setLernender(3);
        setM3HW_A_Own('M3HW_A_Student');
        setM3HW_A_Connor('M3HW_A_Student');
        setM3HW_A_Danielle('M3HW_A_Student');
        setM3HW_A_Luca('M3HW_A_Student_active');
        setM3HW_A_Miles('M3HW_A_Student');

        if(windowSize.current[0] < 600)
        {
            setM3HW_A_Own('M3HW_A_Student_Mobile');
            setM3HW_A_Connor('M3HW_A_Student_Mobile');
            setM3HW_A_Danielle('M3HW_A_Student_Mobile');
            setM3HW_A_Luca('M3HW_A_Student_Mobile_active');
            setM3HW_A_Miles('M3HW_A_Student_Mobile');
        }
    }

    const selectMiles = () =>{
        setStudentSelected(true);
        setLernender(4);
        setM3HW_A_Own('M3HW_A_Student');
        setM3HW_A_Connor('M3HW_A_Student');
        setM3HW_A_Danielle('M3HW_A_Student');
        setM3HW_A_Luca('M3HW_A_Student');
        setM3HW_A_Miles('M3HW_A_Student_active');

        if(windowSize.current[0] < 600)
        {
            setM3HW_A_Own('M3HW_A_Student_Mobile');
            setM3HW_A_Connor('M3HW_A_Student_Mobile');
            setM3HW_A_Danielle('M3HW_A_Student_Mobile');
            setM3HW_A_Luca('M3HW_A_Student_Mobile');
            setM3HW_A_Miles('M3HW_A_Student_Mobile_active');
        }
    }

    const absenden = () =>{
        if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
        {
        setM3HW_A_But('M3HW_A_But_gone');
        setM3HW_LoaderArea('M3HW_LoaderArea');
        if(windowSize.current[0] < 600)
            {
                setM3HW_LoaderArea('M3HW_LoaderMobile');
            }
        Axios.post(Api+"saveM3HW", {UserCode:UserCode, Datum:Datum, Lerner: Lernender, Feedback: FeedbackText, ChatGPT: ChatGPTChat})
        setM3HW_Messenger('M3HW_Messenger');
        setM3HW_MessengerText('Upload läuft...');  
        setTimeout(()=>{
            Axios.post(Api+'confirmM3HW', {UserCode: UserCode, Datum:Datum}).then((result) =>{
                console.log(result.data.confirmation);
                if(result.data.confirmation == "No data!")
                {
                    setLoaderStatus('error');
                    setM3HW_Messenger('M3HW_Messenger');
                    setM3HW_MessengerText('Irgendwas ist schiefgegangen');
                }
                if(result.data.confirmation == "Confirm!")
                {
                    setM3HW_Messenger('M3HW_Messenger');
                    setM3HW_MessengerText('Upload hat funktioniert!');  
                    setLoaderStatus('finished');  
                                    
                }
            }).catch(err => err)

        },5000)
    }
    else
    {
        setM3HW_Messenger('M3HW_Messenger');
        setM3HW_MessengerText('Kein Benutzer gefunden. Bitte nochmal einloggen!')
    }
    }


    useEffect(()=>{
        if(studentSelected == true && FeedbackText != "")
        {
            setM3HW_A_But('M3HW_A_But');
            if(windowSize.current[0] < 600)
            {
                setM3HW_A_But('M3HW_A_But_Mobile');
            }
        }

    },[FeedbackText, ChatGPTChat]);

    const closeMessenger = () =>{
        setM3HW_Messenger('M3HW_Messenger_gone');
        setM3HW_MessengerText('');
    }


    return(
       <div className='M3HW_A_Wrapper'>
            <div className={M3HW_A_Titel}>Hausaufgabe einreichen</div>
            <div className={H3HW_A_Box}>
                <div className={H3HW_A_Box_Label}>Ausgewählte Schüler:in:</div>
                <div className={M3HW_A_Student_Selector}>
                <div className={M3HW_A_Own} onClick={selectOwnText}>Eigener Text</div>
                    <div className={M3HW_A_Connor} onClick={selectConnor}>Connor (Religion)</div>
                    <div className={M3HW_A_Danielle} onClick={selectDanielle}>Danielle (Englisch)</div>
                    <div className={M3HW_A_Luca} onClick={selectLuca}>Luca (Geschichte)</div>
                    <div className={M3HW_A_Miles} onClick={selectMiles}>Miles (Geographie)</div>
                    
                </div>
            </div>
            <div className={H3HW_A_Box}>
                <div className={H3HW_A_Box_Label}>Feedbacktext</div>
                <textarea className={H3HW_A_Box_Field2} placeholder='Hier den verfassten Feedbacktext eingeben (z.B. aus Word kopieren).' onChange={(e) => {setFeedbackText(e.target.value)}}></textarea>
                
            </div>
            <div className={H3HW_A_Box}>
                <div className={H3HW_A_Box_Label}>Chat aus ChatGPT:</div>
                <input className={H3HW_A_Box_Field} placeholder='Hier bitte den Link zum durchgeführten Chat mit ChatGPT (https://chatgpt.com/share/...) eingeben.' onChange={(e)=> {setChatGPTChat(e.target.value)}}></input>
            </div>
            <div className={H3HW_A_Box}>
                <div className='M3HW_A_But_Area'>
                    <div className={M3HW_A_But} onClick={absenden}>absenden</div><div className={M3HW_LoaderArea}><Loader Status={LoaderStatus}></Loader></div>
                </div>
            </div>
            <div className={M3HW_Messenger}>
                <div className='M3HW_Messenger_Textarea'>{M3HW_MessengerText}</div>
                <div className='M3HW_MessengerButton' onClick={closeMessenger}>Okay</div>
            </div>
       </div>
    )
}
export default M3HW_A;