import '../styles/Loader.css'
import { useEffect, useRef, useState } from 'react';

function Loader(props)
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [LoaderRing, setLoaderRing] = useState('LoaderRing_Loading');
    const [LoaderError, setLoaderError] = useState('Loader_Error_gone');
    const [status, setStatus] = useState(props.Status);
    const [LoaderBreakY, setLoaderBreakY] = useState('LoaderBreakY');
    const [LoaderBreakX, setLoaderBreakX] = useState('LoaderBreakX');

    useEffect(()=>{
        if(props.Status == "loading")
        {
            setLoaderRing('LoaderRing_Loading');
            if(windowSize.current[0] > 1600)
                {
                    
                }
        
                if(windowSize.current[0] < 1200)
                {
                    setLoaderRing('LoaderRing_Loading_Ipad');
                    setLoaderBreakY('LoaderBreakY_Ipad');
                    setLoaderBreakX('LoaderBreakX_Ipad');
                }
        
                if(windowSize.current[0] < 900)
                {
                       
                }
        
                if(windowSize.current[0] < 600)
                {
                    setLoaderRing('LoaderRing_Loading_Mobile');
                    setLoaderBreakX('LoaderBreakX_Mobile');
                    setLoaderBreakY('LoaderBreakY_Mobile');
                }
        }

        if(props.Status == "finished")
        {
            setLoaderRing('LoaderRing_Finished');
            setLoaderBreakX('LoaderBreakX_Finished');

            if(windowSize.current[0] < 600)
                {
                    setLoaderRing('LoaderRing_Mobile_Finished');
                    setLoaderBreakX('LoaderBreakX_Mobile_Finished');
                }
        }

        if(props.Status == "error")
        {
            setLoaderRing('LoaderRing_gone');
            setLoaderError('Loader_Error');
        }

    },[props.Status])


    useEffect(()=>{

        

    }, windowSize.current[0]);


    return(
        <div className='LoaderWrapper'>
            <div className= {LoaderRing}>
                <div className={LoaderBreakY}>
                <div className={LoaderBreakX}>
                <div className={LoaderBreakY}></div>
                </div>
                
                </div>
            </div>
            <div className={LoaderError}>
                X
            </div>
        </div>
    )
}
export default Loader;