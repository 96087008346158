import '../styles/Anmeldeseite.css'
import Navigation from '../components/Navigation';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Button from '../components/Button';
import Axios from 'axios';

function Anmeldeseite()
{

    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const location = useLocation();
    window.scrollTo(0,0);

    const [AnmeldeTitel, setAnmeldetitel] = useState('AnmeldeTitel');
    const [AnmeldeRow, setAnmeldeRow] = useState('AnmeldeRow');
    const [AnmeldeLabel, setAnmeldeLabel] = useState('AnmeldeLabel');
    const [AnmeldeField, setAnmeldeField] = useState('AnmeldeField');
    const [AnmeldeField2, setAnmeldeField2] = useState('AnmeldeField2');
    const [FieldArea, setFieldArea] = useState('FieldArea');
    const [AnmeldeButton, setAnmeldeButton] = useState('AnmeldeButton_gone');
    const [SuccessWindow, setSuccessWidow] = useState('SuccessWindow_gone');
    const [SWTitel, setSWTitel] = useState('SWTitel');
    const [SWCloseLinkRow, setSWCloseLinkRow] = useState('SWCloseLinkRow');
    const [SWCloseLink, setSWCloseLink] = useState('SWCloseLink');
    const WorkshopTitel = location.state?.Titel;
    const Datum1 = location.state?.Datum1;
    const Datum2 = location.state?.Datum2;
    const KursId = location.state?.KursId;

    const [Vorname, setVorname] = useState('');
    const [Nachname, setNachname] = useState('');
    const [mailadresse, setMailadresse] = useState('')
    const [Fach1, setFach1] = useState('Fach1');
    const [Fach2, setFach2] = useState('Fach2');
    const Api = "https://www.schreibenmitki.de:8443/";

    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
            
        }

        if(windowSize.current[0] < 600)
        {
            setAnmeldetitel('AnmeldeTitel_Mobile');
            setAnmeldeRow('AnmeldeRow_Mobile');
            setAnmeldeLabel('AnmeldeLabel_Mobile');
            setAnmeldeField('AnmeldeField_Mobile');
            setAnmeldeField2('AnmeldeField2_Mobile');
            setFieldArea('FieldArea_Mobile');
            setSWTitel('SWTitel_Mobile');
            setSWCloseLink('SWCloseLink_Mobile')
        }

    }, windowSize.current[0]);


    const uberprufen = ()=>{
        var namensCheck = false;
        var mailCheck = false;
    
        if(Vorname.length > 2 && Nachname.length > 2)
            {
                namensCheck = true;
            }
        
            if(mailadresse.length > 5)
                {
                    if(mailadresse.includes("@"))
                    {
                        if(mailadresse.includes("."))
                        {
                            mailCheck = true;
                           
                        }
        
                    }
                    else{
                        mailCheck = false;
                        
                    }
                }
                if( namensCheck == true &&  mailCheck == true)
                    {
                        setAnmeldeButton('AnmeldeButton');
                    }
                else
                {
                    setAnmeldeButton('AnmeldeButton_gone');
                }
    }

    const anmelden = () =>{
        setSuccessWidow('SuccessWindow');
        const Name = Vorname+" "+Nachname;
        Axios.post(Api+"TeilnehmerAnmelden", {Name: Vorname, Mail: mailadresse, UserCode: Nachname, Durchgang: KursId, Fach1:Fach1, Fach2: Fach2});
    }

    return(
        <div className='AnmeldeWrapper'>
            <Navigation Page={"Landingpage"}></Navigation>
            <div className={AnmeldeTitel}>Anmeldung:</div>
            <div className={AnmeldeRow}>
                <div className={AnmeldeLabel}>Workshop-Titel:</div>
                <div className={AnmeldeLabel}>{WorkshopTitel}</div>
            </div>
            <div className={AnmeldeRow}>
                <div className={AnmeldeLabel}>Datum:</div>
                <div className={AnmeldeLabel}>{Datum1}<p>{Datum2}</p></div>
            </div>
            <div className={AnmeldeRow}>
                <div className={AnmeldeLabel}>Name:</div>
                <div className={FieldArea}>
                <input className={AnmeldeField} placeholder='Vorname' onChange={(e) => {setVorname(e.target.value)}} onMouseEnter={uberprufen} onMouseLeave={uberprufen}></input>
                <input className={AnmeldeField} placeholder='Nachname' onChange={(e) => {setNachname(e.target.value)}} onMouseEnter={uberprufen} onMouseLeave={uberprufen}></input>
                </div>
            </div>
            <div className={AnmeldeRow}>
                <div className={AnmeldeLabel}>Mail-Adresse:</div>
                <input className={AnmeldeField2} placeholder='Mail@uni-jena.de' onChange={(e) => {setMailadresse(e.target.value)}} onMouseEnter={uberprufen} onMouseLeave={uberprufen}></input>
            </div>
            <div className={AnmeldeRow}>
                <div className={AnmeldeLabel}>Studienfächer:</div>
                <div className={FieldArea}>
                <input className={AnmeldeField} placeholder='1. Studienfach' onChange={(e) => {setFach1(e.target.value)}} onMouseEnter={uberprufen} onMouseLeave={uberprufen}></input>
                <input className={AnmeldeField} placeholder='2. Studienfach' onChange={(e) => {setFach2(e.target.value)}} onMouseEnter={uberprufen} onMouseLeave={uberprufen}></input>
                </div>
            </div>
            <div className={AnmeldeRow}>
            <div className={AnmeldeLabel}></div>
            <div className={AnmeldeLabel}><div className={AnmeldeButton} onClick={anmelden}><Button MarginLeft="15" Text="Anmelden"></Button></div></div>
                
            </div>
            <div className={SuccessWindow}>
                    <div className={SWTitel}>Ihre Anmeldung war erfolgreich!</div>
                    <div className={SWCloseLink}><Link to="/Modul3" style={{textDecoration:"none", color:"black"}}>Okay</Link></div>

            </div>

        </div>
    )
}
export default Anmeldeseite;