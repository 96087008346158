import '../styles/Philipp2023.css';
import { useEffect, useRef, useState } from 'react';

export function Philipp2023() {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [GridRow1, setGridRow1] = useState('P2023_Grid_Row_neutral1');
    const [GridRow2, setGridRow2] = useState('P2023_Grid_Row_neutral2');
    const [GridRow3, setGridRow3] = useState('P2023_Grid_Row_neutral1');

    const [GridRow1Cell1, setGridRow1Cell1] = useState('P2023_Grid_Cell_neutral');
    const [GridRow1Cell2, setGridRow1Cell2] = useState('P2023_Grid_Cell_neutral');
    const [GridRow1Cell3, setGridRow1Cell3] = useState('P2023_Grid_Cell_neutral');
    const [GridRow2Cell1, setGridRow2Cell1] = useState('P2023_Grid_Cell_neutral');
    const [GridRow2Cell2, setGridRow2Cell2] = useState('P2023_Grid_Cell_neutral');
    const [GridRow2Cell3, setGridRow2Cell3] = useState('P2023_Grid_Cell_neutral');
    const [GridRow3Cell1, setGridRow3Cell1] = useState('P2023_Grid_Cell_neutral');
    const [GridRow3Cell2, setGridRow3Cell2] = useState('P2023_Grid_Cell_neutral');
    const [GridRow3Cell3, setGridRow3Cell3] = useState('P2023_Grid_Cell_neutral');
    const [connectorHorizontalCell, setConnectorHorizontalCell] = useState('connectorHorizontalCell');
    const [connectorHorizontal, setConnectorHorizontal] = useState('connectorHorizontal');
    const [connectorVerticalCell, setConnectorVerticalCell] = useState('connectorVerticalCell');
    const [connectorVertical, setConnectorVertical] = useState('connectorVertical');
    const [PhilippContentBox, setPhilippContentBox] = useState('PhilippContentBox');
    const [PhilippLabelTitel, setPhilippLabelTitel] = useState('PhilippLabelTitel');
    const [PhilippContentBox1_Content, setPhilippContentBox1_Content] = useState('PhilippContentBox_Content_gone');
    const [PhilippContentBox1_Label, setPhilippContentBox1_Label] = useState('PhilippContentBox_Label');
    const [PhilippContentBox2_Content, setPhilippContentBox2_Content] = useState('PhilippContentBox_Content_gone');
    const [PhilippContentBox2_Label, setPhilippContentBox2_Label] = useState('PhilippContentBox_Label');
    const [PhilippContentBox3_Content, setPhilippContentBox3_Content] = useState('PhilippContentBox_Content_gone');
    const [PhilippContentBox3_Label, setPhilippContentBox3_Label] = useState('PhilippContentBox_Label');
    const [PhilippContentBox4_Content, setPhilippContentBox4_Content] = useState('PhilippContentBox_Content_gone');
    const [PhilippContentBox4_Label, setPhilippContentBox4_Label] = useState('PhilippContentBox_Label');
    const [PhilippBoxContentTitel, setPhilippBoxContentTitel] = useState('PhilippBoxContentTitel');
    const [PhilippBoxContentContent, setPhilippBoxContentContent] = useState('PhilippBoxContentContent');
    const [MobileMode, setMobileMode] = useState(false);


    useEffect(() => {

        if (windowSize.current[0] > 1600) {
        }

        if (windowSize.current[0] < 1200) {
        }

        if (windowSize.current[0] < 900) {
        }

        if (windowSize.current[0] < 600) {
            setConnectorHorizontal('connectorHorizontal_Mobile');
            setConnectorVertical('connectorVertical_Mobile');
            setPhilippLabelTitel('PhilippLabelTitel_Mobile');
            setPhilippBoxContentTitel('PhilippBoxContentTitel_Mobile');
            setPhilippBoxContentContent('PhilippBoxContentContent_Mobile_start');
            setMobileMode(true);

        }

    }, windowSize.current[0]);

    const openBox1 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox2_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox3_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox4_Label('PhilippContentBox_Label_gone');
        setGridRow1('P2023_Grid_Row_max');
        setGridRow2('P2023_Grid_Row_min');
        setGridRow3('P2023_Grid_Row_min');
        setGridRow1Cell1('P2023_Grid_Cell_max');
        setGridRow1Cell2('P2023_Grid_Cell_min');
        setGridRow1Cell3('P2023_Grid_Cell_min');
        setGridRow2Cell1('P2023_Grid_Cell_max');
        setGridRow2Cell2('P2023_Grid_Cell_min');
        setGridRow2Cell3('P2023_Grid_Cell_min');
        setGridRow3Cell1('P2023_Grid_Cell_max');
        setGridRow3Cell2('P2023_Grid_Cell_min');
        setGridRow3Cell3('P2023_Grid_Cell_min');

        setPhilippContentBox1_Content('PhilippContentBox1_Content');
        if (MobileMode == true) {
            setPhilippBoxContentContent('PhilippBoxContentContent_Mobile');
        }
    };

    const closeBox1 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label');
        setPhilippContentBox2_Label('PhilippContentBox_Label');
        setPhilippContentBox3_Label('PhilippContentBox_Label');
        setPhilippContentBox4_Label('PhilippContentBox_Label');
        setGridRow1('P2023_Grid_Row_neutral1');
        setGridRow2('P2023_Grid_Row_neutral2');
        setGridRow3('P2023_Grid_Row_neutral1');
        setGridRow1Cell1('P2023_Grid_Cell_neutral');
        setGridRow1Cell2('P2023_Grid_Cell_neutral');
        setGridRow1Cell3('P2023_Grid_Cell_neutral');
        setGridRow2Cell1('P2023_Grid_Cell_neutral');
        setGridRow2Cell2('P2023_Grid_Cell_neutral');
        setGridRow2Cell3('P2023_Grid_Cell_neutral');
        setGridRow3Cell1('P2023_Grid_Cell_neutral');
        setGridRow3Cell2('P2023_Grid_Cell_neutral');
        setGridRow3Cell3('P2023_Grid_Cell_neutral');

        setPhilippContentBox1_Content('PhilippContentBox_Content_gone');
    };

    const openBox2 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox2_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox3_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox4_Label('PhilippContentBox_Label_gone');
        setGridRow1('P2023_Grid_Row_max');
        setGridRow2('P2023_Grid_Row_min');
        setGridRow3('P2023_Grid_Row_min');
        setGridRow1Cell1('P2023_Grid_Cell_min');
        setGridRow1Cell2('P2023_Grid_Cell_min');
        setGridRow1Cell3('P2023_Grid_Cell_max');
        setGridRow2Cell1('P2023_Grid_Cell_min');
        setGridRow2Cell2('P2023_Grid_Cell_min');
        setGridRow2Cell3('P2023_Grid_Cell_max');
        setGridRow3Cell1('P2023_Grid_Cell_min');
        setGridRow3Cell2('P2023_Grid_Cell_min');
        setGridRow3Cell3('P2023_Grid_Cell_max');
        setPhilippContentBox1_Content('PhilippContentBox_Content_gone');
        setPhilippContentBox3_Content('PhilippContentBox_Content_gone');
        setPhilippContentBox4_Content('PhilippContentBox_Content_gone');


        setPhilippContentBox2_Content('PhilippContentBox1_Content');
        if (MobileMode == true) {
            setPhilippBoxContentContent('PhilippBoxContentContent_Mobile');
        }
    };

    const closeBox2 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label');
        setPhilippContentBox2_Label('PhilippContentBox_Label');
        setPhilippContentBox3_Label('PhilippContentBox_Label');
        setPhilippContentBox4_Label('PhilippContentBox_Label');
        setGridRow1('P2023_Grid_Row_neutral1');
        setGridRow2('P2023_Grid_Row_neutral2');
        setGridRow3('P2023_Grid_Row_neutral1');
        setGridRow1Cell1('P2023_Grid_Cell_neutral');
        setGridRow1Cell2('P2023_Grid_Cell_neutral');
        setGridRow1Cell3('P2023_Grid_Cell_neutral');
        setGridRow2Cell1('P2023_Grid_Cell_neutral');
        setGridRow2Cell2('P2023_Grid_Cell_neutral');
        setGridRow2Cell3('P2023_Grid_Cell_neutral');
        setGridRow3Cell1('P2023_Grid_Cell_neutral');
        setGridRow3Cell2('P2023_Grid_Cell_neutral');
        setGridRow3Cell3('P2023_Grid_Cell_neutral');

        setPhilippContentBox2_Content('PhilippContentBox_Content_gone');
    };

    const openBox3 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox2_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox3_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox4_Label('PhilippContentBox_Label_gone');
        setGridRow1('P2023_Grid_Row_min');
        setGridRow2('P2023_Grid_Row_min');
        setGridRow3('P2023_Grid_Row_max');
        setGridRow1Cell1('P2023_Grid_Cell_max');
        setGridRow1Cell2('P2023_Grid_Cell_min');
        setGridRow1Cell3('P2023_Grid_Cell_min');
        setGridRow2Cell1('P2023_Grid_Cell_max');
        setGridRow2Cell2('P2023_Grid_Cell_min');
        setGridRow2Cell3('P2023_Grid_Cell_min');
        setGridRow3Cell1('P2023_Grid_Cell_max');
        setGridRow3Cell2('P2023_Grid_Cell_min');
        setGridRow3Cell3('P2023_Grid_Cell_min');
        setPhilippContentBox1_Content('PhilippContentBox_Content_gone');
        setPhilippContentBox2_Content('PhilippContentBox_Content_gone');
        setPhilippContentBox4_Content('PhilippContentBox_Content_gone');

        setPhilippContentBox3_Content('PhilippContentBox1_Content');
        if (MobileMode == true) {
            setPhilippBoxContentContent('PhilippBoxContentContent_Mobile');
        }
    };

    const closeBox3 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label');
        setPhilippContentBox2_Label('PhilippContentBox_Label');
        setPhilippContentBox3_Label('PhilippContentBox_Label');
        setPhilippContentBox4_Label('PhilippContentBox_Label');
        setGridRow1('P2023_Grid_Row_neutral1');
        setGridRow2('P2023_Grid_Row_neutral2');
        setGridRow3('P2023_Grid_Row_neutral1');
        setGridRow1Cell1('P2023_Grid_Cell_neutral');
        setGridRow1Cell2('P2023_Grid_Cell_neutral');
        setGridRow1Cell3('P2023_Grid_Cell_neutral');
        setGridRow2Cell1('P2023_Grid_Cell_neutral');
        setGridRow2Cell2('P2023_Grid_Cell_neutral');
        setGridRow2Cell3('P2023_Grid_Cell_neutral');
        setGridRow3Cell1('P2023_Grid_Cell_neutral');
        setGridRow3Cell2('P2023_Grid_Cell_neutral');
        setGridRow3Cell3('P2023_Grid_Cell_neutral');
        
        setPhilippContentBox3_Content('PhilippContentBox_Content_gone');
    };

    const openBox4 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox2_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox3_Label('PhilippContentBox_Label_gone');
        setPhilippContentBox4_Label('PhilippContentBox_Label_gone');
        setGridRow1('P2023_Grid_Row_min');
        setGridRow2('P2023_Grid_Row_min');
        setGridRow3('P2023_Grid_Row_max');
        setGridRow1Cell1('P2023_Grid_Cell_min');
        setGridRow1Cell2('P2023_Grid_Cell_min');
        setGridRow1Cell3('P2023_Grid_Cell_max');
        setGridRow2Cell1('P2023_Grid_Cell_min');
        setGridRow2Cell2('P2023_Grid_Cell_min');
        setGridRow2Cell3('P2023_Grid_Cell_max');
        setGridRow3Cell1('P2023_Grid_Cell_min');
        setGridRow3Cell2('P2023_Grid_Cell_min');
        setGridRow3Cell3('P2023_Grid_Cell_max');
        setPhilippContentBox1_Content('PhilippContentBox_Content_gone');
        setPhilippContentBox2_Content('PhilippContentBox_Content_gone');
        setPhilippContentBox3_Content('PhilippContentBox_Content_gone');

        setPhilippContentBox4_Content('PhilippContentBox1_Content');
        if (MobileMode == true) {
            setPhilippBoxContentContent('PhilippBoxContentContent_Mobile');
        }
    };

    const closeBox4 = () => {
        setPhilippContentBox1_Label('PhilippContentBox_Label');
        setPhilippContentBox2_Label('PhilippContentBox_Label');
        setPhilippContentBox3_Label('PhilippContentBox_Label');
        setPhilippContentBox4_Label('PhilippContentBox_Label');
        setGridRow1('P2023_Grid_Row_neutral1');
        setGridRow2('P2023_Grid_Row_neutral2');
        setGridRow3('P2023_Grid_Row_neutral1');
        setGridRow1Cell1('P2023_Grid_Cell_neutral');
        setGridRow1Cell2('P2023_Grid_Cell_neutral');
        setGridRow1Cell3('P2023_Grid_Cell_neutral');
        setGridRow2Cell1('P2023_Grid_Cell_neutral');
        setGridRow2Cell2('P2023_Grid_Cell_neutral');
        setGridRow2Cell3('P2023_Grid_Cell_neutral');
        setGridRow3Cell1('P2023_Grid_Cell_neutral');
        setGridRow3Cell2('P2023_Grid_Cell_neutral');
        setGridRow3Cell3('P2023_Grid_Cell_neutral');

        setPhilippContentBox4_Content('PhilippContentBox_Content_gone');
        if (MobileMode == true) {
            setPhilippBoxContentContent('PhilippBoxContentContent_Mobile');
        }
    };


    return (
        <div className='Philipp2023Wrappper'>
            <div className={GridRow1}>
                <div className={GridRow1Cell1}><div className={PhilippContentBox}><div className={PhilippContentBox1_Label} onClick={openBox1}><div className={PhilippLabelTitel}>Inhalt des Feedbacks</div></div>
                    <div className={PhilippContentBox1_Content} onClick={closeBox1}>
                        <div className={PhilippBoxContentTitel}>Inhalt des Feedbacks:</div>
                        <div className={PhilippBoxContentContent}>
                            <p><b>Art der Information:</b></p>
                            <table style={{ marginTop: -1 + "%" }}>
                                <tr><td style={{ width: 30 + "%" }}><b>1. Feed up</b></td><td style={{ width: 30 + "%" }}><b>2. Feedback</b></td><td style={{ width: 30 + "%" }}><b>3. Feed forward</b></td></tr>
                                <tr><td><i>Where am I going?</i></td><td><i>How am I going?</i></td><td><i>Where to next?</i></td></tr>
                                <tr>
                                    <td><ul>
                                        <li>Deutlich machen, was die Anforderungen einer Aufgabe waren (Zielformulierung)</li>
                                        <li>Deutlich machen, was <i>success criteria</i> sind</li>
                                    </ul></td>
                                    <td><ul>
                                        <li>Aufzeigen, wie erfolgreich die Schreibenden bisher waren (welche Anforderungen sind bereits erfüllt?)</li>
                                        <li>Aufzeigen und nicht bewerten</li>
                                    </ul></td>
                                    <td><ul>
                                        <li>Klären, welche Vorgehensweisen helfen, um vom aktuellen Stand näher zum definierten Ziel zu gelangen</li>
                                        <li>Hinweise auf Strategien, Prozessoptimierungen...</li>
                                    </ul></td>
                                </tr>
                            </table>
                            <p><b>Fokussierung / Anzahl der Informationen</b></p>
                            <ul>
                                <li>Die Informationsmenge ist relevant: 'Schwächste' Lerndende kann ein umfangreiches Feedback überfordern</li>
                                <li>Dennoch sollte ein Feedback nicht zu 'knapp' sein: 'Stärkere' Lernende profitieren von einem <i>high-information feedback</i></li>
                                <li>Feedback wird v.a. dann wirksam, wenn Lernende sich 'sehr sicher' waren, alles korrekt zu haben (<i>high-confidence feedback</i>)</li>
                            </ul>
                            <p><b>Valenz der Informationen: </b>positives oder negatives Feedback</p>
                        </div>
                    </div>
                </div></div>
                <div className={GridRow1Cell2}><div className={connectorHorizontalCell}><div className={connectorHorizontal}></div></div></div>
                <div className={GridRow1Cell3}><div className={PhilippContentBox} ><div className={PhilippContentBox2_Label} onClick={openBox2}><div className={PhilippLabelTitel}>Funktion des Feedbacks</div></div>
                    <div className={PhilippContentBox2_Content} onClick={closeBox2}>
                        <div className={PhilippBoxContentTitel}>Funktion des Feedbacks:</div>
                        <div className={PhilippBoxContentContent}>
                            <ul><li><b>Ein Feedback kann unterschiedliche Funktionen erfüllen, wobei Form und Funktion eng zusammenhängen:</b></li></ul>
                            <table style={{ marginLeft: 5 + "%" }}>
                                <tr><td>{"a)"}</td><td><b>Kognition:</b></td><td>Verbesserung der Prozesse und Produkte der Lernenden</td></tr>
                                <tr><td>{"b)"}</td><td><b>Motivation:</b></td><td>Steigerung der Motivation bzw. Emotion der Lernenden</td></tr>
                                <tr><td>{"c)"}</td><td><b>Selbstregulation:</b></td><td>Optimierung der Selbststeuerung der Lernenden</td></tr>
                            </table>
                            <ul>
                                <li>Empirisch zeigt sich, dass ein Feedback, das auf die Motivation abzielt, den geringsten langfristigen Effekt bringt!</li>
                                <li style={{ marginTop: 2 + "%" }}>Am stärksten wirkt Feedback, wenn es auf die Selbstregulation abzielt.</li>
                            </ul>
                        </div>
                    </div>
                </div>

                </div>
            </div>
            <div className={GridRow2}>
                <div className={GridRow2Cell1}><div className={connectorVerticalCell}><div className={connectorVertical}></div></div></div>
                <div className={GridRow2Cell2}></div>
                <div className={GridRow2Cell3}><div className={connectorVerticalCell}><div className={connectorVertical}></div></div></div>
            </div>
            <div className={GridRow3}>
                <div className={GridRow3Cell1}><div className={PhilippContentBox}><div className={PhilippContentBox3_Label} onClick={openBox3}><div className={PhilippLabelTitel}>Kontext des Feedbacks</div></div>
                    <div className={PhilippContentBox3_Content} onClick={closeBox3}>
                        <div className={PhilippBoxContentTitel}>Kontext des Feedbacks:</div>
                        <div className={PhilippBoxContentContent}>
                            <b>Feedback findet stets eingebunden in einen Kontext statt. Eine Reihe von Faktoren sind zu berücksichtigen:</b>
                            <ul>
                                <li>Das Klassenklima</li>
                                <li>Die Lerngruppe</li>
                                <li>Die Situation (Prüfungssituation vs. Lernsituation)</li>
                                <li>Das Fach</li>
                                <li>Der institutionelle Kontext (Feedback in der Schule vs. Feedback an der Hochschule)</li>
                            </ul>
                        </div>
                    </div>
                </div></div>
                <div className={GridRow3Cell2}><div className={connectorHorizontalCell}><div className={connectorHorizontal}></div></div></div>
                <div className={GridRow3Cell3}><div className={PhilippContentBox} ><div className={PhilippContentBox4_Label} onClick={openBox4}><div className={PhilippLabelTitel}>Quelle des Feedbacks</div></div>
                    <div className={PhilippContentBox4_Content} onClick={closeBox4}>
                        <div className={PhilippBoxContentTitel}>Quelle des Feedbacks:</div>
                        <div className={PhilippBoxContentContent}>
                            <p><b>Es sind </b>(laut Philipp 2023, S. 11) <b>eine Reihe unterschiedlicher Quellen für Feedback denkbar:</b></p>
                            <p><i>personell/sozial:</i></p>
                            <ul style={{ marginTop: -1 + "%" }}>
                                <li>Lehrperson</li>
                                <li>Peers / Mitlernende</li>
                                <li>eigene Person (<i>self</i>)</li>
                            </ul>
                            <p><i>andere:</i></p>
                            <ul style={{ marginTop: -1 + "%" }}>
                                <li>Aufgabe</li>
                                <li>Computer / <b>KI-Anwendungen</b></li>
                            </ul>
                        </div>
                    </div>
                </div></div>
            </div>
        </div>
    );
}
export default Philipp2023;
