import '../styles/UserErgebnis.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';

function UserErgebnis(props){
    
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [Stimulus, setStimulu] = useState(props.Stimulus);
    const [UserCode, setUserCode] = useState(props.UserCode);
    const [Kurs, setKurs] = useState(props.Kurs);
    const [Datum, setDatum] = useState(props.Datum);
    const Api = "https://www.schreibenmitki.de:8443/";
    const [MasterSlave, setMasterSlave] = useState(props.MasterSlave);
    const [UserErgebnisWrapper, setUserErgebnisWrapper] = useState('UserErgebnisWrapper')
    const [UserErgebnisTitel, setUserErgebnisTitel] = useState('UserErgebnisTitel');
    const [UserErgebnisKommentareArea,setUserErgebnisKommentareArea] = useState('UserErgebnisKommentareArea');
    const [UserErgebnisKommentareAreaTitel, setUserErgebnisKommentareAreaTitel] = useState('UserErgebnisKommentareAreaTitel');
    const [UserErgebnisKommentareBlock, setUserErgebnisKommentareBlock] = useState('UserErgebnisKommentareBlock');
    const [UserErgebnisKommentareBlockTitel, setUserErgebnisKommentareBlockTitel] = useState('UserErgebnisKommentareBlockTitel');
    const [UserErgebnisKommentareBlockText, setUserErgebnisKommentareBlockText] = useState('UserErgebnisKommentareBlockText');
    const [loaded, setLoaded] = useState(false);
    const [UserKommentar1_Data, setUserKommentar1_Data] = useState("");
    const [UserKommentar2_Data, setUserKommentar2_Data] = useState("");
    const [UserKommentar3_Data, setUserKommentar3_Data] = useState("");
    const [UserKommentar4_Data, setUserKommentar4_Data] = useState("");
    const [UserErgebnisFeedbackArea, setUserErgebnisFeedbackArea] = useState('UserErgebnisFeedbackArea');
    const [UserErgebnisFeedbackText, setUserErgebnisFeedbackText] = useState('UserErgebnisFeedbackText');
    const [UserErgebnisFeedback_Data, setUserErgebnisFeedback_Data] = useState('')

    useEffect(()=>{
        if(props.triggerLoad==true)
            {
                LoadData();
            }
       
    },[props.triggerLoad])

    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
            
        }

        if(windowSize.current[0] < 900)
            {
                
            }

        if(windowSize.current[0] < 600)
        {
            setUserErgebnisWrapper('UserErgebnisWrapper_Mobile');
            setUserErgebnisTitel('UserErgebnisTitel_Mobile');
            setUserErgebnisKommentareArea('UserErgebnisKommentareArea_Mobile');
            setUserErgebnisKommentareAreaTitel('UserErgebnisKommentareAreaTitel_Mobile');
            setUserErgebnisKommentareBlock('UserErgebnisKommentareBlock_Mobile');
            setUserErgebnisKommentareBlockTitel('UserErgebnisKommentareBlockTitel_Mobile');
            setUserErgebnisKommentareBlockText('UserErgebnisKommentareBlockText_Mobile');
            setUserErgebnisFeedbackArea('UserErgebnisFeedbackArea_Mobile');
            setUserErgebnisFeedbackText('UserErgebnisFeedbackText_Mobile');
        }

    }, windowSize.current[0]);

    const LoadData = () =>{
        if(loaded == false)
            {
                setLoaded(true);
                if(MasterSlave == "Master")
                    {
                        Axios.post(Api+"ReturnKommentare", {UserCode: props.UserCode, Kurs: Kurs, Stimulus:Stimulus}).then((result) =>{
                        setUserKommentar1_Data(result.data.UserKommentar1);
                        setUserKommentar2_Data(result.data.UserKommentar2);
                        setUserKommentar3_Data(result.data.UserKommentar3);
                        setUserKommentar4_Data(result.data.UserKommentar4);
                        })

                        setTimeout(()=>{
                            Axios.post(Api+"ReturnFeedback", {UserCode: props.UserCode, Kurs: Kurs, Stimulus:Stimulus}).then((result2) =>{
                                setUserErgebnisFeedback_Data(result2.data.UserFeedback);
                            })

                        },3000)

                    }
                if(MasterSlave == "Slave")
                {
                    setTimeout(() => {
                            Axios.post(Api+"ReturnKommentare", {UserCode: props.UserCode, Kurs: Kurs, Stimulus:Stimulus}).then((result) =>{
                            setUserKommentar1_Data(result.data.UserKommentar1);
                            setUserKommentar2_Data(result.data.UserKommentar2);
                            setUserKommentar3_Data(result.data.UserKommentar3);
                            setUserKommentar4_Data(result.data.UserKommentar4);
                            })

                            setTimeout(()=>{
                                Axios.post(Api+"ReturnFeedback", {UserCode: props.UserCode, Kurs: Kurs, Stimulus:Stimulus}).then((result2) =>{
                                    setUserErgebnisFeedback_Data(result2.data.UserFeedback);
                                })
    
                            },3000)

                    }, 6000)
                }
            }
        
    }

    return(
        <div className={UserErgebnisWrapper}>
            <div className={UserErgebnisTitel}>Ihr Feedback:</div>
            <div className={UserErgebnisKommentareArea}>
                <div className={UserErgebnisKommentareAreaTitel}>Kommentare:</div>
                <div className={UserErgebnisKommentareBlock}>
                    <div className={UserErgebnisKommentareBlockTitel}>Ausdruckskompetenz:</div>
                    <div className={UserErgebnisKommentareBlockText}>{UserKommentar1_Data}</div>
                </div>
                <div className={UserErgebnisKommentareBlock}>
                    <div className={UserErgebnisKommentareBlockTitel}>Kontextualisierungskompetenz:</div>
                    <div className={UserErgebnisKommentareBlockText}>{UserKommentar2_Data}</div>
                </div>
                <div className={UserErgebnisKommentareBlock}>
                    <div className={UserErgebnisKommentareBlockTitel}>Antizipationskompetenz:</div>
                    <div className={UserErgebnisKommentareBlockText}>{UserKommentar3_Data}</div>
                </div>
                <div className={UserErgebnisKommentareBlock}>
                    <div className={UserErgebnisKommentareBlockTitel}>Textgestaltungskompetenz:</div>
                    <div className={UserErgebnisKommentareBlockText}>{UserKommentar4_Data}</div>
                </div>
            </div>
            <div className={UserErgebnisFeedbackArea}>
                <div className={UserErgebnisKommentareAreaTitel}>Feedbacktext:</div>
                <div className={UserErgebnisFeedbackText}>{UserErgebnisFeedback_Data}</div>
            </div>
        </div>
    )
}
export default UserErgebnis;