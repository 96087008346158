import '../styles/BaurmannPohl.css';
import { useEffect, useRef, useState } from 'react';

function BaurmannPohl1()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [BaurmannPohl_Wrapper, setBaurmannPohl_Wrapper] = useState('BaurmannPohl_Wrapper');
    const [BaurmannPohlModelArea, setBaurmannPohlModelArea] = useState('BaurmannPohlModelArea');
    const [BaurmannPohlExplainArea, setBaurmannPohlExplainArea] = useState('BaurmannPohlExplainArea');
    const [BaurmannPohlFieldSchreibkompetenz, setBaurmannPohlFieldSchreibkompetenz] = useState('BaurmannPohlFieldSchreibkompetenz');
    const [BaurmannPohlLabelSchreibkompetenz, setBaurmannPohlLabelSchreibkompetenz] = useState('BaurmannPohlLabelSchreibkompetenz');
    const [BaurmannPohlTextSchreibkompetenz, setBaurmannPohlTextSchreibkompetenz] = useState('Schreibkompetenz');
    const [GeradeSenkrecht, setGeradeSenkrecht] = useState('BaurmannPohl_GeradeSenkrecht');
    const [GeradeWaagerecht, setGeradeWaagerecht] = useState('BaurmannPohl_GeradeWaagerecht');
    const [Kurve1, setKurve1] = useState('BaurmannPohl_Kurve1');
    const [Kurve1_1, setKurve1_1] = useState('BaurmannPohl_Kurve1_1');
    const [KurveLGegen, setKurveLGegen] = useState('BaurmannPohl_KurveLGegen');
    const [KurveLTopGegen, setKurveLTopGegen] = useState('BaurmannPohl_KurveLTopGegen');
    const [KurveLTopGegen2, setKurveLTopGegen2] = useState('BaurmannPohl_KurveLTopGegen2');
    const [KurveLBottomGegen, setKurveLBottomGegen] = useState('BaurmannPohl_KurveLBottomGegen');
    const [KurveLBottomGegen2, setKurveLBottomGegen2] = useState('BaurmannPohl_KurveLBottomGegen2');
    const [KurveRGegen, setKurveRGegen] = useState('BaurmannPohl_KurveRGegen');
    const [KurveRGegen2, setKurveRGegen2] = useState('BaurmannPohl_KurveRGegen2');
    const [Kurve2, setKurve2] = useState('BaurmannPohl_Kurve2');
    const [Kurve2_2, setKurve2_2] = useState('BaurmannPohl_Kurve2_2');
    const [Kurve3, setKurve3] = useState('BaurmannPohl_Kurve3');
    const [Kurve4, setKurve4] = useState('BaurmannPohl_Kurve4');
    const [BaurmannPohlKomponent1, setBaurmannPohlKomponent1] = useState('BaurmannPohlKomponent');
    const [BaurmannPohlKomponent2, setBaurmannPohlKomponent2] = useState('BaurmannPohlKomponent');
    const [BaurmannPohlKomponent3, setBaurmannPohlKomponent3] = useState('BaurmannPohlKomponent');
    const [BaurmannPohlKomponent4, setBaurmannPohlKomponent4] = useState('BaurmannPohlKomponent');
    const [BaurmannPohlKomponentTitel, setBaurmannPohlKomponentTitel] = useState('BaurmannPohlKomponentTitel');
    const [KTitel4, setKTitel4] = useState('Textgestaltungskompetenz');
    const [KTitel3, setKTitel3] = useState('Antizipationskompetenz');
    const [KTitel2, setKTitel2] = useState('Kontextualisierungskompetenz');
    const [KTitel1, setKTitel1] = useState('Ausdruckskompetenz');
    const [BaurmannPohlKomponentKurztext, setBaurmannPohlKomponentKurztext] = useState('BaurmannPohlKomponentKurztext');
    const [BaurmannPohlExplainField, setBaurmannPohlExplainField] = useState('BaurmannPohlExplainField_gone');
    const [BauermannPohlExplainTitel, setBauermannPohlExplainTitel] = useState('BauermannPohlExplainTitel');
    const [BP2009_ExplainTitelText, setBP2009_ExplainTitelText] = useState('Titel');
    const [BaurmannPohlKomponentText, setBaurmannPohlKomponentText] = useState('BaurmannPohlKomponentText')
    const [BaurmannPohlKomponent_Content, setBaurmannPohlKomponent_Content] = useState('');

    
    useEffect(()=>{

        if(windowSize.current[0] > 2000)
        {
            setBaurmannPohlKomponentKurztext('BaurmannPohlKomponentKurztext_4K');
            setBaurmannPohlKomponentTitel('BaurmannPohlKomponentTitel_4K');
            setBauermannPohlExplainTitel('BauermannPohlExplainTitel_4K');
            setBaurmannPohlKomponentText('BaurmannPohlKomponentText_4K');
            setBaurmannPohlLabelSchreibkompetenz('BaurmannPohlLabelSchreibkompetenz_4K');      
        }

        if(windowSize.current[0] < 1600)
        {
            setBaurmannPohlKomponentKurztext('BaurmannPohlKomponentKurztext_Medium');
            setBaurmannPohlKomponentTitel('BaurmannPohlKomponentTitel_Medium');
        }

        if(windowSize.current[0] < 900)
            {
                
            }

        if(windowSize.current[0] < 600)
        {
            setBaurmannPohl_Wrapper('BaurmannPohl_Wrapper_Mobile');
            setBaurmannPohlModelArea('BaurmannPohlModelArea_Mobile');
            setBaurmannPohlExplainArea('BaurmannPohlExplainArea_Mobile');
            setBaurmannPohlLabelSchreibkompetenz('BaurmannPohlLabelSchreibkompetenz_Mobile');
            setBaurmannPohlTextSchreibkompetenz('Schreib-kompetenz');
            setGeradeSenkrecht('BaurmannPohl_GeradeSenkrecht_Mobile');
            setGeradeWaagerecht('BaurmannPohl_GeradeWaagerecht_Mobile');
            setKurve1('BaurmannPohl_Kurve1_Mobile');
            setKurve1_1('BaurmannPohl_Kurve1_1_Mobile');
            setKurveLGegen('BaurmannPohl_KurveLGegen_Mobile');
            setKurveRGegen('BaurmannPohl_KurveRGegen_Mobile');
            setKurveRGegen2('BaurmannPohl_KurveRGegen2_Mobile');
            setKurveLTopGegen('BaurmannPohl_KurveLTopGegen_Mobile');
            setKurveLTopGegen2('BaurmannPohl_KurveLTopGegen2_Mobile');
            setKurve2('BaurmannPohl_Kurve2_Mobile');
            setKurve2_2('BaurmannPohl_Kurve2_2_Mobile');
            setKurveLBottomGegen('BaurmannPohl_KurveLBottomGegen_Mobile');
            setKurveLBottomGegen2('BaurmannPohl_KurveLBottomGegen2_Mobile');
            setKurve3('BaurmannPohl_Kurve3_Mobile');
            setKurve4('BaurmannPohl_Kurve4_Mobile');
            setBaurmannPohlKomponent1('BaurmannPohlKomponent_Mobile');
            setBaurmannPohlKomponent2('BaurmannPohlKomponent_Mobile');
            setBaurmannPohlKomponent3('BaurmannPohlKomponent_Mobile');
            setBaurmannPohlKomponent4('BaurmannPohlKomponent_Mobile');
            setBaurmannPohlKomponentTitel('BaurmannPohlKomponentTitel_Mobile');
            setKTitel4('Textgestaltungs-kompetenz');
            setKTitel3('Antizipations-kompetenz');
            setKTitel2('Kontextualisierungs-kompetenz');
            setKTitel1('Ausdrucks-kompetenz');
            setBaurmannPohlKomponentKurztext('BaurmannPohlKomponentKurztext_Mobile');
            setBauermannPohlExplainTitel('BauermannPohlExplainTitel_Mobile');
            setBaurmannPohlExplainField('BaurmannPohlExplainField_Mobile');
            setBaurmannPohlKomponentText('BaurmannPohlKomponentText_Mobile')            
        }


    }, windowSize.current[0]);



    const ExplainAusdruckskompetenz = () =>{
        setBP2009_ExplainTitelText('Ausdruckskompetenz');
        setBaurmannPohlKomponent1('BaurmannPohlKomponent_active');
        setBaurmannPohlKomponent2('BaurmannPohlKomponent');
        setBaurmannPohlKomponent3('BaurmannPohlKomponent');
        setBaurmannPohlKomponent4('BaurmannPohlKomponent');
        setBaurmannPohlExplainField('BaurmannPohlExplainField');
        if(windowSize.current[0] < 600)
            {
                setBaurmannPohlExplainField('BaurmannPohlExplainField_Mobile');
                setBaurmannPohlKomponent1('BaurmannPohlKomponent_Mobile_active');
                setBaurmannPohlKomponent2('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent3('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent4('BaurmannPohlKomponent_Mobile');
            }
        setBaurmannPohlKomponent_Content(<>
            <ul>
                <li>Wegfall der analogen Kommunikationsformen, es kann nicht länger gezeigt oder vorgemacht werden</li>
                <li>Fehlende kommunikative Spontanität und Direktheit muss überwunden werden</li>
                <li>Notwendigkeit der Affektkontrolle</li>
            </ul>
            <p><b>Herausforderung für junge Schreibenlernende (Grundschule, Sek I): </b>Nicht länger Aspekte der mündlichen Kommunikation in das Schreiben übertragen. Weniger subjektiv und emotional zugreifen.</p>
            <p><b>Herausforderung für erfahrene Schreibenlernende (Sek II, Uni): </b>Textuelle Ausdrucksfunktion in 'Extremformen der Schriftlichkeit' (z.B. Hausarbeit) bedienen.</p>
            <p><b style={{textDecoration:"underline"}}>Entwicklung:</b></p>
            <p>Von einem <b>subjektiv involviertem </b> Schreiben zu einem <b>adressat:innenorientierten emotional involvierendem </b> Schreiben </p>
            </>
        )
    }

    const ExplainKontextualisierungskompetenz = () =>{
        setBP2009_ExplainTitelText('Kontextualisierungskompetenz');
        setBaurmannPohlKomponent1('BaurmannPohlKomponent');
        setBaurmannPohlKomponent2('BaurmannPohlKomponent_active');
        setBaurmannPohlKomponent3('BaurmannPohlKomponent');
        setBaurmannPohlKomponent4('BaurmannPohlKomponent');
        setBaurmannPohlExplainField('BaurmannPohlExplainField');
        if(windowSize.current[0] < 600)
            {
                setBaurmannPohlExplainField('BaurmannPohlExplainField_Mobile');
                setBaurmannPohlKomponent1('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent2('BaurmannPohlKomponent_Mobile_active');
                setBaurmannPohlKomponent3('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent4('BaurmannPohlKomponent_Mobile');
            }
        setBaurmannPohlKomponent_Content(<>
            <ul>
                <li>Herstellen der informationellen Selbstversorgtheit des Textes</li>
                <li>Textinterne Kohärenzbildung und herstellen der „Verstehbarkeit“ </li>
            </ul>
            <p><b>Herausforderung für junge Schreibenlernende (Grundschule): </b>Abschätzen, was in der Rezeptionssituation (beim Lesen) nicht vorhanden ist und in den Text gebracht werden muss.</p>
            <p><b>Herausforderung für fortgeschrittene Schreibenlernende (Sek I): </b>Implizit Mitgedachtes explizit machen</p>
            <p><b>Herausforderung für erfahrene Schreibenlernende (Sek II, Uni): </b>Wissensstand der (abstrakten) Rezipient:innen antizipieren.</p>
            <p><b style={{textDecoration:"underline"}}>Entwicklung:</b></p>
            <p>Von <b>kontextuell eingebettetem </b> Schreiben zu <b>kontexterzeugendem Schreiben</b> Schreiben </p>
            </>
        )
    }

    const ExplainAntizipationskompetenz = () =>{
        setBP2009_ExplainTitelText('Antizipationskompetenz');
        setBaurmannPohlKomponent1('BaurmannPohlKomponent');
        setBaurmannPohlKomponent2('BaurmannPohlKomponent');
        setBaurmannPohlKomponent3('BaurmannPohlKomponent_active');
        setBaurmannPohlKomponent4('BaurmannPohlKomponent');
        setBaurmannPohlExplainField('BaurmannPohlExplainField');
        if(windowSize.current[0] < 600)
            {
                setBaurmannPohlExplainField('BaurmannPohlExplainField_Mobile');
                setBaurmannPohlKomponent1('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent2('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent3('BaurmannPohlKomponent_Mobile_active');
                setBaurmannPohlKomponent4('BaurmannPohlKomponent_Mobile');
            }
        setBaurmannPohlKomponent_Content(<>
            <ul>
                <li>Mehr als 'Leser:innenadressierung': Texte weisen Meta-Kommunikation auf und wollen bei 'generalisierten Anderen' einen Effekt auslösen</li>
            </ul>
            <p><b>Herausforderung für junge Schreibenlernende (Grundschule): </b>Erkennen, dass Texte überhaupt Leser:innen haben.</p>
            <p><b>Herausforderung für erfahrene Schreibenlernende (Sek I bis Uni): </b>Nicht länger für konkrete Adressat:innen, sondern zunehmend 'abstrakte' Adressat:innen schreiben (z.B. beim wissenschaftlichen Schreiben)</p>
            <p><b style={{textDecoration:"underline"}}>Entwicklung:</b></p>
            <p>Von einer <b>egozentrischen Textwahrnehmung </b> zu einer <b>Antizipation eines generalisierten Lesenden</b> beim Schreiben </p>
            </>
        )
    }

    const ExplainTextgestaltungskompetenz = () =>{
        setBP2009_ExplainTitelText('Textgestaltungskompetenz');
        setBaurmannPohlKomponent1('BaurmannPohlKomponent');
        setBaurmannPohlKomponent2('BaurmannPohlKomponent');
        setBaurmannPohlKomponent3('BaurmannPohlKomponent');
        setBaurmannPohlKomponent4('BaurmannPohlKomponent_active');
        setBaurmannPohlExplainField('BaurmannPohlExplainField');
        if(windowSize.current[0] < 600)
            {
                setBaurmannPohlExplainField('BaurmannPohlExplainField_Mobile');
                setBaurmannPohlKomponent1('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent2('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent3('BaurmannPohlKomponent_Mobile');
                setBaurmannPohlKomponent4('BaurmannPohlKomponent_Mobile_active');
            }
        setBaurmannPohlKomponent_Content(<>
            <ul>
                <li>Der Text wird nicht länger von mehreren, sondern nur einer Person gestaltet</li>
                <li>Herstellung eines homogenen Monologs</li>
            </ul>
            <p><b>Herausforderung für junge Schreibenlernende (Grundschule): </b>Text verfassen, der nicht länger Vorwissen bei Lesenden voraussetzt (Reaktion oft: 'und dann... und dann...')</p>
            <p><b>Herausforderung für fortgeschrittene Schreibenlernende (Sek I): </b>Konnektoren (z.B. 'und dann', 'auf der einen Seite...'), Koordination und Subordination nicht länger mechanisch, sondern funktional einsetzen</p>
            <p><b>Herausforderung für erfahrene Schreibenlernende (Sek II, Uni): </b>offensichtliche Sachverhalte nicht anhaltend sprachlich explizieren.</p>
            <p><b style={{textDecoration:"underline"}}>Entwicklung:</b></p>
            <p>Von <b>assoziativ-reihender Textgestaltung  </b> zu <b>schema- oder textsortengeleiteter Textordnung</b> beim Schreiben </p>
            </>
        )
    }


return(
    <div className={BaurmannPohl_Wrapper}>
        <div className={BaurmannPohlModelArea}>
            <div className='BaurmannPohlRow1'>
                <div className='BaurmannPohlCell1'><div className='CellSplit1'><div className='CellSplit2'></div><div className='CellSplit2'><div className={KurveLTopGegen}></div></div></div><div className='CellSplit1'><div className='CellSplit2'><div className={KurveLGegen}></div></div><div className='CellSplit2'><div className={Kurve1}></div></div></div></div>
                <div className='BaurmannPohlCell2'><div className={GeradeWaagerecht}></div></div>
                <div className='BaurmannPohlCell3'><div className={BaurmannPohlKomponent1} onClick={ExplainAusdruckskompetenz}><div className={BaurmannPohlKomponentTitel}>{KTitel1}</div><div className={BaurmannPohlKomponentKurztext}>Ausgleich fehlender Ausdrucksqualitäten wie Mimik/Gestik durch lexikalische und syntaktische Mittel</div></div></div>
            </div>
            <div className='BaurmannPohlRow2'>
                <div className='BaurmannPohlCell1'><div className={GeradeSenkrecht}></div></div>
                <div className='BaurmannPohlCell2'></div>
                <div className='BaurmannPohlCell3'></div>
            </div>
            <div className='BaurmannPohlRow3'>
                <div className='BaurmannPohlCell1'><div className={GeradeSenkrecht}></div></div>
                <div className='BaurmannPohlCell2'><div className='CellSplit1'><div className='CellSplit2'></div><div className='CellSplit2'></div></div><div className='CellSplit1'><div className='CellSplit2'><div className={Kurve1_1}></div></div><div className='CellSplit2'><div className={KurveLBottomGegen2}></div></div></div></div>
                <div className='BaurmannPohlCell3'><div className={BaurmannPohlKomponent2} onClick={ExplainKontextualisierungskompetenz}><div className={BaurmannPohlKomponentTitel}>{KTitel2}</div><div className={BaurmannPohlKomponentKurztext}>Aufbau einer aus sich heraus verständlichen Textwelt
</div></div></div>
            </div>
            <div className='BaurmannPohlRow4'>
                <div className='BaurmannPohlCell1'><div className={BaurmannPohlFieldSchreibkompetenz}><div className={BaurmannPohlLabelSchreibkompetenz}>{BaurmannPohlTextSchreibkompetenz}</div></div></div>
                <div className='BaurmannPohlCell2'><div className='CellSplit1'><div className='CellSplit2'><div className={KurveRGegen2}></div></div><div className='CellSplit2'></div></div><div className='CellSplit1'><div className='CellSplit2'><div className={KurveRGegen2}></div></div><div className='CellSplit2'></div></div></div>
                <div className='BaurmannPohlCell3'></div>
            </div>
            <div className='BaurmannPohlRow5'>
                <div className='BaurmannPohlCell1'><div className={GeradeSenkrecht}></div></div>
                <div className='BaurmannPohlCell2'><div className='CellSplit1'><div className='CellSplit2'><div className={Kurve2_2}></div></div><div className='CellSplit2'><div className={KurveLTopGegen2}></div></div></div><div className='CellSplit1'><div className='CellSplit2'></div><div className='CellSplit2'></div></div></div>
                <div className='BaurmannPohlCell3'><div className={BaurmannPohlKomponent3} onClick={ExplainAntizipationskompetenz}><div className={BaurmannPohlKomponentTitel}>{KTitel3}</div><div className={BaurmannPohlKomponentKurztext}>Einschätzungen und Vorwegnahme möglicher Lesendenreaktionen</div></div></div>
            </div>
            <div className='BaurmannPohlRow6'>
                <div className='BaurmannPohlCell1'><div className={GeradeSenkrecht}></div></div>
                <div className='BaurmannPohlCell2'></div>
                <div className='BaurmannPohlCell3'></div>
            </div>
            <div className='BaurmannPohlRow7'>
                <div className='BaurmannPohlCell1'><div className='CellSplit1'><div className='CellSplit2'><div className={KurveLGegen}></div></div><div className='CellSplit2'><div className={Kurve2}></div></div></div><div className='CellSplit1'><div className='CellSplit2'></div><div className='CellSplit2'><div className={KurveLBottomGegen}></div></div></div></div>
                <div className='BaurmannPohlCell2'><div className={GeradeWaagerecht}></div></div>
                <div className='BaurmannPohlCell3'><div className={BaurmannPohlKomponent4} onClick={ExplainTextgestaltungskompetenz}><div className={BaurmannPohlKomponentTitel}>{KTitel4}</div><div className={BaurmannPohlKomponentKurztext}>Aufbau und Strukturierung des Textes in einer nachvollziehbaren Ordnung; u. U. auf der Basis von Textsortenwissen
</div></div></div>
            </div>
        </div>
        <div className={BaurmannPohlExplainArea}>
            <div className={BaurmannPohlExplainField}>
                <div className={BauermannPohlExplainTitel}>{BP2009_ExplainTitelText}</div>
                <div className={BaurmannPohlKomponentText}>{BaurmannPohlKomponent_Content}</div>


            </div>
        </div>
    </div>
)
}
export default BaurmannPohl1;