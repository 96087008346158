import '../styles/AdminPage.css'
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie'; 
import { Link } from 'react-router-dom';

function AdminPage()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [AdminPageButton, setAdminPageButton] = useState('AdminPageButton');
    const [AdminPage_Field, setAdminPage_Field] = useState('AdminPage_Field');
    const Api = "https://www.schreibenmitki.de:8443/";
    const [Kurs, setKurs] = useState(8);
    const [AdminPageNeuerKurs, setAdminPageNeuerKurs] = useState('AdminPageNeuerKurs');
    const [UserCode, setUserCode] = useState(Cookies.get("SchreibenmitKI_UserCode"));
    const [newKurs, setnewKurs] = useState('');
    const [AdminPageButton2, setAdminPageButton2] = useState('AdminPageButton');
    const [Rolle, setRolle] = useState(Cookies.get("SchreibenmitKI_Rolle"));
    const [ChatGPTUserCode, setChatGPTUserCode] = useState("");
    const [ChatGPTLink, setChatGPTLink] = useState('');
    const [ChatGPTButton, setChatGPTButton] = useState('AdminPageButton_gone')


    useEffect(()=>{
        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
           
        }

        if(windowSize.current[0] < 900)
        {
               
        }

        if(windowSize.current[0] < 600)
        {
            setAdminPageButton('AdminPageButton_Mobile');
            setAdminPageButton2('AdminPageButton_Mobile');
            setAdminPage_Field('AdminPage_Field_Mobile');
            setAdminPageNeuerKurs('AdminPageNeuerKurs_Mobile');
        }

    }, windowSize.current[0]);

    const upDateStatus = (update) =>{
        if(Rolle == "Admin")
        {
        Axios.post(Api+"updateFortbildungsStatus", {KursId:Kurs, NeuerStatus: update}).catch(err => err)
        }
    }

    const startM3CK = () =>{
        upDateStatus("M3CK_Start");
    }

    const endM3CK = () =>{
        upDateStatus("M3CK_End");
    }

    const M3pause = () =>{
        upDateStatus("M3_Pause");
    }

    const startM3PK = () =>{
        upDateStatus("M3PK_Start");
    }

    const startM3TK = () =>{
        upDateStatus("M3TK_Start");
    }

    const startM3HW = () =>{
        upDateStatus("M3HW_Start");
    }

    const startM3HW_C = () =>{
        upDateStatus("M3HW_End");
    }

    const startM3TK2 = () =>{
        upDateStatus("M3TK2_Start");
    }

    const checkFortbildung = () =>{
        Axios.post(Api+"checkFortbildung", {Fortbildung: Kurs}).then((response) =>{
            if(response.data.Fortbildung == true)
            {
                setAdminPage_Field('AdminPage_Field_success');
                if(windowSize.current[0] < 600)
                {
                    setAdminPage_Field('AdminPage_Field_success_Mobile');
                }
            }
        }).catch(err => err)
    }

    const starteNeuenKurs = () =>{
        if(Rolle == "Admin")
        {
            setAdminPageButton2('AdminPageButton_gone')
            var nKurs = Math.round(Math.random()*1000);
            setnewKurs(nKurs);
            let today = new Date();
            let date=today.getDate() + "."+ parseInt(today.getMonth()+1) +"."+today.getFullYear();

            setTimeout(() =>{
                Axios.post(Api+'createNewFortbildung', {AdminUser: UserCode, Datum: date, KursId: nKurs})
            },[1000]).catch(err => err)
        }
        
    }

    const checkforChatGPT = () =>{
        if(Rolle == "Admin")
        {
        if(ChatGPTUserCode != "")
        {
            Axios.post(Api+'checkforChatGPT', {UserCode: ChatGPTUserCode}).then((cresponse) =>{
                if(cresponse.data.Link)
                {
                    setChatGPTLink(cresponse.data.Link);
                    setChatGPTButton('AdminPageButton');
                }
            })
        }
        }
    }
    

    return(
    <div className='AdminPageWrapper'>
        <div className='AdminPageBody'>
        <div className='AdminPageKursSelector'>
            <div className='AdminPage_Select_exist'><input type="text" placeholder='Kurs-Id' className={AdminPage_Field} onChange={e => {setKurs(e.target.value)}}></input><div className={AdminPageButton} onClick={checkFortbildung}>Check</div></div>
            <div className='AdminPage_Select_exist'><div className={AdminPageButton2} onClick={starteNeuenKurs}>Neue Fortbildung</div> <div className={AdminPageNeuerKurs}>{newKurs}</div></div>
        </div>
        <div className='AdminPageBox'>
            <div className='AdminPageBoxColumn'>
            <div className={AdminPageButton} onClick={startM3CK}>Start Content Knowledge</div>
        <div className={AdminPageButton} onClick={endM3CK}>End Content Knowledge</div>
        <div className={AdminPageButton} onClick={startM3PK}>Start Pedagogical Knowledge</div>
        <div className={AdminPageButton} onClick={startM3TK}>Start Technological Knowledge</div>
        <div className={AdminPageButton} onClick={startM3HW}>Start Hausaufgabe</div>
        <div className={AdminPageButton} onClick={M3pause}>Pause</div>
            </div>

            <div className='AdminPageBoxColumn'>
                <div className={AdminPageButton} onClick={startM3HW_C}>Starte Hausaufgabenkontrolle</div>
                <div className={AdminPageButton} onClick={startM3TK2}>Start Technological Knowledge 2</div>
            </div>
        
        </div>
        <div className='AdminPageKursSelector'>
            <div className='AdminPage_Select_exist'><input type="text" className={AdminPage_Field} placeholder='UserCode' onChange={(e) =>{setChatGPTUserCode(e.target.value)}}></input><div className={AdminPageButton} onClick={checkforChatGPT}>Abrufen</div></div>
            <div className='AdminPage_Select_exist'> <div className={ChatGPTButton}><Link to={ChatGPTLink} target="_blank">Link</Link></div> </div>
        </div>
        
        </div>
        
    </div>
    )
}

export default AdminPage;