import '../styles/M3PK1.css'
import '../styles/M3CK1.css'
import '../styles/M3TK1.css'
import { useEffect, useRef, useState } from 'react';
import Loader from '../../components/Loader.js';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import UserErgebnis from '../../components/UserErgebnis.js';
import SchulertextViewer from '../../components/SchulertextViewer.js';
import MullerFurstenberg from '../../components/MullerFurstenberg.js';
import Cookies from 'js-cookie'; 

function M3TK1S(){
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [UserCode, setUserCode] = useState(Cookies.get("SchreibenmitKI_UserCode"));
    const [Datum, setDatum] = useState(Cookies.get("SchreibenmitKI_Datum"));
    const [Kurs, setKurs] = useState(Cookies.get("SchreibenmitKI_WorkshopCode"));
    const Api = "https://www.schreibenmitki.de:8443/";
    const [M3CK1S_Top, setM3CK1S_Top] = useState('M3CK1S_Top');
    const [M3CK1SNavigationArea, setM3CK1SNavigationArea] = useState('M3CK1SNavigationArea');
    const [M3CK1SNavElement1, setM3CK1SNavElement1] = useState('M3CK1SNavElement');
    const [M3CK1S_Middle, setM3CK1S_Middle] = useState('M3CK1S_Middle');
    const [M3CK1SNavImage1, setM3CK1SNavImage1] = useState('NavImage_ChatGPT_active');
    const [M3CK1SNavImage2, setM3CK1SNavImage2] = useState('NavImage_OwnGPT');
    const [M3CK1SNavImage3, setM3CK1SNavImage3] = useState('NavImage_Spiegel');
    const [M3CK1SNavImage4, setM3CK1SNavImage4] = useState('NavImage_Finn');
    const [M3CK1SNavImage5, setM3CK1SNavImage5] = useState('NavImage_Mia');
    const [M3CK1SNavImage6, setM3CK1SNavImage6] = useState('NavImage_finish');
    const [M3CK1S_ContentArea1, setM3CK1S_ContentArea1] = useState('M3CK1S_Content_Area');
    const [M3CK1S_ContentArea2, setM3CK1S_ContentArea2] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea3, setM3CK1S_ContentArea3] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea4, setM3CK1S_ContentArea4] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea5, setM3CK1S_ContentArea5] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea6, setM3CK1S_ContentArea6] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1SNextButton, setM3CK1SNextButton] = useState('M3CK1S_NextButton_first');
    const [currentPage, setCurrentPage] = useState(0);
    const [SpeicherSeite, setSpeicherSeite] = useState('SpeicherSeite');
    const [SpeicherSeiteTitel, setSpeicherSeiteTitel] = useState('SpeicherSeiteTitel');
    const [SpeicherButtonArea, setSpeicherButtonArea] = useState('M3CK1S_SpeicherButtonArea');
    const [SpeicherButton, setSpeicherButton] = useState('M3CK1S_SpeicherButton');
    const [WeiterButtonArea, setWeiterButtonArea] = useState('M3CK1S_WeiterButtonArea');
    const [WeiterButton, setWeiterButton] = useState('M3CK1S_WeiterButton_gone');
    const [triggerSave, setTriggerSave] = useState(false);
    const [M3CK1SLoader, setM3CK1SLoader] = useState('M3CK1SLoader_gone');
    const [LoaderStatus, setLoaderStatus] = useState('loading');
    const [triggerLoad, setTriggerLoad] = useState(false);
    const [Schreibaufgabe1, setSchreibaufgabe1] = useState('Schreibaufgabe1');
    const [Sch1_SpiegelTextArea, setSch1_SpiegelTextArea] = useState('Sch1_SpiegelTextArea');
    const [Schi1_SpiegelTextTitel, setSchi1_SpiegelTextTitel] = useState('Schi1_SpiegelTextTitel');
    const [Schi1_SpiegelTextImage1, setSchi1_SpiegelTextImage1] = useState('Schi1_SpiegelTextImage1');
    const [Schi1_SpiegelTextArticle, setSchi1_SpiegelTextArticle] = useState('Schi1_SpiegelTextArticle');
    const [Sch1_SpiegelCommentareArea, setSch1_SpiegelCommentareArea] = useState('Sch1_SpiegelCommentareArea');
    const [Sch1_SpiegelKommentar, setSch1_SpiegelKommentar] = useState('Sch1_SpiegelKommentar');
    const [Sch1_TaskArea, setSch1_TaskArea] = useState('Sch1_TaskArea');
    const [Sch1_TaskSchreibaufgabe, setSch1_TaskSchreibaufgabe] = useState('Sch1_TaskSchreibaufgabe');
    const [SchreibproduktFeedback, setSchreibproduktFeedback] = useState('SchreibproduktFeedback');
    const [SchreibproduktFeedbackTitel, setSchreibproduktFeedbackTitel] = useState('SchreibproduktFeedbackTitel');
    const [SchreibproduktFeedbackMain, setSchreibproduktFeedbackMain] = useState('SchreibproduktFeedbackMain');
    const [SchreibproduktFeedbackMainBox, setSchreibproduktFeedbackMainBox] = useState('SchreibproduktFeedbackMainBox');
    const [AiExplainArea, setAiExplainArea] = useState('AiExplainArea');
    const [AiExplainAreaTitel, setAiExplainAreaTitel] = useState('AiExplainAreaTitel');
    const [AiExplainAreaBody, setAiExplainAreaBody] = useState('AiExplainAreaBody');
    const [ChatGPTShareBox, setChatGPTShareBox] = useState('ChatGPTShareBox');
    const [ChatGPTShareBoxTitel, setChatGPTShareBoxTitel] = useState('ChatGPTShareBoxTitel');
    const [ChatGPTLink, setChatGPTLink] = useState('');
    const [M3HW_LoaderArea, setM3HW_LoaderArea] = useState('M3HW_LoaderArea_gone');
    const [M3HW_Messenger, setM3HW_Messenger] = useState('M3HW_Messenger_gone');
    const [M3HW_MessengerText, setM3HW_MessengerText] = useState('');
    const [ChatGPTInput_send, setChatGPTInput_send] = useState('ChatGPTInput_send');

    const SpiegelArtikel = (<>
        <p><b>Keine Zeit zum Wachwerden, kaum Zeit zum Frühstücken und viel Stress. Der Schulbeginn um 8.00 Uhr am Morgen ist für viele Schülerinnen und Schüler eine echte Qual!</b></p>
        <p>Im Sommer geht es noch so gerade: Wenn man das Haus um 7.00 oder 7.30 verlässt, braucht man auf dem Weg zu Schule kein Fahrradlicht. Anders ist dieses jedoch im Winter. Hier ist es in der Frühe noch besonders kalt, nass und dunkel und das frühe Losgehen ist für viele Schüler eine Qual. Auch fällt es vielen schwer, sich schon morgens um 8.00 Uhr zu konzentrieren oder sogar eine Klassenarbeit zu schreiben.</p>
        <p>Besonders betroffen sind die Schüler, die mit dem Schulbus kommen oder einen besonders weiten Schulweg haben. Dass viele übermüdet im Unterricht sitzen, hat laut Wissenschaftlern, auch biologische Gründe: Bei allen Menschen ticken die inneren Uhren jeweils ein bisschen anders. Manche Menschen brauchen dadurch täglich mehr Schlaf als andere. </p>
        <p>Bildungspolitiker und Forscher diskutieren deshalb. Forscher sprechen sich dafür aus, dass die Schule erst um 9.00 oder sogar erst um 10.00 Uhr beginnen sollte. Ein Beispiel aus den USA hat gezeigt, "dass selbst Schüler, die morgens nur eine Viertel- oder halbe Stunde länger schlafen können, besser drauf sind", sagt der Wissenschaftler Peter Spork. </p>
        <p>Andererseits sind gerade Eltern, die jeden Tag arbeiten müssen, darauf angewiesen, dass ihre Kinder zu bestimmten Uhrzeiten in der Schule sind, damit sie selbst arbeiten gehen können. Logisch ist natürlich auch, dass wenn ein Schultag später beginnt, dieser auch erst später zu Ende ist. Ein normaler Schultag mit 6 Schulstunden wurde dann nicht um 13.30 Uhr, sondern erst um 14.30 Uhr oder sogar 15.30 Uhr enden. Schüler, die 8 Unterrichtsstunden haben, würden dann erst um 18.00 Uhr aus der Schule kommen.</p>
        <p><i>Spiegel Online. Montag: 15.09.2014</i></p>
    </>)
   
    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            setSchi1_SpiegelTextArticle('Schi1_SpiegelTextArticle_4K');
            setAiExplainAreaTitel('AiExplainAreaTitel_4K');
            setAiExplainAreaBody('AiExplainAreaBody_4K');
            
        }

        if(windowSize.current[0] < 1300)
        {
            setSch1_SpiegelTextArea('Sch1_SpiegelTextArea_Medium');
            setSchi1_SpiegelTextArticle('Schi1_SpiegelTextArticle_Medium');
            setSch1_TaskSchreibaufgabe('Sch1_TaskSchreibaufgabe_Medium');
        }

        if(windowSize.current[0] < 600)
        {
            setM3CK1SNavigationArea('M3CK1SNavigationArea_Mobile');
            setM3CK1SNavElement1('M3CK1SNavElement_Mobile');
            setM3CK1SNextButton('M3CK1S_NextButton_Mobile_first');
            setSpeicherSeite('SpeicherSeite_Mobile');
            setSpeicherSeiteTitel('SpeicherSeiteTitel_Mobile');
            setSpeicherButtonArea('M3CK1SM3CK1S_SpeicherButtonArea_Mobile');
            setSpeicherButton('M3CK1S_SpeicherButton_Mobile');
            setWeiterButtonArea('M3CK1S_WeiterButtonArea_Mobile');
            setSchreibaufgabe1('Schreibaufgabe1_Mobile');
            setSch1_SpiegelTextArea('Sch1_SpiegelTextArea_Mobile');
            setSchi1_SpiegelTextTitel('Schi1_SpiegelTextTitel_Mobile');
            setSchi1_SpiegelTextArticle('Schi1_SpiegelTextArticle_Mobile');
            setSchi1_SpiegelTextImage1('Schi1_SpiegelTextImage1_Mobile');
            setSch1_SpiegelCommentareArea('Sch1_SpiegelCommentareArea_Mobile');
            setSch1_SpiegelKommentar('Sch1_SpiegelKommentar_Mobile');
            setSch1_TaskArea('Sch1_TaskArea_Mobile');
            setSch1_TaskSchreibaufgabe('Sch1_TaskSchreibaufgabe_Mobile');
            setSchreibproduktFeedback('SchreibproduktFeedback_Mobile');
            setSchreibproduktFeedbackTitel('SchreibproduktFeedbackTitel_Mobile');
            setSchreibproduktFeedbackMain('SchreibproduktFeedbackMain_Mobile');
            setSchreibproduktFeedbackMainBox('SchreibproduktFeedbackMainBox_Mobile');
            setAiExplainArea('AiExplainArea_Mobile');
            setAiExplainAreaTitel('AiExplainAreaTitel_Mobile');
            setAiExplainAreaBody('AiExplainAreaBody_Mobile');
            setFullScreen();
        }

    }, windowSize.current[0]);

    const nextPage = () =>{
        setM3CK1SNextButton('M3CK1S_NextButton');
        if(windowSize.current[0] < 600)
            {
                setM3CK1SNextButton('M3CK1S_NextButton_Mobile');
            }

        if(currentPage==5)
            {
                    setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                    setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                    setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
                    setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
                    setM3CK1S_ContentArea5('M3CK1S_Content_Area_leftOff'); 
                    setM3CK1S_ContentArea6('M3CK1S_Content_Area');
                    setCurrentPage(4);
                    setM3CK1SNavImage1('NavImage_ChatGPT');
                    setM3CK1SNavImage2('NavImage_OwnGPT');
                    setM3CK1SNavImage3('NavImage_Spiegel');
                    setM3CK1SNavImage4('NavImage_Finn');
                    setM3CK1SNavImage5('NavImage_Mia');
                    setM3CK1SNavImage6('NavImage_finish_active');
                    setTriggerLoad(true);
            }
    

        if(currentPage==4)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
                setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
                setM3CK1S_ContentArea5('M3CK1S_Content_Area'); 
                setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
                setCurrentPage(5);
                setM3CK1SNavImage1('NavImage_ChatGPT');
                setM3CK1SNavImage2('NavImage_OwnGPT');
                setM3CK1SNavImage3('NavImage_Spiegel');
                setM3CK1SNavImage4('NavImage_Finn');
                setM3CK1SNavImage5('NavImage_Mia_active');
                setTriggerLoad(true);
            }

        if(currentPage==3)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
                setM3CK1S_ContentArea4('M3CK1S_Content_Area'); 
                setCurrentPage(4);
                setM3CK1SNavImage1('NavImage_ChatGPT');
                setM3CK1SNavImage2('NavImage_Spiegel');
                setM3CK1SNavImage3('NavImage_Lernende');
                setM3CK1SNavImage4('NavImage_Finn_active');
                setM3CK1SNavImage5('NavImage_Mia');
                setTriggerLoad(true);
            }

            if(currentPage==1)
                {
                    setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                    setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
                    setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
                    setCurrentPage(3);
                    setM3CK1SNavImage1('NavImage_ChatGPT');
                    setM3CK1SNavImage2('NavImage_OwnGPT');
                    setM3CK1SNavImage3('NavImage_Spiegel_active');
                    setM3CK1SNavImage4('NavImage_Finn');
                    setM3CK1SNavImage5('NavImage_Mia');
                    setTriggerLoad(true);
                }

        if(currentPage==0)
        {
            setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
            setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
            setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
            setCurrentPage(3);
            setM3CK1SNavImage1('NavImage_ChatGPT');
            setM3CK1SNavImage2('NavImage_OwnGPT');
            setM3CK1SNavImage3('NavImage_Spiegel_active');
            setM3CK1SNavImage4('NavImage_Finn');
            setM3CK1SNavImage5('NavImage_Mia');
            setTriggerLoad(true);
        }

        if(currentPage==99)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area');  
                setCurrentPage(2);
                setM3CK1SNavImage1('NavImage_ChatGPT');
                setM3CK1SNavImage2('NavImage_OwnGPT_active');
                setM3CK1SNavImage3('NavImage_Spiegel');
                setM3CK1SNavImage4('NavImage_Finn');
                setM3CK1SNavImage5('NavImage_Mia');
                setTriggerLoad(true);
            }
        
        if(currentPage==99)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area');  
                setCurrentPage(2);
                setM3CK1SNavImage1('NavImage_ChatGPT');
                setM3CK1SNavImage2('NavImage_Spiegel_active');
                setM3CK1SNavImage3('NavImage_Spiegel');
                setM3CK1SNavImage4('NavImage_Finn');
                setM3CK1SNavImage5('NavImage_Mia');
                setTriggerLoad(true);
            }
       
    }

       

    const selectPage1 = () =>{
        setM3CK1SNavImage1('NavImage_ChatGPT_active');
        setM3CK1SNavImage2('NavImage_OwnGPT');
        setM3CK1SNavImage3('NavImage_Spiegel');
        setM3CK1SNavImage4('NavImage_Finn');
        setM3CK1SNavImage5('NavImage_Mia');
        setM3CK1SNavImage6('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_rightOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(1);
    }

    const selectPage2 = () =>{
        setM3CK1SNavImage1('NavImage_ChatGPT');
        setM3CK1SNavImage2('NavImage_OwnGPT_active');
        setM3CK1SNavImage3('NavImage_Spiegel');
        setM3CK1SNavImage4('NavImage_Finn');
        setM3CK1SNavImage5('NavImage_Mia');
        setM3CK1SNavImage6('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(2);
    }

    const selectPage3 = () =>{
        setM3CK1SNavImage1('NavImage_ChatGPT');
        setM3CK1SNavImage2('NavImage_OwnGPT');
        setM3CK1SNavImage3('NavImage_Spiegel_active');
        setM3CK1SNavImage4('NavImage_Finn');
        setM3CK1SNavImage5('NavImage_Mia');
        setM3CK1SNavImage6('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(3);
    }

    const selectPage4 = () =>{
        setM3CK1SNavImage1('NavImage_ChatGPT');
        setM3CK1SNavImage2('NavImage_OwnGPT');
        setM3CK1SNavImage3('NavImage_Spiegel');
        setM3CK1SNavImage4('NavImage_Finn_active');
        setM3CK1SNavImage5('NavImage_Mia');
        setM3CK1SNavImage6('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_rightOff');
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff'); 
        setTriggerLoad(true);
        setCurrentPage(4);
    }

    const selectPage5 = () =>{
        setM3CK1SNavImage1('NavImage_ChatGPT');
        setM3CK1SNavImage2('NavImage_OwnGPT');
        setM3CK1SNavImage3('NavImage_Spiegel');
        setM3CK1SNavImage4('NavImage_Finn');
        setM3CK1SNavImage5('NavImage_Mia_active');
        setM3CK1SNavImage6('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area_rightOff');
        setTriggerLoad(true);
        setCurrentPage(5);
    }

    const selectPage6 = () =>{
        setM3CK1SNavImage1('NavImage_ChatGPT');
        setM3CK1SNavImage2('NavImage_OwnGPT');
        setM3CK1SNavImage3('NavImage_Spiegel');
        setM3CK1SNavImage4('NavImage_Finn');
        setM3CK1SNavImage5('NavImage_Mia');
        setM3CK1SNavImage6('NavImage_finish_active');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea4('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea5('M3CK1S_Content_Area_leftOff'); 
        setM3CK1S_ContentArea6('M3CK1S_Content_Area');
        setTriggerLoad(true);
        setCurrentPage(6);
    }

    const ErgebnisseSpeichern = () =>{
        setSpeicherButton('M3CK1S_SpeicherButton_gone');
        setM3CK1SLoader('M3CK1SLoader');
        if(windowSize.current[0] < 600)
            {
                setM3CK1SLoader('M3CK1SLoader_Mobile');
            }
        //setTriggerSave(true);                                                    //MUSS WIEDER REIN!                                       

        setTimeout(()=>{
            Axios.post(Api+'confirmM3PK1', {UserCode: UserCode, Datum:Datum}).then((result) =>{
                console.log(result.data.confirmation);
                if(result.data.confirmation == "No data!")
                {
                    setLoaderStatus('error');
                }
                if(result.data.confirmation == "Confirm!")
                {
                    setLoaderStatus('finished');
                    setWeiterButton('M3CK1S_SpeicherButton');
                    if(windowSize.current[0] < 600)
                    {
                        setWeiterButton('M3CK1S_SpeicherButton');
                    }
                    
                }
            })   
        },10000)
    }

    const setFullScreen = () =>{
        const element = document.documentElement;

        if (element.requestFullscreen) {
          element.requestFullscreen().catch(err => err);
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen().catch(err => err);
        }
      }

      const UploadChatGPTLink = () =>{
        console.log(ChatGPTLink);
        setChatGPTInput_send('ChatGPTInput_send_gone');

        Axios.post(Api+'saveChatGPT',{UserCode: UserCode, Link:ChatGPTLink});

        setTimeout(()=>{
            Axios.post(Api+'confirmChatGPT',{UserCode: UserCode}).then((response6) =>{
                if(response6.data.confirmation == "No data!")
                    {
                        setM3HW_MessengerText('Irgendwas ist schiefgegangen');  
                        setM3HW_LoaderArea('M3HW_LoaderArea');
                        setM3HW_Messenger('M3HW_Messenger');
                        setChatGPTInput_send('ChatGPTInput_send');
                    }
                    if(response6.data.confirmation == "Confirm!")
                    {
                        setM3HW_LoaderArea('M3HW_LoaderArea');
                        setM3HW_Messenger('M3HW_Messenger');
                        setM3HW_MessengerText('Upload hat funktioniert!');  
                        setLoaderStatus('finished');  
                    }
            })
        },5000)

        
      }

      const closeMessenger = () =>{
        setM3HW_Messenger('M3HW_Messenger_gone');
        setM3HW_MessengerText('');
       
    }



    return(
        <div className='M3CK1S_Wrapper'>
            <div className={M3CK1S_Top}>
                <div className={M3CK1SNavigationArea}>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage1} onClick={selectPage1}></div>
                    </div>
                    
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage3} onClick={selectPage3}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage4} onClick={selectPage4}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                    <div className={M3CK1SNavImage5} onClick={selectPage5}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                    <div className={M3CK1SNavImage6} onClick={selectPage6}></div>
                    </div>
                </div>
            </div>
            <div className={M3CK1S_Middle}>
                <div className={M3CK1S_ContentArea1}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                               <div className={AiExplainArea}>
                                    <div className={AiExplainAreaTitel}>Zusammenfassung ChatGPT</div>
                                    <div className={AiExplainAreaBody}>
                                        <MullerFurstenberg></MullerFurstenberg>
                                    </div>
                               </div>
                            </div>                
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea2}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea3}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={Schreibaufgabe1}>
                                        <div className={Sch1_SpiegelTextArea}>
                                            <div className={Schi1_SpiegelTextTitel}>Wann sollte morgens der Unterricht beginnen?</div>
                                            <div className={Schi1_SpiegelTextImage1}></div>
                                            <div className={Schi1_SpiegelTextArticle}>{SpiegelArtikel}</div>
                                        </div>
                                        <div className={Sch1_SpiegelCommentareArea}>
                                            <div className={Sch1_SpiegelKommentar}>
                                                <p><b>Unnütze Meckerei!</b></p>
                                                <p>Wenn die Schüler doch mehr Schlaf brauchen, dann sollen sie halt früher ins Bett gehen und nicht mehr stundenlang TV gucken!!! Die Schüler sollen auch nicht immer ihren Willen bekommen: Wenn es nach ihnen geht, würde die NIE ins Bett, nicht auf die Eltern hören und den ganzen Tag nur Computerspielen!</p>
                                                <p><i>User: MoDa525</i></p>
                                            </div>
                                            <div className={Sch1_SpiegelKommentar}>
                                                <p><b>Gewöhnt euch dran!</b></p>
                                                <p>Viele normale Berufe finden auch in der Zeit von 8.00 bis 16.00 Uhr statt. Die Schüler müssen sich einfach daran gewöhnen.</p>
                                                <p><i>User: Herr Workter</i></p>
                                            </div>
                                            <div className={Sch1_SpiegelKommentar}>
                                                <p><b>10.00 Uhr sofort!</b></p>
                                                <p>Viele Schüler schaffen es bei dem ganzen Stress morgens nicht einfach vernünftig zu Frühstücken. Wie soll man sich bitte in der Schule konzentrieren, wenn man einfach nur Hunger hat!</p>
                                                <p><i>User: Frau Menas</i></p>
                                            </div>
                                            <div className={Sch1_SpiegelKommentar}>
                                                <p><b>Ja!</b></p>
                                                <p>Ich sehe es immer am Beispiel meiner Schwester: Die geht immer erst um 2.00 oder 3.00 Uhr in der Nacht schlafen. Wenn die dann um 7.00 los muss, ist sie völlig müde. Oft schläft sie sogar in der Schule ein.</p>
                                                <p><i>User: Nick Laker</i></p>
                                            </div>
                                            <div className={Sch1_SpiegelKommentar}>
                                                <p><b>Bitte nicht, ich will weiter Fußball spielen können!</b></p>
                                                <p>Wenn die Schule später beginnt, dann ist sie auch später zu Ende. Wenn ich erst um 16.00 oder 18.00 Uhr aus der Schule komme, bleibt ja überhaupt gar keine Zeit mehr, um zum Fußballtraining zu gehen oder Freunde zu treffen!!!</p>
                                                <p><i>User: Milan Meier.</i></p>
                                            </div>
                                        </div>
                                        <div className={Sch1_TaskArea}>
                                            <div className={Sch1_TaskSchreibaufgabe}>
                                                <p><b>Schreibaufgabe:</b></p>
                                                <p><i>Schreibe einen Brief an die SPIEGEL-Redaktion? Schreibe ob du für oder gegen einen späteren Schulbeginn bist. Achte darauf, dass in deinem Brief Thesen, Argumente und Beispiele sind. Denke an die Anrede und die Schlussformel.</i></p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea4}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={SchreibproduktFeedback}>
                                    <div className={SchreibproduktFeedbackTitel}>Fynns Schreibprodukt + Ihr Feedback</div>
                                    <div className={SchreibproduktFeedbackMain}>
                                        <div className={SchreibproduktFeedbackMainBox}><SchulertextViewer Schulername={"Fynn"} Lernertext={"Liebe Redaktion, | | als ich neulich ihren Artikel über den Schulbeginn | gelesen habe, kam mir die Idee Ihnen einen | Brief zu schreiben und darin meine Meinung zu sagen. | Ich bin der Meinung dass die Schule nicht später | anfangen soll, denn dann würde die Schule auch | länger dauern und wenn man dann auch noch (wie ich) | 8 Stunden hat ist man erst ganz spät zu Hause | und dann hat man keine Zeit mehr für andere Sachen | (wie z.B. Fußball spielen). Außerdem müssen manche | Eltern morgens pünktlich zur Arbeit und wollen | ihre Kinder nicht alleine lassen. Wie z.B. bei mir | zu Hause meine Eltern müssen um 7:30 zur Arbeit | und ich und meine Schwester müssen um diese | Zeit dann auch los und wir sollen alleine bleiben. | | Ich komme zu dem Schluss, dass die Schule nicht | später anfangen sollte. | LG | Fynn"}></SchulertextViewer></div>
                                        <div className={SchreibproduktFeedbackMainBox}><UserErgebnis UserCode={UserCode} Datum={Datum} Kurs={Kurs} Save={triggerSave} triggerLoad={triggerLoad} MasterSlave="Master" Stimulus="Fynn"></UserErgebnis></div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea5}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                               <div className={SchreibproduktFeedback}>
                                <div className={SchreibproduktFeedbackTitel}>Mias Schreibprodukt + Ihr Feedback</div>
                                <div className={SchreibproduktFeedbackMain}>
                                         <div className={SchreibproduktFeedbackMainBox}><SchulertextViewer Schulername={"Mia"} Lernertext={"Sehr geehrte Damen und Herren des Spiegel Teams, mein Name ist | Mia Weber, ich bin 11 Jahre alt. Ich habe Ihren Artikel gelesen und | finde das es bei 8.00 Uhr mit dem Schulbeginn bleiben soll. | Ich bin selbst morgens immer müde, aber dann ich eben früher ins Bett | gehen. | Die Idee das die Schule später anfängt ist ansich ganz gut aber es ist nicht | so gut das die Schule dadurch auch länger dauerd. Man hätte aber am | Nachmittag nicht mehr genug Zeit zum Verabreden oder für Hobbys. | Man muss auch dafür sorgen das man rechzeitig Schlafen geht sonst | ist man immer müde. Es ist auch so das wen die Schule später anfängt das | die Schüler später ins Bett gehen und immernoch müde sind. Dann wäre es | ja eigentlich nutzlos die Schule später anfangen zu lassen. | Ich komme zu dem Schluss, dass die Schule weiter hin um 8:00 beginnen | sollte. Mit lieben Grüßen Mia"}></SchulertextViewer></div>
                                        <div className={SchreibproduktFeedbackMainBox}><UserErgebnis UserCode={UserCode} Datum={Datum} Kurs={Kurs} Save={triggerSave} triggerLoad={triggerLoad} MasterSlave="Slave" Stimulus="Mia"></UserErgebnis></div>
                                </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea6}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                                <div className={SpeicherSeite}>
                                <div className={SpeicherSeiteTitel}><h3>Ergebnisse teilen</h3> <p>(<i>UserCode {UserCode}</i>)</p></div>
                                
                                    <div className={ChatGPTShareBox}>
                                        <div className={ChatGPTShareBoxTitel}>Hier den Link zum ChatGPT-Chat einfügen</div>
                                        <div className='ChatGPT_Input_Row'>
                                            <input className='ChatGPTInput_Field' placeholder='https://chatgpt.com/share/...' onChange={(e) =>{setChatGPTLink(e.target.value)}}></input>
                                            <div className={ChatGPTInput_send} onClick={UploadChatGPTLink}>Hochladen</div>
                                        </div>
                                    </div>
                                    
                                    <div className={SpeicherButtonArea}><div className={SpeicherButton}><Link to="/M3Transit" style={{textDecoration:"none", color:"black"}} Origin="M3CK1S">Weiter</Link></div></div>
                                    <div className={WeiterButtonArea}><div className={WeiterButton}><Link to="/M3Transit" style={{textDecoration:"none", color:"black"}} Origin="M3CK1S">Weiter</Link></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1SNextButton} onClick={nextPage}>{">"}</div>
            </div>
            <div className={M3HW_Messenger}>
                <div className='M3HW_Messenger_Textarea'>{M3HW_MessengerText}</div>
                <div className='M3HW_MessengerButton' onClick={closeMessenger}>Okay</div>
            </div>
        </div>
    )
}

export default M3TK1S;