import '../styles/Annotation.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';

function Annotation(props)
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [MasterSlave, setMasterSlave] = useState(props.MasterSlave);
    const Api = "https://www.schreibenmitki.de:8443/";
    const [UserCode, setUserCode] = useState(props.UserCode);
    const [Datum, setDatum] = useState(props.Datum);
    const [Kurs, setKurs] = useState(props.Kurs);
    const [AnnotationStatus, setAnnotationStatus] = useState(props.Status);
    const [InputDisabled, setInputDisabled] = useState(false);
    const [AnnotationWrapper, setAnnotationWrapper] = useState('AnnotationWrapper');
    const [AnnotationTextArea1, setAnnotationTextArea1] = useState('AnnotationTextAreaLevel1');
    const [AnnotationTextArea2, setAnnotationTextArea2] = useState('AnnotationTextAreaLevel2_gone');
    const [AnnotationTextArea3, setAnnotationTextArea3] = useState('AnnotationTextAreaLevel3_gone');
    const [AnnotationTextArea4, setAnnotationTextArea4] = useState('AnnotationTextAreaLevel4_gone');
    const [AnnotationTextArea1_2, setAnnotationTextArea1_2] = useState('AnnotationTextAreaLevel1_gone');
    const [AnnotationTextArea2_2, setAnnotationTextArea2_2] = useState('AnnotationTextAreaLevel2_gone');
    const [AnnotationTextArea3_2, setAnnotationTextArea3_2] = useState('AnnotationTextAreaLevel3_gone');
    const [AnnotationTextArea4_2, setAnnotationTextArea4_2] = useState('AnnotationTextAreaLevel4_gone');
    const [AnnotationToolArea, setAnnotationToolArea] = useState('AnnotationToolArea');
    const [AnnotationLevelTool, setAnnotationLevelTool] = useState('AnnotationLevelTool');
    const [AnnotationCommentTool, setAnnotationCommentTool] = useState('AnnotationCommentTool');
    const [AnnotationLevelToolLevel1, setAnnotationLevelToolLevel1] = useState('AnnotationLevelToolLevel1_active');
    const [AnnotationLevelToolLevel2, setAnnotationLevelToolLevel2] = useState('AnnotationLevelToolLevel2_passive');
    const [AnnotationLevelToolLevel3, setAnnotationLevelToolLevel3] = useState('AnnotationLevelToolLevel3_passive');
    const [AnnotationLevelToolLevel4, setAnnotationLevelToolLevel4] = useState('AnnotationLevelToolLevel4_passive');
    const [AnnotationTextRow, setAnnotationTextRow] = useState('AnnotationTextRow');
    const [AnTextRow1, setAnTextRow1] = useState([]);
    const [AnTextRow2, setAnTextRow2] = useState([]);
    const [AnTextRow3, setAnTextRow3] = useState([]);
    const [AnTextRow4, setAnTextRow4] = useState([]);
    const [AnTextRow1_2, setAnTextRow1_2] = useState([]);
    const [AnTextRow2_2, setAnTextRow2_2] = useState([]);
    const [AnTextRow3_2, setAnTextRow3_2] = useState([]);
    const [AnTextRow4_2, setAnTextRow4_2] = useState([]);
    const [markingColor, setMarkingColor] = useState('#e1743c');
    const [loschenButton, setLoschenButton] = useState('LoschenButton');
    const [CommentToolLevel1,setCommentToolLevel1] = useState('CommentToolLevel1');
    const [CommentToolLevel2,setCommentToolLevel2] = useState('CommentToolLevel2_gone');
    const [CommentToolLevel3,setCommentToolLevel3] = useState('CommentToolLevel3_gone');
    const [CommentToolLevel4,setCommentToolLevel4] = useState('CommentToolLevel4_gone');
    const [CommentToolCommentField, setCommentToolCommentField] = useState('CommentToolCommentField');
    const [CommentToolTitel, setCommentToolTitel] = useState('CommentToolTitel');
    const [commenting, setCommenting] = useState(false);
    const [mainLog, setMainLog] = useState(["MainLog:",]);
    const [marking, setMarking] = useState(false);
    const [currentRow, setCurrentRow] = useState(0);
    const [sended, setSended] = useState(false);
    const [LevelToolLabel, setLevelToolLabel] = useState('LevelToolLabel');

    const [KommentarLevel1, setKommentarLevel1] = useState('');
    const [KommentarLevel2, setKommentarLevel2] = useState('');
    const [KommentarLevel3, setKommentarLevel3] = useState('');
    const [KommentarLevel4, setKommentarLevel4] = useState('');
         
    const [Modus, setModus] = useState('Level1'); 

    const [MarkLevel1, setMarkLevel1] = useState([props.Level1,]);
    const [MarkLevel2, setMarkLevel2] = useState([props.Level2,]);
    const [MarkLevel3, setMarkLevel3] = useState([props.Level3,]);
    const [MarkLevel4, setMarkLevel4] = useState([props.Level4,]);

    const [Level1, setLevel1] = useState(props.Level1);
    const [Level2, setLevel2] = useState(props.Level2);
    const [Level3, setLevel3] = useState(props.Level3);
    const [Level4, setLevel4] = useState(props.Level4);

    const [LevelText1, setLevelText1] = useState(props.Level1);
    const [LevelText2, setLevelText2] = useState(props.Level2);
    const [LevelText3, setLevelText3] = useState(props.Level3);
    const [LevelText4, setLevelText4] = useState(props.Level4);

    const [delete1, setDelete1] = useState(0);
    const [delete2, setDelete2] = useState(0);
    const [delete3, setDelete3] = useState(0);
    const [delete4, setDelete4] = useState(0);
  
    useEffect(() => {
        setAnnotationStatus(props.Status);
        if(props.Status == true)
        {
            setInputDisabled(false);
        }
        if(props.Status == false)
            {
                setInputDisabled(true);
            }
    }, [props.Status])

    const AnalyseInput = () =>{
        if(AnTextRow1.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
               AnTextRow1.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        }  
        if(AnTextRow2.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
                AnTextRow2.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        }
        if(AnTextRow3.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
                AnTextRow3.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        }  
        if(AnTextRow4.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
                AnTextRow4.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        } 
        if(AnTextRow1_2.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
               AnTextRow1_2.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        }
        if(AnTextRow2_2.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
                AnTextRow2_2.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        } 
        if(AnTextRow3_2.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
                AnTextRow3_2.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
         }  
         if(AnTextRow4_2.length < 2)
        {
            var InputSplit = props.Textinput.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
                AnTextRow4_2.push(<div contentEditable>{InputSplit[i]}</div>);
            }  
        }                   
    }

    const Commenting = () =>{
        setCommenting(true);
    }

    const NotCommenting = () =>{
        setCommenting(false);
    }

    const enableMarking = () =>{
        setMarking(true);
    }

    const disableMarking = () =>{
        setMarking(false);
    }

    useEffect(()=>{
    if(windowSize.current[0] > 1600)
    {
        setAnnotationTextRow('AnnotationTextRow_Larger');
        setLevelToolLabel('LevelToolLabel_4K');
    }
    if(windowSize.current[0] < 1300)
    {
        setAnnotationTextRow('AnnotationTextRow_Smaller');
    }
    if(windowSize.current[0] < 600)
    {
        setAnnotationWrapper('AnnotationWrapper_Mobile');
        setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_active_Mobile');
        setAnnotationTextArea1('AnnotationTextAreaLevel1_Mobile');
        setAnnotationToolArea('AnnotationToolArea_Mobile');
        setAnnotationCommentTool('AnnotationCommentTool_Mobile');
        setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_Mobile_active');
        setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_Mobile_passive');
        setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_Mobile_passive');
        setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_Mobile_passive');
        setCommentToolLevel1('CommentToolLevel1_Mobile');
        setCommentToolTitel('CommentToolTitel_Mobile');
        setCommentToolCommentField('CommentToolCommentField_Mobile')
        setAnnotationLevelTool('AnnotationLevelTool_Mobile');
        setLevelText1("Ausdrucks-kompetenz");
        setLevelText2("Kontextual-isierungs-kompetenz");
        setLevelText3("Anti-zipations-kompetenz");
        setLevelText4("Text-gestaltungs-kompetenz");
        setLoschenButton('LoschenButton_Mobile');
        setLevelToolLabel('LevelToolLabel_Mobile');
    }
    AnalyseInput();
    }, windowSize.current[0]);






    const selectLevel1 = () =>{
        setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_active');
        setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_passive');
        setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_passive');
        setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_passive');
        if(delete1 == 0)
            {
                setAnnotationTextArea1('AnnotationTextAreaLevel1');
                setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
            }
        if(delete1 == 1)
            {
                setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
                setAnnotationTextArea1_2('AnnotationTextAreaLevel1');
            }
        
        setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
        setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
        setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
        setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
        setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
        setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
        setMarkingColor("#e1743c");
        setModus('Level1');
        setCommentToolLevel1('CommentToolLevel1');
        setCommentToolLevel2('CommentToolLevel2_gone');
        setCommentToolLevel3('CommentToolLevel3_gone');
        setCommentToolLevel4('CommentToolLevel4_gone');
        if(windowSize.current[0] < 600)
        {
            setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_Mobile_active');
            setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_Mobile_passive');
            setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_Mobile_passive');
            setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_Mobile_passive');
            if(delete1 == 0)
                {
                    setAnnotationTextArea1('AnnotationTextAreaLevel1_Mobile');
                    setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
                }
            if(delete1 == 1)
                {
                    setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
                    setAnnotationTextArea1_2('AnnotationTextAreaLevel1_Mobile');
                }
            
            setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
            setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
            setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
            setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
            setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
            setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
            setMarkingColor("#e1743c");
            setModus('Level1');
            setCommentToolLevel1('CommentToolLevel1_Mobile');
            setCommentToolLevel2('CommentToolLevel2_gone');
            setCommentToolLevel3('CommentToolLevel3_gone');
            setCommentToolLevel4('CommentToolLevel4_gone');
            mainLog.push('Wechsel auf Level 1');
        }
    }

    const selectLevel2 = () =>{
        setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_passive');
        if(delete2==0)
        {
            setAnnotationTextArea2('AnnotationTextAreaLevel2');
            setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
        }
        if(delete2==1)
        {
            setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
            setAnnotationTextArea2_2('AnnotationTextAreaLevel2');
        }
        setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_active');
        setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_passive');
        setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_passive');
        setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
        
        setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
        setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
        setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
        setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
        setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
        setModus('Level2');
        setMarkingColor("#3cbae1");
        setCommentToolLevel1('CommentToolLevel1_gone');
        setCommentToolLevel2('CommentToolLevel2');
        setCommentToolLevel3('CommentToolLevel3_gone');
        setCommentToolLevel4('CommentToolLevel4_gone');
        mainLog.push('Wechsel auf Level 2');
        if(windowSize.current[0]<600)
        {
            setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_Mobile_passive');
            if(delete2==0)
            {
                setAnnotationTextArea2('AnnotationTextAreaLevel2_Mobile');
                setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
            }
            if(delete2==1)
            {
                setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
                setAnnotationTextArea2_2('AnnotationTextAreaLevel2_Mobile');
            }
            setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_Mobile_active');
            setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_Mobile_passive');
            setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_Mobile_passive');
            setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
            
            setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
            setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
            setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
            setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
            setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
            setModus('Level2');
            setMarkingColor("#3cbae1");
            setCommentToolLevel1('CommentToolLevel1_gone');
            setCommentToolLevel2('CommentToolLevel2_Mobile');
            setCommentToolLevel3('CommentToolLevel3_gone');
            setCommentToolLevel4('CommentToolLevel4_gone');
            mainLog.push('Wechsel auf Level 2');
        }
    }

    const selectLevel3 = () =>{
        setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_passive');
        setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_passive');
        setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_active');
        setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_passive');
        setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
        setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
        if(delete3==0)
            {
                setAnnotationTextArea3('AnnotationTextAreaLevel3');
                setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
            }
        if(delete3==1)
            {
                setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
                setAnnotationTextArea3_2('AnnotationTextAreaLevel3');
            }
       
        setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
        setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
        setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
        setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
        setMarkingColor("#ba3ce1");
        setModus('Level3');
        setCommentToolLevel1('CommentToolLevel1_gone');
        setCommentToolLevel2('CommentToolLevel2_gone');
        setCommentToolLevel3('CommentToolLevel3');
        setCommentToolLevel4('CommentToolLevel4_gone');
        mainLog.push('Wechsel auf Level 3');
        if(windowSize.current[0]<600)
        {
            setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_Mobile_passive');
            setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_Mobile_passive');
            setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_Mobile_active');
            setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_Mobile_passive');
            setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
            setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
            if(delete3==0)
                {
                    setAnnotationTextArea3('AnnotationTextAreaLevel3_Mobile');
                    setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
                }
            if(delete3==1)
                {
                    setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
                    setAnnotationTextArea3_2('AnnotationTextAreaLevel3_Mobile');
                }
        
            setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
            setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
            setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
            setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
            setMarkingColor("#ba3ce1");
            setModus('Level3');
            setCommentToolLevel1('CommentToolLevel1_gone');
            setCommentToolLevel2('CommentToolLevel2_gone');
            setCommentToolLevel3('CommentToolLevel3_Mobile');
            setCommentToolLevel4('CommentToolLevel4_gone');
            mainLog.push('Wechsel auf Level 3');
        }
    }

    const selectLevel4 = () =>{
        setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_passive');
        setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_passive');
        setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_passive');
        setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_active');
        setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
        setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
        setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
        if(delete4==0)
            {
                setAnnotationTextArea4('AnnotationTextAreaLevel4');
                setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
            }
        if(delete4==1)
            {
                setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
                setAnnotationTextArea4_2('AnnotationTextAreaLevel4');
            }
        
        setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
        setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
        setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
        setMarkingColor("#5ae13c");
        setModus('Level4');
        setCommentToolLevel1('CommentToolLevel1_gone');
        setCommentToolLevel2('CommentToolLevel2_gone');
        setCommentToolLevel3('CommentToolLevel3_gone');
        setCommentToolLevel4('CommentToolLevel4');
        mainLog.push('Wechsel auf Level 4');

        if(windowSize.current[0]<600)
        {
            setAnnotationLevelToolLevel1('AnnotationLevelToolLevel1_Mobile_passive');
            setAnnotationLevelToolLevel2('AnnotationLevelToolLevel2_Mobile_passive');
            setAnnotationLevelToolLevel3('AnnotationLevelToolLevel3_Mobile_passive');
            setAnnotationLevelToolLevel4('AnnotationLevelToolLevel4_Mobile_active');
            setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
            setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
            setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
            if(delete4==0)
                {
                    setAnnotationTextArea4('AnnotationTextAreaLevel4_Mobile');
                    setAnnotationTextArea4_2('AnnotationTextAreaLevel4_gone');
                }
            if(delete4==1)
                {
                    setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
                    setAnnotationTextArea4_2('AnnotationTextAreaLevel4_Mobile');
                }
            
            setAnnotationTextArea1_2('AnnotationTextAreaLevel1_gone');
            setAnnotationTextArea2_2('AnnotationTextAreaLevel2_gone');
            setAnnotationTextArea3_2('AnnotationTextAreaLevel3_gone');
            setMarkingColor("#5ae13c");
            setModus('Level4');
            setCommentToolLevel1('CommentToolLevel1_gone');
            setCommentToolLevel2('CommentToolLevel2_gone');
            setCommentToolLevel3('CommentToolLevel3_gone');
            setCommentToolLevel4('CommentToolLevel4_Mobile');
            mainLog.push('Wechsel auf Level 4');
        }
    }

    onmouseup = (function(){
        if(commenting==false && marking == true && AnnotationStatus == true)
        {
        var text = "";
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type != "Control") {
            text = document.selection.createRange().text;
        }
        document.execCommand("backColor", false, markingColor);
        window.getSelection().removeAllRanges();

        console.log(text);
        mainLog.push(text);
        if(Modus == "Level1"){MarkLevel1.push(text+"("+currentRow+")")}
        if(Modus == "Level2"){MarkLevel2.push(text+"("+currentRow+")")}
        if(Modus == "Level3"){MarkLevel3.push(text+"("+currentRow+")")}
        if(Modus == "Level4"){MarkLevel4.push(text+"("+currentRow+")")}
        }

      })

    const loschen1 = () =>{
       console.log("loschen!");
       setDelete1(1);
       setAnnotationTextArea1('AnnotationTextAreaLevel1_gone');
       setAnnotationTextArea1_2('AnnotationTextAreaLevel1');
       mainLog.push("Loeschen auf Level 1");
    }

    const loschen2 = () =>{
        console.log("loschen!");
        setDelete2(1);
        setAnnotationTextArea2('AnnotationTextAreaLevel2_gone');
        setAnnotationTextArea2_2('AnnotationTextAreaLevel2');
        mainLog.push("Loeschen auf Level 2");
     }
    const loschen3 = () =>{
        console.log("loschen!");
        setDelete3(1);
        setAnnotationTextArea3('AnnotationTextAreaLevel3_gone');
        setAnnotationTextArea3_2('AnnotationTextAreaLevel3');
        mainLog.push("Loeschen auf Level 3");
    }
    const loschen4 = () =>{
        console.log("loschen!");
        setDelete4(1);
        setAnnotationTextArea4('AnnotationTextAreaLevel4_gone');
        setAnnotationTextArea4_2('AnnotationTextAreaLevel4');
        mainLog.push("Loeschen auf Level 4");
    }

    const updateRow = (row) =>{
        setCurrentRow(row);
    }

    const handleRow0 = () => {updateRow(0)}
    const handleRow1 = () => {updateRow(1)}
    const handleRow2 = () => {updateRow(2)}
    const handleRow3 = () => {updateRow(3)}
    const handleRow4 = () => {updateRow(4)}
    const handleRow5 = () => {updateRow(5)}
    const handleRow6 = () => {updateRow(6)}
    const handleRow7 = () => {updateRow(7)}
    const handleRow8 = () => {updateRow(8)}
    const handleRow9 = () => {updateRow(9)}
    const handleRow10 = () => {updateRow(10)}
    const handleRow11 = () => {updateRow(11)}
    const handleRow12 = () => {updateRow(12)}
    const handleRow13 = () => {updateRow(13)}
    const handleRow14 = () => {updateRow(14)}
    const handleRow15 = () => {updateRow(15)}

    useEffect(() =>{
        if(props.Save == true && sended == false)
            {
                setSended(true);
                if(MasterSlave == "Master")
                {
                    console.log("Saving:");
                    console.log(MarkLevel1);
                    console.log(KommentarLevel1);
                    console.log(MarkLevel2);
                    console.log(KommentarLevel2);
                    console.log(MarkLevel3);
                    console.log(KommentarLevel3);
                    console.log(MarkLevel4);
                    console.log(KommentarLevel4);
                    console.log(mainLog);
                    Axios.post(Api+'saveM3CK1',{UserCode: UserCode, 
                        Datum: Datum, 
                        Kurs: Kurs,
                        Level1Fokus: Level1, 
                        Level2Fokus: Level2,
                        Level3Fokus: Level3, 
                        Level4Fokus: Level4,
                        Stimulus: "Fynn",
                        MarkierungL1: MarkLevel1.toString(),
                        KommentarL1: KommentarLevel1,
                        MarkierungL2: MarkLevel2.toString(),
                        KommentarL2: KommentarLevel2,
                        MarkierungL3: MarkLevel3.toString(),
                        KommentarL3: KommentarLevel3,
                        MarkierungL4: MarkLevel4.toString(),
                        KommentarL4: KommentarLevel4,
                        MainLog: mainLog.toString()
                    }).catch(err=>err)

                }
                if(MasterSlave == "Slave")
                {
                    setTimeout(()=>{
                        Axios.post(Api+'saveM3CK1',{UserCode: UserCode, 
                            Datum: Datum, 
                            Kurs: Kurs,
                            Level1Fokus: Level1, 
                            Level2Fokus: Level2,
                            Level3Fokus: Level3, 
                            Level4Fokus: Level4,
                            Stimulus: "Mia",
                            MarkierungL1: MarkLevel1.toString(),
                            KommentarL1: KommentarLevel1,
                            MarkierungL2: MarkLevel2.toString(),
                            KommentarL2: KommentarLevel2,
                            MarkierungL3: MarkLevel3.toString(),
                            KommentarL3: KommentarLevel3,
                            MarkierungL4: MarkLevel4.toString(),
                            KommentarL4: KommentarLevel4,
                            MainLog: mainLog.toString()
                        }).catch(err=>err)
                    },6000);
                }
                
            }
    }, [props.Save])


    return(
        <div className={AnnotationWrapper}>
            <div className={AnnotationTextArea1} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow1[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow1[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow1[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow1[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow1[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow1[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow1[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow1[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow1[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow1[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow1[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow1[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow1[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow1[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow15}>{AnTextRow1[14]}</div>
                <div className={loschenButton} onClick={loschen1}><div className='loschenButtonBild'></div></div>
            </div>
            <div className={AnnotationTextArea1_2} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow1_2[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow1_2[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow1_2[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow1_2[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow1_2[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow1_2[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow1_2[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow1_2[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow1_2[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow1_2[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow1_2[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow1_2[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow1_2[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow1_2[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow1_2[14]}</div>
            </div>
            <div className={AnnotationTextArea2} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow2[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow2[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow2[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow2[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow2[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow2[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow2[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow2[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow2[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow2[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow2[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow2[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow2[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow2[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow2[14]}</div>
                <div className={loschenButton}  onClick={loschen2}><div className='loschenButtonBild'></div></div>
            </div>
            <div className={AnnotationTextArea2_2} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow2_2[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow2_2[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow2_2[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow2_2[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow2_2[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow2_2[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow2_2[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow2_2[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow2_2[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow2_2[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow2_2[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow2_2[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow2_2[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow2_2[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow2_2[14]}</div>
            </div>
            <div className={AnnotationTextArea3} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow3[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow3[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow3[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow3[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow3[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow3[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow3[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow3[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow3[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow3[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow3[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow3[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow3[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow3[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow3[14]}</div>
                <div className={loschenButton}  onClick={loschen3}><div className='loschenButtonBild'></div></div>
            </div>
            <div className={AnnotationTextArea3_2} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow3_2[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow3_2[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow3_2[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow3_2[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow3_2[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow3_2[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow3_2[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow3_2[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow3_2[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow3_2[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow3_2[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow3_2[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow3_2[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow3_2[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow3_2[14]}</div>
            </div>
            <div className={AnnotationTextArea4} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow4[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow4[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow4[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow4[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow4[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow4[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow4[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow4[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow4[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow4[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow4[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow4[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow4[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow4[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow4[14]}</div>
                <div className={loschenButton}  onClick={loschen4}><div className='loschenButtonBild'></div></div>
            </div>
            <div className={AnnotationTextArea4_2} onMouseEnter={enableMarking} onMouseLeave={disableMarking}>
                <div className={AnnotationTextRow} onMouseEnter={handleRow0}>{AnTextRow4_2[0]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow1}>{AnTextRow4_2[1]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow2}>{AnTextRow4_2[2]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow3}>{AnTextRow4_2[3]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow4}>{AnTextRow4_2[4]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow5}>{AnTextRow4_2[5]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow6}>{AnTextRow4_2[6]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow7}>{AnTextRow4_2[7]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow8}>{AnTextRow4_2[8]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow9}>{AnTextRow4_2[9]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow10}>{AnTextRow4_2[10]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow11}>{AnTextRow4_2[11]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow12}>{AnTextRow4_2[12]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow13}>{AnTextRow4_2[13]}</div>
                <div className={AnnotationTextRow} onMouseEnter={handleRow14}>{AnTextRow4_2[14]}</div>

            </div>

            <div className={AnnotationToolArea}>
                <div className={AnnotationLevelTool}>
                    <div className={AnnotationLevelToolLevel1} onClick={selectLevel1}><div className={LevelToolLabel}>{LevelText1}</div></div>
                    <div className={AnnotationLevelToolLevel2} onClick={selectLevel2}><div className={LevelToolLabel}>{LevelText2}</div></div>
                    <div className={AnnotationLevelToolLevel3} onClick={selectLevel3}><div className={LevelToolLabel}>{LevelText3}</div></div>
                    <div className={AnnotationLevelToolLevel4} onClick={selectLevel4}><div className={LevelToolLabel}>{LevelText4}</div></div>
                </div>
                <div className={AnnotationCommentTool} onMouseEnter={Commenting} onMouseLeave={NotCommenting}>
                    <div className={CommentToolLevel1}><div className={CommentToolTitel}>Kommentar:</div><textarea className={CommentToolCommentField} placeholder='Text' onChange={(e) => {setKommentarLevel1(e.target.value)}} disabled={InputDisabled}></textarea></div>
                    <div className={CommentToolLevel2}><div className={CommentToolTitel}>Kommentar:</div><textarea className={CommentToolCommentField} placeholder='Text' onChange={(e) => {setKommentarLevel2(e.target.value)}} disabled={InputDisabled}></textarea></div>
                    <div className={CommentToolLevel3}><div className={CommentToolTitel}>Kommentar:</div><textarea className={CommentToolCommentField} placeholder='Text' onChange={(e) => {setKommentarLevel3(e.target.value)}} disabled={InputDisabled}></textarea></div>
                    <div className={CommentToolLevel4}><div className={CommentToolTitel}>Kommentar:</div><textarea className={CommentToolCommentField} placeholder='Text' onChange={(e) => {setKommentarLevel4(e.target.value)}} disabled={InputDisabled}></textarea></div>
                </div>
            </div>
            
        </div>
    )
}
export default Annotation;

