import '../styles/MullerFurstenberg.css';
import { useEffect, useRef, useState } from 'react';

function MullerFurstenberg()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [MullerFurstenberg_Wrapper, setMullerFurstenberg_Wrapper] = useState('MullerFurstenberg_Wrapper');
    const [MF_TextArea, setMF_TextArea] = useState('MF_TextArea');
    const [liMargin, setLiMargin] = useState(3);
    const [liMargin2, setLiMargin2] = useState(0.5);

    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
           
        }

        if(windowSize.current[0] < 900)
            {
                
            }

        if(windowSize.current[0] < 600)
        {
            setMullerFurstenberg_Wrapper('MullerFurstenberg_Wrapper_Mobile');
            setMF_TextArea('MF_TextArea_Mobile');
            setLiMargin(8);
            setLiMargin2(3)
        }

    }, windowSize.current[0]);

    return(
    <div className={MullerFurstenberg_Wrapper}>
        	<div className={MF_TextArea}>
                <ul>
                    <li>LLMs (wie sie ChatGPT zugrunde liegen) generieren zu jedem Wort, das wahrscheinlichste nächste Wort. Sie generieren den wahrscheinlichsten Text ausgehend von einem Wort.</li>
                    <li style={{marginTop:liMargin+"%"}}><b>Konsequenz: </b>GPT ersetzt „Bedeutung“ durch „Auftretenswahrscheinlichkeit“ </li>
                    <li style={{listStyleType: "none"}}>
                        <ul>
                            <li style={{marginTop:liMargin2+"%"}}>ChatGPT ‚weiß‘ nichts und versteht nichts, es kennt nur die Wahrscheinlichkeit <i>aller</i> möglichen Folgewörter.</li>
                            <li style={{marginTop:liMargin2+"%"}}>Dem Output von ChatGPT eine ‚Bedeutung‘ beizumessen, ist ein logischer Fehlschluss.</li>
                            <li style={{marginTop:liMargin2+"%"}}>Stellenweise wird soweit gegangen, nicht von ‚KI‘, sondern <b style={{color:"#e1743c"}}>simulierter Kommunikation</b> zu sprechen </li>
                        </ul>
                    </li>
                    <li style={{marginTop:liMargin+"%"}}>ChatGPT ist ein umfangreicher Speicher für Phrasen.</li>
                    <li style={{marginTop:liMargin+"%"}}>ChatGPT löst jede sprachliche Aufgabe wirklich <i>durchschnittlich.</i></li>
                    <li style={{marginTop:liMargin+"%"}}>ChatGPT erkennt den Durchschnitt (und ignoriert manchmal, was davon abweicht).</li>
                </ul>
                <p style={{textAlign:"right"}}>(aus Müller & Fürstenberg 2023)</p>
                <p style={{marginTop:liMargin2+"%"}}><b style={{color:"#e1743c"}}>Didaktische Konsequenz: </b><b>ChatGPT sollte absolut in der Lage sein, ein durchschnittliches Feedback zu generieren bzw. zu simulieren.</b></p>
            </div>
    </div>
    )
}
export default MullerFurstenberg;