import '../styles/Footer.css'
import { useEffect, useRef, useState } from 'react';


function Footer(props)
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const page = props.Page;
    const [FooterTextRow, setFooterTextRow] = useState('FooterTextRow');
    const [FooterText, setFooterText] = useState('FooterText');

    const [FooterWrapper, setFooterWrapper] = useState('FooterWrapper')

    useEffect(()=>{
        if(page == "Landingpage")
        {
            setFooterWrapper('FooterWrapper_Landingpage')
        

        if(windowSize.current[0] < 1200)
        {
            setFooterWrapper('FooterWrapper_Landingpage_Ipad');
            setFooterTextRow('FooterTextRow_Ipad');
            setFooterText('FooterText_Ipad');
        }

        if(windowSize.current[0] < 900)
            {
                setFooterWrapper('FooterWrapper_Landingpage_Ipad');
                setFooterTextRow('FooterTextRow_Ipad');
                setFooterText('FooterText_Ipad');
            }

        if(windowSize.current[0] < 600)
        {
            setFooterWrapper('FooterWrapper_Landingpage_Mobile');
            setFooterTextRow('FooterTextRow_Mobile');
            setFooterText('FooterText_Mobile');
        }
        }

        if(page != "Landingpage")
        {
            setFooterWrapper('FooterWrapper_Page')
        

        if(windowSize.current[0] < 1200)
        {
            setFooterWrapper('FooterWrapper_Page_Ipad');
            setFooterTextRow('FooterTextRow_Ipad');
            setFooterText('FooterText_Ipad');
        }

        if(windowSize.current[0] < 600)
        {
            setFooterWrapper('FooterWrapper_Page_Mobile');
            setFooterTextRow('FooterTextRow_Mobile');
            setFooterText('FooterText_Mobile');
        }
        }

    }, windowSize.current[0]);

    return(
        <div className={FooterWrapper}>
            <div className={FooterTextRow}>
                <div className={FooterText}>Schreiben mit KI im Lehramt ist ein Projekt der Friedrich-Schiller-Universität Jena</div>
            </div>
        </div>
    )
}

export default Footer;