import {Routes} from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import {Route} from 'react-router-dom';
import Modul3 from './pages/Modul3.js';
import Landingpage from './pages/Landingpage.js';
import Projektseite from './pages/Projektseite.js';
import Modul3_Info from './pages/Modul3_Info.js'
import Anmeldeseite from './pages/Anmeldeseite.js';
import M3CK1S from './module/modul3/M3CK1S.js';
import M3Transit from './module/modul3/M3Transit.js';
import M3PK1S from './module/modul3/M3PK1S.js';
import M3TK1S from './module/modul3/M3TK1S.js';
import M3TK2S from './module/modul3/M3TK2S.js';
import M3HW from './module/modul3/M3HW.js';
import M3E1 from './module/modul3/M3E1.js';
import AdminPage from './pages/AdminPage.js';
import Register from './pages/Register.js';
import M3HW_A from './module/modul3/M3HW_A.js';
import M3Q from './module/modul3/M3Q.js';
import M3HW_C from './module/modul3/M3HW_C.js';
import Modulubersicht from './pages/Modulubersicht.js';

function Main()
{
    return(<>
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<Landingpage/>}></Route>
        <Route path="/Projekt" element={<Projektseite/>}></Route>
        <Route path="/Modul3" element={<Modul3/>}></Route>
        <Route path="/Modulubersicht" element={<Modulubersicht/>}></Route>
        <Route path="/Anmeldung" element={<Anmeldeseite/>}></Route>
        <Route path="/Modul3_Info" element={<Modul3_Info/>}></Route>

        <Route path="/M3CK1S" element={<M3CK1S/>}></Route>
        <Route path="/M3PK1S" element={<M3PK1S/>}></Route>
        <Route path="/M3TK1S" element={<M3TK1S/>}></Route>
        <Route path="/M3TK2S" element={<M3TK2S/>}></Route>
        <Route path="/M3HW" element={<M3HW/>}></Route>
        <Route path="/M3HW_A" element={<M3HW_A/>}></Route>
        <Route path="/M3E1" element={<M3E1/>}></Route>
        <Route path="/M3Q" element={<M3Q/>}></Route>
        <Route path="/M3HW_C" element={<M3HW_C/>}></Route>
        <Route path="/M3Transit" element={<M3Transit/>}></Route>

        <Route path="/AdminPage" element={<AdminPage/>}></Route>
        <Route path="/Register" element={<Register/>}></Route>
    </Routes>
    </BrowserRouter>
    
    </>)

}
export default Main;