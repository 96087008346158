import '../styles/M3HW_C.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie'; 
import Loader from '../../components/Loader.js';
import M3Printer2 from '../../components/M3Printer2.js';
import '../styles/M3E1.css'

function M3HW_C()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [UserCode, setUserCode] = useState(Cookies.get("SchreibenmitKI_UserCode"));
    const [Datum, setDatum] = useState('');
    const [Kurs, setKurs] = useState(Cookies.get("SchreibenmitKI_WorkshopCode"));
    const Api = "https://www.schreibenmitki.de:8443/";

    const [M3HW_C_Wrapper, setM3HW_C_Wrapper] = useState('M3HW_C_Wrapper');
    const [M3HW_C_Page1, setM3HW_C_Page1] = useState('M3HW_C_Page1');
    const [M3HW_C_Page2, setM3HW_C_Page2] = useState('M3HW_C_Page2_gone');
    const [M3HW_C_Page3, setM3HW_C_Page3] = useState('M3HW_C_Page3_gone');
    const [M3HW_C_Page3_wait, setM3HW_C_Page3_wait] = useState('M3HW_C_Page3_wait_gone');
    const [M3HW_C_Page3_display, setM3HW_C_Page3_display] = useState('M3HW_C_Page3_display');
    const [M3HW_A_P1_Titel, setM3HW_A_P1_Titel] = useState('M3HW_A_P1_Titel');
    const [M3HW_A_P1_Mid, setM3HW_A_P1_Mid] = useState('M3HW_A_P1_Mid');
    const [M3HW_A_P1_LoaderArea, setM3HW_A_P1_LoaderArea] = useState('M3HW_A_P1_LoaderArea');
    const [M3HW_A_P1_Bottom, setM3HW_A_P1_Bottom] = useState('M3HW_A_P1_Bottom');
    const [M3HW_A_P1_Top, setM3HW_A_P1_Top] = useState('M3HW_A_P1_Top');
    const [HW_A_LoaderBox, setHW_A_LoaderBox] = useState('HW_A_LoaderBox_gone');
    const [HW_A_LoaderBox2, setHW_A_LoaderBox2] = useState('HW_A_LoaderBox_gone');
    const [M3HW_A_FeedbackArea, setM3HW_A_FeedbackArea] = useState('M3HW_A_FeedbackArea');
    const [M3HW_A_FeedbackArea2, setM3HW_A_FeedbackArea2] = useState('M3HW_A_FeedbackArea2');
    const [M3HW_A_CommentArea, setM3HW_A_CommentArea] = useState('M3HW_A_CommentArea');
    const [M3HW_A_CommentArea_ButtonArea, setM3HW_A_CommentArea_ButtonArea] = useState('M3HW_A_CommentArea_ButtonArea');
    const [M3HW_C_TextArea, setM3HW_C_TextArea] = useState('M3HW_C_TextArea');
    const [M3HW_A_FeedbackArea_Text, setM3HW_A_FeedbackArea_Text] = useState('M3HW_A_FeedbackArea_Text');
    const [selectedLerner, setSelectedLerner] = useState('Tim');
    const [M3HW_C_Button1, setM3HW_C_Button1] = useState('M3HW_C_Button');
    const [M3HW_C_Button2, setM3HW_C_Button2] = useState('M3HW_C_Button_gone');
    const [LoaderStatus, setLoaderStatus] = useState('loading');
    const [LernerNummer, setLernerNummer] = useState(99);
    const [givenLerner, setGivenLerner] = useState(99);
    const [Giver, setGiver] = useState('');
    const [LernerText, setLernerText] = useState("");
    const [Kommentar, setKommentar] = useState('');
    const [M3HW_A_CommentArea_Button, setM3HW_A_CommentArea_Button] = useState('M3HW_A_CommentArea_Button');
    const [M3HW_A_CommentArea_Button2, setM3HW_A_CommentArea_Button2] = useState('M3HW_C_Button_gone');
    const [M3HW_C_Page3_waitTextArea, setM3HW_C_Page3_waitTextArea] = useState('M3HW_C_Page3_waitTextArea');
    const [M3HW_C_Page3_waitText, setM3HW_C_Page3_waitText] = useState('M3HW_C_Page3_waitText');
    const [M3HW_C_Page3_aktualBut, setM3HW_C_Page3_aktualBut] = useState('M3HW_C_Page3_aktualBut');
    const [M3HW_C_Page3_aktualButText, setM3HW_C_Page3_aktualButText] = useState('Aktualisieren');
    const [ownLearner, setOwnLearner] = useState('99');
    const [ownFeedbackText, setOwnFeedbackText] = useState('Text');
    const [RuckmeldeText, setRuckmeldeText] = useState('');
    const [TriggerFinalPrint, setTriggerFinalPrint] = useState(false);
    const [M3HW_C_Druckansicht, setM3HW_C_Druckansicht] = useState('M3HW_C_Druckansicht_gone');
    const [PrinterButton, setPrinterButton] = useState('Printerbutton_B');



    const [P1Text1, setP1Text1] = useState(<>
        <p>Als kleine 'Hausaufgabe' formulierten alle Workshopteilnehmenden ein schriftliches Feedback zu einem Lernendentext nach Wahl.</p>
        <p>Heute sollen Sie nun Feedback zum Feedback geben, also eine schriftliche Rückmeldung zu einem der Feedbacks, die Workshopteilnehmende geschrieben haben. Dies geschieht anonym, Sie wissen also nicht, von wem das Feedback stammt, dass Sie sogleich lesen werden.</p>
        <p>Ihre Aufgabe besteht nun aus den folgenden Teilen:</p>
        <ol>
            <li>Betrachten Sie den Feedbacktext ausführlich. Ziehen Sie dazu die Kriterien 'guten' Feedbacks heran. </li>
            <li>Interpretieren Sie aktiv: Inwieweit erfüllt der vorliegende Feedbacktext z.B. diese angelegten Kriterien. Welche Aspekte fallen Ihnen im Feedback noch auf.</li>
            <li>Entscheiden Sie, welche Aspekte Sie zu diesem Feedback rückmelden wollen.</li>
            <li>Verfassen Sie ein schriftliches Feedback zu diesem Feedback.</li>
        </ol>
        <p>Um zu starten, klicken Sie auf die "weiter" Taste</p>
        </>)

    useEffect(()=>{

        if(Cookies.get("M3HW_C") == "true")
        {
            setM3HW_C_Page1('M3HW_C_Page1_gone');
            gotoPage3();
        }
        

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1300)
        {
            
        }

        if(windowSize.current[0] < 600)
        {
            setM3HW_C_Page1('M3HW_C_Page1_Mobile');
            setM3HW_A_P1_Titel('M3HW_A_P1_Titel_Mobile');
            setM3HW_A_P1_Mid('M3HW_A_P1_Mid_Mobile');
            setM3HW_A_P1_LoaderArea('M3HW_A_P1_LoaderArea_Mobile');
            setM3HW_A_P1_Bottom('M3HW_A_P1_Bottom_Mobile');
            setM3HW_A_P1_Top('M3HW_A_P1_Top_Mobile');
            setHW_A_LoaderBox('HW_A_LoaderBox_Mobile');
            setM3HW_A_FeedbackArea('M3HW_A_FeedbackArea_Mobile');
            setM3HW_A_CommentArea('M3HW_A_CommentArea_Mobile');
            setM3HW_A_CommentArea_ButtonArea('M3HW_A_CommentArea_ButtonArea_Mobile');
            setM3HW_A_FeedbackArea_Text('M3HW_A_FeedbackArea_Text_Mobile');
            setM3HW_C_TextArea('M3HW_C_TextArea_Mobile');
            setM3HW_C_Button1('M3HW_C_Button_Mobile');
            setM3HW_C_Page3_waitText('M3HW_C_Page3_waitText_Mobile');
            setM3HW_A_FeedbackArea2('M3HW_A_FeedbackArea2_Mobile');
            setPrinterButton('Printerbutton_Mobile');
        }

    }, windowSize.current[0]);

    const RegisterUser = () =>{
        setM3HW_C_Button1('M3HW_C_Button_gone');
        setHW_A_LoaderBox('HW_A_LoaderBox');
        Axios.post(Api+'setHWAvailable', {UserCode:UserCode}).catch(err => err);
        setTimeout(()=>{
            Axios.post(Api+'giveHW', {UserCode:UserCode}).then((response2) =>{
                if(response2.data.length > 0)
                    {
                        console.log(response2.data[0].UserCode)
                        setGiver(response2.data[0].UserCode);
                        setGivenLerner(response2.data[0].Lerner);
                        setLernerText(response2.data[0].Feedback);
                        if(response2.data[0].Lerner == 1){setSelectedLerner("Connor (Religion)"); setLernerNummer(1);}
                        if(response2.data[0].Lerner == 2){setSelectedLerner("Danielle (Englisch)"); setLernerNummer(2);}
                        if(response2.data[0].Lerner == 3){setSelectedLerner("Luca (Geschichte)"); setLernerNummer(3);}
                        if(response2.data[0].Lerner == 4){setSelectedLerner("Miles (Geographie)"); setLernerNummer(4);}
                        if(response2.data[0].Lerner == 5){setSelectedLerner("einem eigenen Lernenden"); setLernerNummer(5);
                        }
                        setTimeout(() =>{
                            setLoaderStatus('finished');
                            setM3HW_C_Button2('M3HW_C_Button');
                        },2000)                                
                    }
                

            }).catch(err => err);
        },2000)
    }

    const selectPage2 = () =>{
        console.log("click!");
        setM3HW_C_Page1('M3HW_C_Page1_gone');
        setM3HW_C_Page2('M3HW_C_Page2');

        if(windowSize.current[0] < 600)
        {
            setM3HW_C_Page2('M3HW_C_Page2_Mobile');
        }
    }

    const saveKommentar = () =>{
        setHW_A_LoaderBox2('HW_A_LoaderBox');
        setLoaderStatus('loading');
        setM3HW_A_CommentArea_Button('M3HW_C_Button_gone');
        Axios.post(Api+'saveHW_C', {FromUser: UserCode, ToUser:Giver, Lerner:LernerNummer, Kommentar:Kommentar}).catch(err=>err);
        setTimeout(()=>{
            Axios.post(Api+'confirmHW_C', {FromUser: UserCode, ToUser:Giver}).then((result) =>{
                console.log(result.data.confirmation);
                if(result.data.confirmation == "No data!")
                {
                    setLoaderStatus('error');
                }
                if(result.data.confirmation == "Confirm!")
                {
                    setLoaderStatus('finished');
                    setHW_A_LoaderBox2('HW_A_LoaderBox_gone');
                    setM3HW_A_CommentArea_Button2('M3HW_C_Button');
                    
                    if(windowSize.current[0] < 600)
                    {
                                                
                    }
                    
                }
            }).catch(err => err)
           
        },10000)
    }

    const gotoPage3 = () =>{
        setM3HW_C_Page2('M3HW_C_Page2_gone');
        setM3HW_C_Page3('M3HW_C_Page3');
        setM3HW_C_Page3_wait('M3HW_C_Page3_wait');
        setM3HW_C_Page3_display('M3HW_C_Page3_wait_gone');
        Cookies.set("M3HW_C", true);

        if(windowSize.current[0] < 600)
            {
                setM3HW_C_Page2('M3HW_C_Page3_Mobile');
            }

        Axios.post(Api+'returnOwnFeedback', {UserCode: UserCode}).then((response3) =>{
                        if(response3.data.Lerner == 1){setOwnLearner("Connor (Religion)");}
                        if(response3.data.Lerner == 2){setOwnLearner("Danielle (Englisch)"); }
                        if(response3.data.Lerner == 3){setOwnLearner("Luca (Geschichte)");}
                        if(response3.data.Lerner == 4){setOwnLearner("Miles (Geographie)");}
                        if(response3.data.Lerner == 5){setOwnLearner("einem eigenen Lernenden");}
                        setOwnFeedbackText(response3.data.OwnFeedback);
        }).catch(err => err)
        setTimeout(()=>{
            Axios.post(Api+'returnFeedbackZumFeedback', {UserCode: UserCode}).then((response4) =>{
                setRuckmeldeText(response4.data.Kommentar);
                setTimeout(()=>{
                    if(response4.data.Kommentar)
                        {
                            setM3HW_C_Page3_wait('M3HW_C_Page3_wait_gone');
                            setM3HW_C_Page3_display('M3HW_C_Page3_display');
                            if(windowSize.current[0] < 600)
                                {
                                    setM3HW_C_Page3_display('M3HW_C_Page3_display_Mobile');
                                }
                        }
                },2000)
            }).catch(err=>err)
        },3000)
    }

    const reOrderFeedback = () =>{
        Axios.post(Api+'returnOwnFeedback', {UserCode: UserCode}).then((response3) =>{
                        if(response3.data.Lerner == 1){setOwnLearner("Connor (Religion)");}
                        if(response3.data.Lerner == 2){setOwnLearner("Danielle (Englisch)"); }
                        if(response3.data.Lerner == 3){setOwnLearner("Luca (Geschichte)");}
                        if(response3.data.Lerner == 4){setOwnLearner("Miles (Geographie)");}
                        if(response3.data.Lerner == 5){setOwnLearner("einem eigenen Lernenden");}
                        setOwnFeedbackText(response3.data.OwnFeedback);
        }).catch(err => err)
        setTimeout(()=>{
            Axios.post(Api+'returnFeedbackZumFeedback', {UserCode: UserCode}).then((response4) =>{
                setRuckmeldeText(response4.data.Kommentar);
                setTimeout(()=>{
                    if(response4.data.Kommentar)
                        {
                            setM3HW_C_Page3_wait('M3HW_C_Page3_wait_gone');
                            setM3HW_C_Page3_display('M3HW_C_Page3_display');

                            if(windowSize.current[0] < 600)
                                {
                                    setM3HW_C_Page3_display('M3HW_C_Page3_display_Mobile');
                                }

                           
                        }
                },2000)
            }).catch(err=>err)
        },3000)
    };

    const PrintFinal = () =>{
        setM3HW_C_Druckansicht('M3HW_C_Druckansicht');
        setTriggerFinalPrint(true);

        setTimeout(()=>{
            setM3HW_C_Druckansicht('M3HW_C_Druckansicht_gone');
        },3000)
    }

    return(
        <div className='M3HW_C_Wrapper'>
            <div className={M3HW_C_Page1}>
                <div className={M3HW_A_P1_Top}><div className={M3HW_A_P1_Titel}>Feedback zum Feedback</div></div>
                <div className={M3HW_A_P1_Mid}>{P1Text1}</div>
                <div className={M3HW_A_P1_LoaderArea}><div className={HW_A_LoaderBox}><Loader Status={LoaderStatus}></Loader></div></div>
                <div className={M3HW_A_P1_Bottom}><div className= {M3HW_C_Button1} onClick={RegisterUser}>Weiter</div> <div className= {M3HW_C_Button2} onClick={selectPage2}>Weiter</div></div>
            </div>
            <div className={M3HW_C_Page2}>
                <div className={M3HW_A_FeedbackArea}>
                    <div className='M3HW_A_FeedbackArea_Titel'>Feedback aus dem Workshop zum Lernertext von {selectedLerner}</div>
                    <div className={M3HW_A_FeedbackArea_Text}>{LernerText}</div>
                </div>
                <div className={M3HW_A_CommentArea}>
                <div className='M3HW_A_FeedbackArea_Titel'>Ihre schriftliche Rückmeldung zu dem Feedback:</div>
                <textarea className={M3HW_C_TextArea} placeholder='Bitte schreiben Sie hier Ihre Rückmeldung' onChange={(e) => {setKommentar(e.target.value)}}></textarea>
                <div className={M3HW_A_CommentArea_ButtonArea}>
                    <div className={M3HW_A_CommentArea_Button} onClick={saveKommentar}>Fertig</div>
                    <div className={HW_A_LoaderBox2}><Loader Status={LoaderStatus}></Loader></div>
                    <div className={M3HW_A_CommentArea_Button2} onClick={gotoPage3}>Weiter</div>
                </div>
                </div>
            </div>
            <div className={M3HW_C_Page3}>
                <div className={M3HW_C_Page3_wait}>
                    <div className={M3HW_C_Page3_waitTextArea}><div className={M3HW_C_Page3_waitText}><p>Es ist noch keine Rückmeldung zu Ihrem Feedbacktext da.</p> <p>Bitte warten Sie noch einen Moment.</p></div></div>
                    <div className={M3HW_C_Page3_waitTextArea}><div className={M3HW_C_Page3_aktualBut} onClick={reOrderFeedback}>{M3HW_C_Page3_aktualButText}</div></div>
                </div>
                <div className={M3HW_C_Page3_display}>
                      <div className={PrinterButton} onClick={PrintFinal}><div className='Printerbutton_Button'></div></div>
                <div className={M3HW_A_FeedbackArea2}>
                    <div className='M3HW_A_FeedbackArea_Titel'>Ihr Feedback zum Lernendentext von {ownLearner}</div>
                    <div className={M3HW_A_FeedbackArea_Text}>{ownFeedbackText}</div>
                </div>
                <div className={M3HW_A_FeedbackArea2}>
                    <div className='M3HW_A_FeedbackArea_Titel'>Rückmeldung dazu aus der Workshopgruppe:</div>
                    <div className={M3HW_A_FeedbackArea_Text}>{RuckmeldeText}</div>
                </div>
                </div>
            </div>
            <div className={M3HW_C_Druckansicht}>
                <M3Printer2 Schuler={ownLearner} Feedbacktext={ownFeedbackText} Kommentar={RuckmeldeText} TriggerPrint={TriggerFinalPrint}></M3Printer2>
            </div>
        </div>

    )


}

export default M3HW_C