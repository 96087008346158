import '../styles/M3Printer.css';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useState, useRef} from "react";
import Ergebnisse from './Ergebnisse';
import SchulertextViewer from './SchulertextViewer';

function M3Printer(props){

    const [Schuler, setSchuler] = useState(props.Schuler);
    const [Stimulus, setStimulu] = useState(props.Stimulus);
    const [UserCode, setUserCode] = useState(props.UserCode);
    const [Kurs, setKurs] = useState(props.Kurs);
    const [Datum, setDatum] = useState(props.Datum);
    const [triggerLoad2, setTriggerLoad2] = useState(false);
    const componentRef = useRef();
    const [derLernerText, setDerLernerText] = useState('');
    const [TriggerPrint, setTriggerPrint] = useState(false);
    const [printed, setPrinted] = useState(false);
    const [showFynn, setShowFynn] = useState('ShowFynn');
    const [showMia, setShowMia] = useState('Mia_gone');
    const FynnsText = "Liebe Redaktion, | | als ich neulich ihren Artikel über den Schulbeginn | gelesen habe, kam mir die Idee Ihnen einen | Brief zu schreiben und darin meine Meinung zu sagen. | Ich bin der Meinung dass die Schule nicht später | anfangen soll, denn dann würde die Schule auch | länger dauern und wenn man dann auch noch (wie ich) | 8 Stunden hat ist man erst ganz spät zu Hause | und dann hat man keine Zeit mehr für andere Sachen | (wie z.B. Fußball spielen). Außerdem müssen manche | Eltern morgens pünktlich zur Arbeit und wollen | ihre Kinder nicht alleine lassen. Wie z.B. bei mir | zu Hause meine Eltern müssen um 7:30 zur Arbeit | und ich und meine Schwester müssen um diese | Zeit dann auch los und wir sollen alleine bleiben. | | Ich komme zu dem Schluss, dass die Schule nicht | später anfangen sollte. | LG | Fynn"
    const MiasText = "Sehr geehrte Damen und Herren des Spiegel Teams, mein Name ist | Mia Weber, ich bin 11 Jahre alt. Ich habe Ihren Artikel gelesen und | finde das es bei 8.00 Uhr mit dem Schulbeginn bleiben soll. | Ich bin selbst morgens immer müde, aber dann ich eben früher ins Bett | gehen. | Die Idee das die Schule später anfängt ist ansich ganz gut aber es ist nicht | so gut das die Schule dadurch auch länger dauerd. Man hätte aber am | Nachmittag nicht mehr genug Zeit zum Verabreden oder für Hobbys. | Man muss auch dafür sorgen das man rechzeitig Schlafen geht sonst | ist man immer müde. Es ist auch so das wen die Schule später anfängt das | die Schüler später ins Bett gehen und immernoch müde sind. Dann wäre es | ja eigentlich nutzlos die Schule später anfangen zu lassen. | Ich komme zu dem Schluss, dass die Schule weiter hin um 8:00 beginnen | sollte. Mit lieben Grüßen Mia"
    
    useEffect(() => {
        if(props.Schuler == "Fynn")
        {
            setShowFynn('ShowFynn');
            setShowMia('Mia_gone');
        }

        if(props.Schuler == "Mia")
            {
                setShowFynn('Fynn_gone');
                setShowMia('ShowMia');
            }
    })

    useEffect(()=>{
        if(printed == false)
            {   
                if(props.TriggerPrint == true)
                {   setPrinted(true);
                    printingProcess();
                }
            }
        
        
    },[props.TriggerPrint])

    const printingProcess = () =>{
        setTriggerLoad2(true);
        setTimeout(() => {
            handlePrint()
        },4000)
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return(
        <div className='M3PrinterWrapper'>
            <div className='M3PrinterSuperTitel' onClick={printingProcess}>Druckansicht</div>
            <div className='M3Druckbereich' ref={componentRef}>
                <div className='M3Druck_Titel'>Workshop-Ergebnis:</div>
                <div className='M3Druck_Einleitung'>Im Rahmen des Worshops <b style={{color:"#e1743c"}}>Feedback</b> <b>mit KI im Lehramt</b> bestand die Aufgabe u.a. darin, ein Feedback zu dem Lernendentext von {Schuler} zu verfassen. 
                Zunächst wurde der Lernendentext dafür bewusst analysiert. Es wurde interpretiert, inwieweit sich bereits ausprägungen der
                <ul>
                    <li>Ausdruckskompetenz</li>
                    <li>Kontextualisierungskompetenz</li>
                    <li>Antizipationskompetenz</li>
                    <li>Textgestaltungskompetenz</li>
                </ul>
                im Lernendentext zeigen. Dies wurde in Kommentaren festgehalten, die dann die Grundlage für ein lernförderliches Feedback bildeten. Dieser Ausdruck fasst Ihre Kommentare und Ihr schriftliches Feedback zusammen:
                </div>
                <div className='Printer_LernerTextArea'>
                <div className={showFynn}> <SchulertextViewer Schulername={props.Schuler} Lernertext={FynnsText}></SchulertextViewer></div>
                <div className={showMia}> <SchulertextViewer Schulername={props.Schuler} Lernertext={MiasText}></SchulertextViewer></div>
                </div>
                <div className='Printer_ErgebnisArea'>
                <Ergebnisse Modus={"View"} UserCode={UserCode} Datum={Datum} Kurs={Kurs} triggerLoad={triggerLoad2} MasterSlave="Master" Stimulus={Schuler}></Ergebnisse>
                </div>
                <div className='M3Druck_Einleitung'>Ich freue mich über Ihre Beteiligung am Workshop</div>
                <div className='M3Druck_Einleitung'>Gerrit Helm</div>
                <div className='M3Druck_Einleitung' b style={{color:"#e1743c"}}>schreibenmitki.de</div>
            </div>

        </div>
    )

}
export default M3Printer;