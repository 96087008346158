import '../styles/M3PK1.css'
import '../styles/M3CK1.css'
import '../styles/M3TK1.css'
import '../styles/M3E1.css'
import { useEffect, useRef, useState } from 'react';
import Loader from '../../components/Loader.js';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import UserErgebnis from '../../components/UserErgebnis.js';
import SchulertextViewer from '../../components/SchulertextViewer.js';
import MullerFurstenberg from '../../components/MullerFurstenberg.js';
import Ergebnisse from '../../components/Ergebnisse.js';
import M3Printer from '../../components/M3Printer.js';
import Cookies from 'js-cookie'; 

function M3E1(){
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [UserCode, setUserCode] = useState(Cookies.get("SchreibenmitKI_UserCode"));
    const [Datum, setDatum] = useState(Cookies.get("SchreibenmitKI_Datum"));
    const [Kurs, setKurs] = useState(Cookies.get("SchreibenmitKI_WorkshopCode"));
    const Api = "https://www.schreibenmitki.de:8443/";
    const [M3CK1S_Top, setM3CK1S_Top] = useState('M3CK1S_Top');
    const [M3CK1SNavigationArea, setM3CK1SNavigationArea] = useState('M3CK1SNavigationArea2');
    const [M3CK1SNavElement1, setM3CK1SNavElement1] = useState('M3CK1SNavElement');
    const [M3CK1S_Middle, setM3CK1S_Middle] = useState('M3CK1S_Middle');
    const [M3CK1SNavImage1, setM3CK1SNavImage1] = useState('NavImage_Finn_active');
    const [M3CK1SNavImage2, setM3CK1SNavImage2] = useState('NavImage_Mia');
    const [M3CK1SNavImage3, setM3CK1SNavImage3] = useState('NavImage_finish');
    const [M3CK1S_ContentArea1, setM3CK1S_ContentArea1] = useState('M3CK1S_Content_Area');
    const [M3CK1S_ContentArea2, setM3CK1S_ContentArea2] = useState('M3CK1S_Content_Area_rightOff');
    const [M3CK1S_ContentArea3, setM3CK1S_ContentArea3] = useState('M3CK1S_Content_Area_rightOff');

    const [M3CK1SNextButton, setM3CK1SNextButton] = useState('M3CK1S_NextButton_first');
    const [currentPage, setCurrentPage] = useState(0);
    const [SpeicherSeite, setSpeicherSeite] = useState('SpeicherSeite');
    const [SpeicherSeiteTitel, setSpeicherSeiteTitel] = useState('SpeicherSeiteTitel');
    const [SpeicherButtonArea, setSpeicherButtonArea] = useState('M3CK1S_SpeicherButtonArea');
    const [SpeicherButton, setSpeicherButton] = useState('M3CK1S_SpeicherButton');
    const [WeiterButtonArea, setWeiterButtonArea] = useState('M3CK1S_WeiterButtonArea');
    const [WeiterButton, setWeiterButton] = useState('M3CK1S_WeiterButton_gone');
    const [triggerSave, setTriggerSave] = useState(false);
    const [M3CK1SLoader, setM3CK1SLoader] = useState('M3CK1SLoader_gone');
    const [LoaderStatus, setLoaderStatus] = useState('loading');
    const [triggerLoad, setTriggerLoad] = useState(false);
    const [M3Ergebnisse, setM3Ergebnisse] = useState('M3Ergebnisse');
    const [M3ErgebnisseTitel, setM3ErgebnisseTitel] = useState('M3ErgebnisseTitel');
    const [M3ErgebnisseArea, setM3ErgebnisseArea] = useState('M3ErgebnisseArea');
    const [PrintWindowFynn, setPrintWindowFynn] = useState('PrintWindow_gone');
    const [PrintWindowMia, setPrintWindowMia] = useState('PrintWindow_gone');
    const [PrinterButton, setPrinterButton] = useState('Printerbutton');
    const [triggerPrintFynn, settriggerPrintFynn] = useState(false);
    const [triggerPrintMia, settriggerPrintMia] = useState(false);
    

   
    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1300)
        {
            
        }

        if(windowSize.current[0] < 600)
        {
            setM3CK1SNavigationArea('M3CK1SNavigationArea2_Mobile');
            setM3CK1SNavElement1('M3CK1SNavElement_Mobile');
            setM3CK1SNextButton('M3CK1S_NextButton_Mobile_first');
            setSpeicherSeite('SpeicherSeite_Mobile');
            setSpeicherSeiteTitel('SpeicherSeiteTitel_Mobile');
            setSpeicherButtonArea('M3CK1SM3CK1S_SpeicherButtonArea_Mobile');
            setSpeicherButton('M3CK1S_SpeicherButton_Mobile');
            setWeiterButtonArea('M3CK1S_WeiterButtonArea_Mobile');
            setM3Ergebnisse('M3Ergebnisse_Mobile');
            setM3ErgebnisseTitel('M3ErgebnisseTitel_Mobile');
            setPrinterButton('Printerbutton_Mobile');
            setFullScreen();
        }

    }, windowSize.current[0]);

    const nextPage = () =>{
        setM3CK1SNextButton('M3CK1S_NextButton');
        if(windowSize.current[0] < 600)
            {
                setM3CK1SNextButton('M3CK1S_NextButton_Mobile');
            }

        if(currentPage==2)
        {
            setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
            setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
            setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
            setCurrentPage(3);
            setM3CK1SNavImage1('NavImage_Finn');
            setM3CK1SNavImage2('NavImage_Mia');
            setM3CK1SNavImage3('NavImage_finish_active');
            setTriggerLoad(true);
        }

        if(currentPage==1)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area');  
                setCurrentPage(2);
                setM3CK1SNavImage1('NavImage_Finn');
                setM3CK1SNavImage2('NavImage_Mia_active');
                setM3CK1SNavImage3('NavImage_finish');
                setTriggerLoad(true);
            }
        
        if(currentPage==0)
            {
                setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
                setM3CK1S_ContentArea2('M3CK1S_Content_Area');  
                setCurrentPage(2);
                setM3CK1SNavImage1('NavImage_Finn');
                setM3CK1SNavImage2('NavImage_Mia_active');
                setM3CK1SNavImage3('NavImage_finishl');
                setTriggerLoad(true);
            } 
    }


    const selectPage1 = () =>{
        setM3CK1SNavImage1('NavImage_Finn_active');
        setM3CK1SNavImage2('NavImage_Mia');
        setM3CK1SNavImage3('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_rightOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_rightOff'); 
        setTriggerLoad(true);
        setCurrentPage(1);
    }

    const selectPage2 = () =>{
        setM3CK1SNavImage1('NavImage_Finn');
        setM3CK1SNavImage2('NavImage_Mia_active');
        setM3CK1SNavImage3('NavImage_finish');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area_rightOff'); 
        setTriggerLoad(true);
        setCurrentPage(2);
    }

    const selectPage3 = () =>{
        setM3CK1SNavImage1('NavImage_Finn');
        setM3CK1SNavImage2('NavImage_Mia');
        setM3CK1SNavImage3('NavImage_finish_active');
        setM3CK1S_ContentArea1('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea2('M3CK1S_Content_Area_leftOff');
        setM3CK1S_ContentArea3('M3CK1S_Content_Area'); 
        setTriggerLoad(true);
        setCurrentPage(3);
    }

   
    const setFullScreen = () =>{
        const element = document.documentElement;

        if (element.requestFullscreen) {
          element.requestFullscreen().catch(err => err);
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen().catch(err => err);
        }
      }

      const handleLoad = () =>{
        setTriggerLoad(true);
      }

    const printFynn = () =>{
        settriggerPrintFynn(true);
        setPrintWindowFynn('PrintWindow');
        setTimeout(()=>{
            setPrintWindowFynn('PrintWindow_gone'); 
        },6000)
    }

    const printMia = () =>{
        settriggerPrintMia(true);
        setPrintWindowMia('PrintWindow');
        setTimeout(()=>{
            setPrintWindowMia('PrintWindow_gone'); 
        },6000)
    }

    

    return(
        <div className='M3CK1S_Wrapper' onMouseEnter={handleLoad}>
            <div className={M3CK1S_Top}>
                <div className={M3CK1SNavigationArea}>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage1} onClick={selectPage1}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage2} onClick={selectPage2}></div>
                    </div>
                    <div className={M3CK1SNavElement1}>
                        <div className={M3CK1SNavImage3} onClick={selectPage3}></div>
                    </div>
                </div>
            </div>
            <div className={M3CK1S_Middle}>
                <div className={M3CK1S_ContentArea1}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                              <div className={M3Ergebnisse}>
                                <div className={M3ErgebnisseTitel}>Ihre Kommentare und Ihr Feedback zu Fynn</div>
                                <div className={M3ErgebnisseArea}>
                                <Ergebnisse Modus={"View"} UserCode={UserCode} Datum={Datum} Kurs={Kurs} Save={triggerSave} triggerLoad={triggerLoad} MasterSlave="Master" Stimulus="Fynn"></Ergebnisse>
                                </div>
                                <div className={PrinterButton} onClick={printFynn}><div className='Printerbutton_Button'></div></div>
                              </div>
                            </div>                
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea2}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                            <div className={M3Ergebnisse}>
                                <div className={M3ErgebnisseTitel}>Ihre Kommentare und Ihr Feedback zu Mia</div>
                                <div className={M3ErgebnisseArea}>
                                   <Ergebnisse Modus={"View"} UserCode={UserCode} Datum={Datum} Kurs={Kurs} Save={triggerSave} triggerLoad={triggerLoad} MasterSlave="Slave" Stimulus="Mia"></Ergebnisse>
                                </div>
                                <div className={PrinterButton} onClick={printMia}><div className='Printerbutton_Button'></div></div>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1S_ContentArea3}>
                    <div className='M3CK1S_ContentArea_Outer'>
                        <div className='M3CK1S_ContentArea_Outer2'>
                            <div className='M3CK1S_ContentArea_Content'>
                            <div className={SpeicherSeite}>
                                    <div className={SpeicherSeiteTitel}>Bearbeitung abschließen</div>
                                    <div className={SpeicherButtonArea}><div className={SpeicherButton}><Link to="/M3Transit" style={{textDecoration:"none", color:"black"}} state={{Origin:"M3E1"}}>Weiter</Link></div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={M3CK1SNextButton} onClick={nextPage}>{">"}</div>
                <div className={PrintWindowFynn}>
                    <M3Printer Schuler={"Fynn"} UserCode={UserCode} Datum={Datum} Kurs={Kurs} TriggerPrint={triggerPrintFynn}></M3Printer>
                </div>
                <div className={PrintWindowMia}>
                    <M3Printer Schuler={"Mia"} UserCode={UserCode} Datum={Datum} Kurs={Kurs} TriggerPrint={triggerPrintMia}></M3Printer>
                </div>
            </div>

        </div>
    )
}

export default M3E1;