import '../styles/M3Transit.css';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import Loader from '../../components/Loader.js';
import Axios from 'axios';
import Cookies from 'js-cookie'; 

function M3Transit()
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const Api = "https://www.schreibenmitki.de:8443/";
    const location = useLocation();
    const Origin = location.state?.Origin;
    const [Kurs, setKurs] = useState(Cookies.get("SchreibenmitKI_WorkshopCode"));
    const [TransitTop, setTransitTop] = useState('TransitTop');
    const [TransitMiddle, setTransitMiddle] = useState('TransitMiddle');
    const [TransitBottom, setTransitBottom] = useState('TransitBottom');
    const [TransitCenterBox, setTransitCenterBox] = useState('TransitCenterBox');
    const [TransitCenterBoxTop, setTransitCenterBoxTop] = useState('TransitCenterBoxTop');
    const [TransitCenterBoxBottom, setTransitCenterBoxBottom] = useState('TransitCenterBoxBottom');
    const [TransitBoxLogoArea, setTransitBoxLogoArea] = useState('TransitBoxLogoArea');
    const [TransitBoxTitel, setTransitBoxTitel] = useState('TransitBoxTitel');
    const [TransitBoxTitelRow1, setTransitBoxTitelRow1] = useState('TransitBoxTitelRow1');
    const [TransitBoxTitelRow2, setTransitBoxTitelRow2] = useState('TransitBoxTitelRow2');
    const [TransitBoxTitelElementColored, setTransitBoxTitelElementColored] = useState('TransitBoxTitelElementColored');
    const [TransitBoxTitelElement, setTransitBoxTitelElement] = useState('TransitBoxTitelElement');
    const [TransitBoxTitelElement2, setTransitBoxTitelElement2] = useState('TransitBoxTitelElement2');
    const [TransitCenterBoxLoader, setTransitCenterBoxLoader] = useState('TransitCenterBoxLoader');
    const [TransitCenterBoxLoaderText, setTransitCenterBoxLoaderText] = useState('Bitte warten');
    const [inSession, setInSession] = useState(false);
    const [wait1, setWait1] = useState("Wait1");
    const [wait2, setWait2] = useState("Wait2");
    const [wait3, setWait3] = useState("Wait3");
    const [nextLinkButton, setNextLinkButton] = useState('nextLinkButton_gone');
    const [nextLink, setNextLink] = useState('/');
    const [KursIdFinderButton1, setKursIdFinderButton1] = useState('KursIdFinderButton1'); 
    const [gesuchterKurs, setGesuchterKurs] = useState(0);
    const [KursIdFinderText, setKursIdFinderText] = useState('Bitte hier die Kursnummer eintragen:');
    const [KursIdFinder, setKursIdFinder] = useState('KursIdFinder_gone');
    const [KursIdFinderField, setKursIdFinderField] = useState('KursIdFinderField');
    const [KursIdFinderTitel, setKursIdFinderTitel] = useState('KursIdFinderTitel');

    useEffect(() =>{
        if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
        {
            if(parseInt(Cookies.get("SchreibenmitKI_WorkshopCode")) > 0)
            {
                setInSession(true);
            }
            else
            {
                setTransitCenterBoxLoaderText("Bitte Workshop auswählen");
                setKursIdFinder('KursIdFinder');
                if(windowSize.current[0] < 600)
                {
                    setKursIdFinder('KursIdFinder_Mobile');
                }
            }   
        }
        else
        {
            setTransitCenterBoxLoaderText("Bitte erst einloggen!");
        }
             

    },[])

    const checkCookie = ()=>{
        console.log("Checke Cookie");
        if(parseInt(Cookies.get("SchreibenmitKI_UserCode")) > 0)
            {
                if(parseInt(Cookies.get("SchreibenmitKI_WorkshopCode")) > 0)
                {
                    setInSession(true);
                    console.log("Setze Session true");
                    checkKursStatus(true);
                }
                else
                {
                    setTransitCenterBoxLoaderText("Bitte Workshop auswählen");
                    setKursIdFinder('KursIdFinder');
                    if(windowSize.current[0] < 600)
                    {
                        setKursIdFinder('KursIdFinder_Mobile');
                    }
                }   
            }
            else
            {
                setTransitCenterBoxLoaderText("Bitte erst einloggen!");
            }
    }
    
    useEffect(()=>{
        
        checkCookie();
        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
            setTransitCenterBox('TransitCenterBox_Ipad');
        }

        if(windowSize.current[0] < 900)
        {
               
        }

        if(windowSize.current[0] < 600)
        {
            setTransitCenterBox('TransitCenterBox_Mobile');
            setTransitCenterBoxTop('TransitCenterBoxTop_Mobile');
            setTransitBoxLogoArea('TransitBoxLogoArea_Mobile');
            setTransitBoxTitelElementColored('TransitBoxTitelElementColored_Mobile');
            setTransitBoxTitelElement('TransitBoxTitelElement_Mobile');
            setTransitBoxTitelElement2('TransitBoxTitelElement2_Mobile');
            setTransitCenterBoxLoader('TransitCenterBoxLoader_Mobile');
            setKursIdFinderTitel('KursIdFinderTitel_Mobile');
            setKursIdFinderField('KursIdFinderField_Mobile');
            setKursIdFinderButton1('KursIdFinderButton1_Mobile'); 
        }

    }, windowSize.current[0]);

    
    const checkKursStatus = (s) =>{
            if(Kurs != undefined)
            {

            Axios.post(Api+"giveFortbildungsStatus",{KursId: Kurs}).then((neuerStatus) =>{
                if(neuerStatus.data)
                {
                    if(s == true)
                    {

                        
                    setNextLinkButton('nextLinkButton');
                    if(neuerStatus.data.FortbildungsStatus == "M3CK_Start")
                    {
                        setNextLink('/M3CK1S');
                       
                    }
    
                    if(neuerStatus.data.FortbildungsStatus == "M3PK_Start")
                    {
                        setNextLink('/M3PK1S');
                    }
    
                    if(neuerStatus.data.FortbildungsStatus == "M3TK_Start")
                    {
                        setNextLink('/M3TK1S');
                    }

                    if(neuerStatus.data.FortbildungsStatus == "M3TK2_Start")
                    {
                        setNextLink('/M3TK2S');
                    }
    
                    if(neuerStatus.data.FortbildungsStatus == "M3HW_Start")
                    {
                            setNextLink('/M3HW');
                    }

                    if(neuerStatus.data.FortbildungsStatus == "M3HW_End")
                    {
                            setNextLink('/M3HW_C');
                    }
    
                    if(neuerStatus.data.FortbildungsStatus == "M3CK_End")
                    {
                                setNextLinkButton('nextLinkButton_gone');
                                setTimeout(()=>{checkKursStatus()}, 10000)
                    }
    
                    if(neuerStatus.data.FortbildungsStatus == "M3_Pause")
                        {
                                    setNextLinkButton('nextLinkButton_gone');
                                    setTimeout(()=>{checkKursStatus()}, 10000)
                        }
    
                    if(neuerStatus.data.FortbildungsStatus == "M3PK_End")
                        {
                                    setNextLinkButton('nextLinkButton_gone');
                                    setTimeout(()=>{checkKursStatus()}, 10000)
                        }
    
                    else
                    {
                        console.log("noch kein Status193");
                    }
    
                    
                    if(windowSize.current[0] > 1600)
                    {
                            
                    }
                
                    if(windowSize.current[0] < 1200)
                    {
            
                    }
                
                    if(windowSize.current[0] < 900)
                    {
                               
                    }
                
                    if(windowSize.current[0] < 600)
                    {
                        setNextLinkButton('nextLinkButton_Mobile');
                    }
                }
                else
                {
                    setTimeout(()=>{checkCookie();}, 10000)
                }
                }
            }).catch(err => err)

            
        }
        
    }

    const SearchKurs = () =>{
        setKursIdFinderButton1('KursIdFinderButton1_gone');
        Axios.post(Api+"checkFortbildung", {Fortbildung: parseInt(gesuchterKurs)}).then((kursResult) =>{
            if(kursResult.data.Fortbildung == false)
            {
                setTimeout(()=>{setKursIdFinderButton1('KursIdFinderButton1');},1000)
            }
            if(kursResult.data.Fortbildung == true)
            {
                setKursIdFinderText('Erfolg!');
                setKursIdFinder('KursIdFinder_success');
                setKursIdFinderField('KursIdFinderField_gone');
                Cookies.set("SchreibenmitKI_WorkshopCode", gesuchterKurs);
                Cookies.set("SchreibenmitKI_Datum", kursResult.data.Datum);
                setInSession(true);
                setTransitCenterBoxLoaderText("Bitte warten");

                if(windowSize.current[0] < 600)
                {
                    setKursIdFinder('KursIdFinder_Mobile_success');
                }

                setTimeout(()=>{
                    setKursIdFinder('KursIdFinder_gone');
                    Axios.post(Api+"giveFortbildungsStatus",{KursId: gesuchterKurs}).then((neuerStatus) =>{
                        if(neuerStatus.data)
                        {
                            setNextLinkButton('nextLinkButton');
                            if(neuerStatus.data.FortbildungsStatus == "M3CK_Start")
                            {
                                setNextLink('/M3CK1S');
                               
                            }
            
                            if(neuerStatus.data.FortbildungsStatus == "M3PK_Start")
                            {
                                setNextLink('/M3PK1S');
                            }
            
                            if(neuerStatus.data.FortbildungsStatus == "M3TK_Start")
                            {
                                setNextLink('/M3TK1S');
                            }

                            if(neuerStatus.data.FortbildungsStatus == "M3TK2_Start")
                            {
                                setNextLink('/M3TK2S');
                            }
            
                            if(neuerStatus.data.FortbildungsStatus == "M3HW_Start")
                            {
                                    setNextLink('/M3HW');
                            }
            
                            if(neuerStatus.data.FortbildungsStatus == "M3CK_End")
                            {
                                        setNextLinkButton('nextLinkButton_gone');
                                        setTimeout(()=>{checkKursStatus()}, 10000)
                            }
            
                            if(neuerStatus.data.FortbildungsStatus == "M3_Pause")
                                {
                                            setNextLinkButton('nextLinkButton_gone');
                                            setTimeout(()=>{checkKursStatus()}, 10000)
                                }
            
                            if(neuerStatus.data.FortbildungsStatus == "M3PK_End")
                                {
                                            setNextLinkButton('nextLinkButton_gone');
                                            setTimeout(()=>{checkKursStatus()}, 10000)
                                }
            
            
                            
                            if(windowSize.current[0] > 1600)
                            {
                                    
                            }
                        
                            if(windowSize.current[0] < 1200)
                            {
                    
                            }
                        
                            if(windowSize.current[0] < 900)
                            {
                                       
                            }
                        
                            if(windowSize.current[0] < 600)
                            {
                                setNextLinkButton('nextLinkButton_Mobile');
                            }
                        }
                    })


                },1500)
            }

        }).catch(err => err)
    }


    return(
        <div className='TransitWrapper'>
            <div className={TransitTop}></div>
            <div className={TransitMiddle}>
                <div className={TransitCenterBox}>
                    <div className={TransitCenterBoxTop}>
                        <div className={TransitBoxLogoArea}><Loader Status="loading"></Loader></div>
                        <div className={TransitBoxTitel}>
                            <div className={TransitBoxTitelRow1}><div className={TransitBoxTitelElementColored}>Schreiben</div><div className={TransitBoxTitelElement}>mit KI</div></div>
                            <div className={TransitBoxTitelRow2}><div className={TransitBoxTitelElement2}>im Lehramt</div></div>
                        </div>
                    </div>
                    <div className={TransitCenterBoxBottom}>
                        <div className={TransitCenterBoxLoader}>{TransitCenterBoxLoaderText}<div className={wait1}>.</div><div className={wait2}>.</div><div className={wait3}>.</div></div>
                    </div>
                </div>
            </div>
            <div className={TransitBottom}><div className={nextLinkButton}><Link to={nextLink} style={{textDecoration:"none", color:"black"}}>weiter</Link></div></div>
            <div className={KursIdFinder}>
                <div className={KursIdFinderTitel}>{KursIdFinderText}</div>
                <div className='KursIdFinderRow'>
                    <div className='KursIdFinderSearchArea'>
                        <input className={KursIdFinderField} onChange={(e)=> setGesuchterKurs(e.target.value)}></input>
                    </div>
                    <div className='KursIdFinderButtonField'>
                        <div className={KursIdFinderButton1} onClick={SearchKurs}>Suchen</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default M3Transit;