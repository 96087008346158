import '../styles/Feedbacktrichter.css';
import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import { Simulate } from 'react-dom/test-utils';

function Feedbacktrichter(props)
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [Stimulus, setStimulu] = useState(props.Stimulus);
    const [UserCode, setUserCode] = useState(props.UserCode);
    const [Kurs, setKurs] = useState(props.Kurs);
    const [Datum, setDatum] = useState(props.Datum);
    const Api = "https://www.schreibenmitki.de:8443/";
    const [MasterSlave, setMasterSlave] = useState(props.MasterSlave);
    const [FeedbackTrichter_Top, setFeedbackTrichter_Top] = useState('FeedbackTrichter_Top');
    const [FeedbackTrichter_Middle, setFeedbackTrichter_Middle] = useState('FeedbackTrichter_Middle');
    const [FeedbackTrichter_Diagramm, setFeedbackTrichter_Diagramm] = useState('FeedbackTrichter_Diagramm');
    const [FeedbackTrichter_Bottom, setFeedbackTrichter_Bottom] = useState('FeedbackTrichter_Bottom');
    const [UserKommentar1, setUserKommentar1] = useState('UserKommentarL1');
    const [UserKommentar2, setUserKommentar2] = useState('UserKommentarL2');
    const [UserKommentar3, setUserKommentar3] = useState('UserKommentarL3');
    const [UserKommentar4, setUserKommentar4] = useState('UserKommentarL4');
    const [UserKommentarText, setUserKommentarText] = useState('UserKommentarText');
    const [UserFeedbackArea, setUserFeedbackArea] = useState('UserFeedbackArea');
    const [UserFeedbackField, setUserFeedbackField] = useState('UserFeedbackField');
    const [FeedbackTaskArea, setFeedbackTaskArea] = useState('FeedbackTaskArea');
    const [FeedbackTaskTask, setFeedbackTaskTask] = useState('FeedbackTaskTask');
    const [LTextArea, setLTextArea] = useState('LTextArea');
    const [LTextButton, setLTextButton] = useState('LTextButton');
    const [LTextButtonText, setLTextButtonText] = useState('Lernendentext');
    const [MobileMode, setMobileMode] = useState(false);
    const [L1Large, setL1Large] = useState(false);
    const [L2Large, setL2Large] = useState(false);
    const [L3Large, setL3Large] = useState(false);
    const [L4Large, setL4Large] = useState(false);
    const [displayLernerText, setDisplayLernerText] = useState('displayLernerText_gone');
    const [displayLernerTextTop, setdisplayLernerTextTop] = useState('displayLernerTextTop');
    const [displayLernerTextTopTitelArea, setdisplayLernerTextTopTitelArea] = useState('displayLernerTextTopTitelArea');
    const [displayLernerTextTopTitel, setDisplayLernerTextTopTitel] = useState('displayLernerTextTopTitel');
    const [displayLernerTextBody, setdisplayLernerTextBody] = useState('displayLernerTextBody');
    const [displayLernerTextTextRow, setdisplayLernerTextTextRow] = useState('displayLernerTextTextRow');
    const [displayLernerTextCloseArea,setdisplayLernerTextCloseArea] = useState('displayLernerTextCloseArea');
    const [displayLernerTextCloseButton, setdisplayLernerTextCloseButton] = useState('displayLernerTextCloseButton');
    const [AnTextRow1, setAnTextRow1] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [triggerLoad, setTriggerLoad] = useState(props.triggerLoad);
    const [UserKommentar1_Data, setUserKommentar1_Data] = useState("");
    const [UserKommentar2_Data, setUserKommentar2_Data] = useState("");
    const [UserKommentar3_Data, setUserKommentar3_Data] = useState("");
    const [UserKommentar4_Data, setUserKommentar4_Data] = useState("");
    const [UserFeedback, setUserFeedback] = useState('');
    const [sended, setSended] = useState(false);

    useEffect(()=>{AnalyseInput();},[])

    useEffect(()=>{
        if(props.triggerLoad==true)
            {
                LoadData();
            }
       
    },[props.triggerLoad])

    const LoadData = () =>{
        if(loaded == false)
            {
                setLoaded(true);
                if(MasterSlave == "Master")
                    {
                        Axios.post(Api+"ReturnKommentare", {UserCode: props.UserCode, Kurs: Kurs, Stimulus:Stimulus}).then((result) =>{
                        setUserKommentar1_Data(result.data.UserKommentar1);
                        setUserKommentar2_Data(result.data.UserKommentar2);
                        setUserKommentar3_Data(result.data.UserKommentar3);
                        setUserKommentar4_Data(result.data.UserKommentar4);
                        }).catch(err => err)
                    }
                if(MasterSlave == "Slave")
                {
                    setTimeout(() => {
                            Axios.post(Api+"ReturnKommentare", {UserCode: props.UserCode, Kurs: Kurs, Stimulus:Stimulus}).then((result) =>{
                            setUserKommentar1_Data(result.data.UserKommentar1);
                            setUserKommentar2_Data(result.data.UserKommentar2);
                            setUserKommentar3_Data(result.data.UserKommentar3);
                            setUserKommentar4_Data(result.data.UserKommentar4);
                            }).catch(err => err)

                    }, 5000)
                }
            }
        
    }

    useEffect(()=>{
        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
           
        }

        if(windowSize.current[0] < 900)
            {
                
            }

        if(windowSize.current[0] < 600)
        {
            setFeedbackTrichter_Top('FeedbackTrichter_Top_Mobile');
            setFeedbackTrichter_Middle('FeedbackTrichter_Middle_Mobile');
            setFeedbackTaskTask('FeedbackTaskTask_Mobile');
            setLTextButton('LTextButton_Mobile');
            setLTextButtonText('Text');
            setUserKommentarText('UserKommentarText_Mobile');
            setDisplayLernerTextTopTitel('displayLernerTextTopTitel_Mobile');
            setdisplayLernerTextTextRow('displayLernerTextTextRow_Mobile');
            setdisplayLernerTextTopTitelArea('displayLernerTextTopTitelArea_Mobile');
            setdisplayLernerTextCloseArea('displayLernerTextCloseArea_Mobile');
            setMobileMode(true);
        }

    }, windowSize.current[0]);

    const enlargeLevel1 = () =>{
        if(MobileMode == true)
        {
            if (L1Large == true){ResetEnlarge();}
            if(L1Large == false)
            {
                setL1Large(true);
                setUserKommentar1('UserKommentarL1_Large');
                setUserKommentar2('UserKommentarL2_Small');
                setUserKommentar3('UserKommentarL3_Small');
                setUserKommentar4('UserKommentarL4_Small');
            }}}

    const enlargeLevel2 = () =>{
        if(MobileMode == true)
        {
            if (L2Large == true){ResetEnlarge();}
            if(L2Large == false)
            {
                setL2Large(true);
                setUserKommentar1('UserKommentarL1_Small');
                setUserKommentar2('UserKommentarL2_Large');
                setUserKommentar3('UserKommentarL3_Small');
                setUserKommentar4('UserKommentarL4_Small');
            }}}

    const enlargeLevel3 = () =>{
        if(MobileMode == true)
        {
            if (L3Large == true){ResetEnlarge();}
            if(L3Large == false)
            {
                setL3Large(true);
                setUserKommentar1('UserKommentarL1_Small');
                setUserKommentar2('UserKommentarL2_Small');
                setUserKommentar3('UserKommentarL3_Large');
                setUserKommentar4('UserKommentarL4_Small');
            }}}
    const enlargeLevel4 = () =>{
        if(MobileMode == true)
        {
            if (L4Large == true){ResetEnlarge();}
            if(L4Large == false)
            {
                setL4Large(true);
                setUserKommentar1('UserKommentarL1_Small');
                setUserKommentar2('UserKommentarL2_Small');
                setUserKommentar3('UserKommentarL3_Small');
                setUserKommentar4('UserKommentarL4_Large');
            }}}

    const ResetEnlarge = () =>{
        setUserKommentar1('UserKommentarL1');
        setUserKommentar2('UserKommentarL2');
        setUserKommentar3('UserKommentarL3');
        setUserKommentar4('UserKommentarL4');
        setL1Large(false);
        setL2Large(false);
        setL3Large(false);
        setL4Large(false);
    }

    const AnalyseInput = () =>{
        if(AnTextRow1.length < 2)
        {
            var InputSplit = props.Lernertext.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
               AnTextRow1.push(<>{InputSplit[i]}</>);
            }  
        }  
    }

    const showLernerText = () =>{
        setDisplayLernerText('displayLernerText');
    }

    const hideLernerText = () =>{
        setDisplayLernerText('displayLernerText_gone');
    }




    
    useEffect(() =>{
        if(props.Save == true && sended == false)
            {
                setSended(true);
                if(MasterSlave == "Master")
                {
                    console.log("Saving:");
                    console.log(UserFeedback);
                    Axios.post(Api+'saveM3PK1',{
                        UserCode: UserCode, 
                        Datum: Datum, 
                        Kurs: Kurs,
                        Stimulus: Stimulus,
                        UserFeedback: UserFeedback                       
                    }).catch(err=>err)

                }
                if(MasterSlave == "Slave")
                {
                    setTimeout(()=>{
                        Axios.post(Api+'saveM3PK1',{
                            UserCode: UserCode, 
                            Datum: Datum, 
                            Kurs: Kurs,
                            Stimulus: Stimulus,
                            UserFeedback: UserFeedback      
                        }).catch(err=>err)
                    },6000);
                }
                
            }
    }, [props.Save])

    return(
    <div className="Feedbacktrichter_Wrapper">
        <div className={FeedbackTrichter_Top}>
            <div className={FeedbackTaskArea}>
                <div className={FeedbackTaskTask}>Verfassen Sie ein lernförderliches Feedback. Berücksichtigen Sie dabei die Merkmale 'guten' Feedbacks sowie Ihre Anmerkungen, auf Ebene der <b style={{color:"#e1743c"}}>Ausdruckskompetenz</b>, <b style={{color:"#3cbae1"}}>Kontextualisierungskompetenz</b>, <b style={{color:"#ba3ce1"}}>Antizipationskompetenz</b> und <b style={{color:"#4fb438"}}>Textgestaltungskompetenz</b>. </div>
            </div>
            <div className={LTextArea}><div className={LTextButton} onClick={showLernerText}>{LTextButtonText}</div></div>
        </div>
        <div className={FeedbackTrichter_Middle}>
            <div className={UserKommentar1} onClick={enlargeLevel1}><div className={UserKommentarText}>{UserKommentar1_Data}</div></div>
            <div className={UserKommentar2} onClick={enlargeLevel2}><div className={UserKommentarText}>{UserKommentar2_Data}</div></div>
            <div className={UserKommentar3} onClick={enlargeLevel3}><div className={UserKommentarText}>{UserKommentar3_Data}</div></div>
            <div className={UserKommentar4} onClick={enlargeLevel4}><div className={UserKommentarText}>{UserKommentar4_Data}</div></div>
        </div>
        <div className={FeedbackTrichter_Diagramm}>
            <div className='FTD_R1'>
                <div className='FTD_R1C1'></div>
                <div className='FTD_R1C2'></div>
                <div className='FTD_R1C3'></div>
                <div className='FTD_R1C4'></div>
                <div className='FTD_R1C5'></div>
                <div className='FTD_R1C6'></div>
                <div className='FTD_R1C7'></div>
                <div className='FTD_R1C8'></div>
            </div>
            <div className='FTD_R2'>
            <div className='FTD_R2C1'></div>
            <div className='FTD_R2C2'></div>
            </div></div>
        <div className={FeedbackTrichter_Bottom}>
            <div className={UserFeedbackArea}>
                <textarea className={UserFeedbackField} onChange={(e) => {setUserFeedback(e.target.value)}}>
                </textarea>
            </div>
        </div>
        <div className={displayLernerText}>
            <div className={displayLernerTextTop}>
                <div className={displayLernerTextTopTitelArea}>
                    <div className={displayLernerTextTopTitel}>Originaler Lernendentext:</div>
                </div>
                <div className={displayLernerTextCloseArea}><div className={displayLernerTextCloseButton} onClick={hideLernerText}></div></div>
            </div>
            <div className={displayLernerTextBody}>
                <div className={displayLernerTextTextRow}>{AnTextRow1[0]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[1]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[2]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[3]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[4]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[5]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[6]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[7]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[8]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[9]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[10]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[11]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[12]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[13]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[14]}</div>
                <div className={displayLernerTextTextRow}>{AnTextRow1[15]}</div>
            </div>
        </div>
    </div>
    )
}
export default Feedbacktrichter;