import '../styles/SchulertextViewer.css';
import { useEffect, useRef, useState } from 'react';

function SchulertextViewer(props)
{
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [SchulertextViewer_Wrapper, setSchulertextViewer_Wrapper] = useState('SchulertextViewer_Wrapper');
    const [Schulername, setSchulername] = useState(props.Schulername);
    const [Schulertext_Titel, setSchulertext_Titel] = useState('Schulertext_Titel');
    const [Schulertext_Area, setSchulertext_Area] = useState('Schulertext_Area');
    const [AnTextRow1, setAnTextRow1] = useState([]);
    const [Schulertext_Row, setSchulertext_Row] = useState('Schulertext_Row');
     
    useEffect(()=>{

        if(windowSize.current[0] > 1600)
        {
            
        }

        if(windowSize.current[0] < 1200)
        {
            
        }

        if(windowSize.current[0] < 900)
            {
                
            }

        if(windowSize.current[0] < 600)
        {
            setSchulertextViewer_Wrapper('SchulertextViewer_Wrapper_Mobile');
            setSchulertext_Titel('Schulertext_Titel_Mobile');
            setSchulertext_Area('Schulertext_Area_Mobile');
            setSchulertext_Row('Schulertext_Row_Mobile');
        }

    }, windowSize.current[0]);


    const AnalyseInput = () =>{
        if(AnTextRow1.length < 2)
        {
            var InputSplit = props.Lernertext.split("|");
            for(let i = 0; i<InputSplit.length; i++)
            {
               AnTextRow1.push(<>{InputSplit[i]}</>);
            }  
        }  
    }

    useEffect(()=>{
        AnalyseInput();
        
    },[])

  
    return(
        <div className={SchulertextViewer_Wrapper}>
            <div className={Schulertext_Titel}>{Schulername+"s Text:"}</div>
            <div className={Schulertext_Area}>
                <div className={Schulertext_Row}>{AnTextRow1[0]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[1]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[2]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[3]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[4]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[5]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[6]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[7]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[8]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[9]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[10]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[11]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[12]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[13]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[14]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[15]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[16]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[17]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[18]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[19]}</div>
                <div className={Schulertext_Row}>{AnTextRow1[20]}</div>
            </div>
        </div>
    )
}
export default SchulertextViewer;